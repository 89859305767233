import React from 'react'
import { Radio } from 'antd'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import './styles.scss'

class HeaderRadioButtons extends React.Component {
  state = {
    value: ''
  }

  handleChange = e => {
    this.setState({
      value: e.target.value
    })
    this.props.handleFormType(e.target.value)
  }

  render () {
    const { intl } = this.props
    return (
      <Radio.Group
        className='form-pick-cover'
        onChange={this.handleChange}
        value={this.state.value}
      >
        <Radio value='company'>{intl.formatMessage({ id: 'company' })}</Radio>
        <Radio value='person'>{intl.formatMessage({ id: 'person' })}</Radio>
      </Radio.Group>
    )
  }
}

HeaderRadioButtons.propTypes = {
  handleFormType: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default injectIntl(HeaderRadioButtons)
