import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Button } from 'antd'
import PageTitle from 'components/Global/PageTitle'

import WorkorderTable from './components/WorkorderList'
import TasksList from './components/TasksList'
import WorkorderDrawer from './components/WorkorderDrawerTabs/index'
import TaskDetails from './components/TaskDetails'
import {
  setPickedWorkorder,
  setWorkorderData,
  wokrorderTaskAdd,
  workorderTaskDelete,
  workorderMoveTask,
  reorderWorkorderTasks,
  setWorkorderShowArchived
} from '../../../ducks/workorder'
import { setTasksData } from '../../../ducks/tasks'
import { DragDropContext } from 'react-beautiful-dnd'

import './styles.scss'

export class WorkordersPage extends React.Component {
  handleShowModalAdd = async () => {
    const { dispatch } = this.props
    dispatch(setPickedWorkorder({ income: {}, value: {} }))
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  getList = id => {
    const { workordersState, tasksData } = this.props
    if (id === 'tasks') {
      return tasksData.docs
    } else {
      return workordersState.docs.find(item => item._id === id).tasks
    }
  }

  move = (source, destination, droppableSource, droppableDestination) => {
    try {
      const sourceClone = Array.from(source)
      const destClone = Array.from(destination)
      const [removed] = sourceClone.splice(droppableSource.index, 1)

      destClone.splice(droppableDestination.index, 0, removed)

      const result = {}
      result[droppableSource.droppableId] = sourceClone
      result[droppableDestination.droppableId] = destClone

      return result
    } catch (e) {
      console.log(e)
    }
  }

  handleMove = moveResult => {
    const { workordersState, tasksData, dispatch } = this.props
    const currentWorkorders = [...workordersState.docs]

    const outputWorkorders = currentWorkorders.map(workorder => {
      Object.keys(moveResult).forEach(moveId => {
        if (workorder._id === moveId) {
          workorder.tasks = moveResult[moveId]
        }
      })
      return workorder
    })

    dispatch(
      setWorkorderData({
        ...workordersState,
        docs: outputWorkorders
      })
    )

    if (Object.keys(moveResult).some(key => key === 'tasks')) {
      dispatch(
        setTasksData({
          ...tasksData,
          docs: moveResult.tasks
        })
      )
    }
  }

  handleReorder = (id, newOrder) => {
    const { workordersState, dispatch } = this.props
    const currentWorkorders = [...workordersState.docs]
    const outputWorkorders = currentWorkorders.map(workorder => {
      if (workorder._id === id) {
        workorder.tasks = newOrder
      }
      return workorder
    })
    dispatch(
      setWorkorderData({
        ...workordersState,
        docs: outputWorkorders
      })
    )
  }

  handleDragEnd = async result => {
    const { source, destination } = result
    const { dispatch } = this.props
    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'tasks') {
        return
      }

      const items = this.reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      )
      dispatch(
        reorderWorkorderTasks(source.droppableId, {
          tasks: items
        })
      ).then(response => {
        const items = this.reorder(
          this.getList(source.droppableId),
          source.index,
          destination.index
        )
        if (response.error) {
          this.handleReorder(source.droppableId, items)
        }
      })
      this.handleReorder(source.droppableId, items)
    } else {
      const moveResult = this.move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      )
      if (
        source.droppableId !== 'tasks' &&
        destination.droppableId !== 'tasks'
      ) {
        dispatch(
          workorderMoveTask({
            source: source.droppableId,
            destination: destination.droppableId,
            task: result.draggableId
          })
        ).then(response => {
          const moveResult = this.move(
            this.getList(destination.droppableId),
            this.getList(source.droppableId),
            destination,
            source
          )
          if (response.error) {
            this.handleMove(moveResult)
          }
        })
      }

      if (
        source.droppableId === 'tasks' &&
        destination.droppableId !== 'tasks'
      ) {
        dispatch(
          wokrorderTaskAdd(destination.droppableId, result.draggableId)
        ).then(response => {
          const moveResult = this.move(
            this.getList(destination.droppableId),
            this.getList(source.droppableId),
            destination,
            source
          )

          if (response.error) {
            this.handleMove(moveResult)
          }
        })
      }

      if (destination.droppableId === 'tasks') {
        dispatch(
          workorderTaskDelete(source.droppableId, result.draggableId)
        ).then(response => {
          const moveResult = this.move(
            this.getList(destination.droppableId),
            this.getList(source.droppableId),
            destination,
            source
          )

          if (response.error) {
            this.handleMove(moveResult)
          }
        })
      }

      this.handleMove(moveResult)
    }
  }

  handleToggleArchive = () => {
    const { dispatch, showArchived } = this.props

    dispatch(setWorkorderShowArchived(!showArchived))
  }

  render () {
    const { intl, pickedTask, showArchived } = this.props

    const headerButtons = (
      <>
        <Button key={0} onClick={this.handleToggleArchive}>
          {showArchived ? (
            <FormattedMessage id='hide archived' />
          ) : (
            <FormattedMessage id='show archived' />
          )}
        </Button>
        {!showArchived && (
          <Button
            key={1}
            type='primary'
            style={{ marginLeft: 10 }}
            onClick={this.handleShowModalAdd}
          >
            <FormattedMessage id='create workorder' />
          </Button>
        )}
      </>
    )

    return (
      <div className='workorder-page-cover'>
        <FormattedMessage id='head.title.workorders'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>
        <PageTitle
          title={intl.formatMessage({ id: 'orders' })}
          buttons={headerButtons}
        />
        <DragDropContext
          style={{ width: '100%' }}
          onDragEnd={this.handleDragEnd}
        >
          <div className='workrorder-content-cover'>
            {!showArchived && <TasksList />}
            <WorkorderTable />
            {!!Object.keys(pickedTask).length &&
              ((pickedTask.workorder &&
                pickedTask.workorder.archived === showArchived) ||
                (!pickedTask.workorder && !showArchived)) && <TaskDetails />}
          </div>
        </DragDropContext>
        <WorkorderDrawer />
      </div>
    )
  }
}

WorkordersPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  pickedTask: PropTypes.object,
  workordersState: PropTypes.object,
  tasksData: PropTypes.object,
  showArchived: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    workordersState: state.workorder.workorderData,
    showArchived: state.workorder.showArchived,
    tasksData: state.tasks.tasksData,
    pickedTask: state.tasks.pickedTask
  }
}
export default injectIntl(connect(mapStateToProps)(WorkordersPage))
