import React, { useEffect, useState } from 'react'
import { Table, Skeleton } from 'antd'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { fetchStats } from '../../../ducks/stats'
import RecordingsBlock from './components/RecordingsBlock'
import CardLayout from './components/CardLayout'

import './style.scss'

const mapStateToProps = state => ({
  settings: state.settings.data,
  user: state.auth.authUser,
  stats: state.stats.data
})

const HomePage = () => {
  const [loading, setLoading] = useState(true)
  const { settings, user, stats } = useSelector(mapStateToProps)
  const intl = useIntl()
  const dispatch = useDispatch()

  const acceptance = stats.acceptance || {}
  const acceptanceCount = acceptance.count || {}
  const recordingimage = stats.recordingimage || {}
  const recordingimageCount = recordingimage.count || {}
  const object = stats.object || {}
  const objectCount = object.count || {}
  const requests = stats.requests || []
  const workorders = stats.workorders || []

  useEffect(() => {
    dispatch(fetchStats()).then(result => {
      if (result) {
        setLoading(false)
      }
    })
  }, [settings])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      value: 'name'
    }
  ]
  const requiredCardsWithTable = [
    {
      label: `${intl.formatMessage({
        id: 'open requests'
      })}`,
      value: requests.list,
      table: true
    },
    {
      label: `${intl.formatMessage({
        id: 'open workorders'
      })}`,
      value: workorders.list,
      table: true
    }
  ]
  const requiredCardsWithoutTable = [
    {
      label: `${intl.formatMessage({
        id: 'number of'
      })} ${intl.formatMessage({
        id: 'users'
      })}`,
      value: user.count
    },
    {
      label: `${intl.formatMessage({
        id: 'number of'
      })} ${intl.formatMessage({
        id: 'acceptances'
      })}`,
      value: acceptanceCount.total
    },
    {
      label: `${intl.formatMessage({
        id: 'number of'
      })} ${intl.formatMessage({
        id: 'recording images'
      })}`,
      value: recordingimageCount.total
    },
    {
      label: `${intl.formatMessage({
        id: 'number of'
      })} ${object.name}`,
      value: objectCount.total
    }
  ]

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <div className='home-row'>
        <RecordingsBlock loading={loading} />
        <div className='homepage-row-block multi-cards'>
          {settings.buildx &&
            settings.buildx.enabled &&
            requiredCardsWithoutTable.map(item => (
              <CardLayout
                key={item.label}
                label={item.label}
                count={item.value}
                loading={loading}
              />
            ))}
        </div>

        {settings.buildx &&
          settings.buildx.enabled &&
          requiredCardsWithTable.map(item => (
            <div key={item.label} className='homepage-row-block'>
              <CardLayout
                key={item.label}
                label={item.label}
                count={!item.table ? item.value : null}
                loading={loading}
                noNumber
              >
                {item.table && (
                  <Skeleton
                    title={false}
                    paragraph={{ rows: 5 }}
                    loading={loading}
                    active
                  >
                    <Table
                      rowKey={record => record._id}
                      dataSource={item.value}
                      columns={columns}
                    />
                  </Skeleton>
                )}
              </CardLayout>
            </div>
          ))}
      </div>
    </>
  )
}

export default HomePage
