import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'
import { notification, Form } from 'antd'

import Button from 'components/Global/Button'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import DrawerComponent from 'components/Drawer'

import FieldDefinitionForm from './FieldDefinitionForm'
import {
  fetchFieldList,
  setDrawerVisible,
  setPickedField,
  submitPickedField,
  updatePickedField,
  deletePickedField
} from '../../../ducks/fields'

const FieldDefinitionDrawer = props => {
  const [loading, setLoading] = useState(false)
  const intl = useIntl()
  const dispatch = useDispatch()
  const { drawerVisible, form, pickedField } = props

  const handleHideDrawer = () => {
    dispatch(setDrawerVisible(false))
    dispatch(setPickedField({}))
  }

  const handleSaveField = async () => {
    form.validateFields((errors, values) => {
      if (!errors) {
        dispatch(updatePickedField(values))
        setLoading(true)
        dispatch(submitPickedField()).then(response => {
          if (!response.error) {
            dispatch(fetchFieldList())
            notification.success({
              message: `${intl.formatMessage({
                id: pickedField._id
                  ? 'updated successfully'
                  : 'created successfully'
              })}`
            })
            setTimeout(() => setLoading(false), 1500)

            handleHideDrawer()
          } else {
            if (response.error.indexOf('to be unique') > -1) {
              notification.error({
                message: intl.formatMessage({
                  id: 'field already exists'
                })
              })
            } else {
              notification.error({
                message: intl.formatMessage({
                  id: 'saving error'
                }),
                description: response.error
              })
            }
            setLoading(false)
          }
        })
      }
    })
  }

  const handleDeleteRecord = async () => {
    dispatch(deletePickedField()).then(response => {
      if (!response.error) {
        notification.success({
          message: intl.formatMessage({
            id: 'delete successful'
          }),
          description: response.message
        })
      } else {
        notification.error({
          message: intl.formatMessage({
            id: 'deleting error'
          }),
          description: response.message
        })
      }
      dispatch(fetchFieldList())
      handleHideDrawer()
    })
  }

  const footerDrawerButtons = [
    <>
      <Button
        onClick={handleHideDrawer}
        value={<FormattedMessage id='cancel' />}
      />

      <Button
        onClick={handleSaveField}
        type='primary'
        disabled={loading}
        value={<FormattedMessage id='save' />}
      />
    </>
  ]

  if (
    ['admin', 'superadmin'].includes(props.auth.authUser.scope[0]) &&
    pickedField._id
  ) {
    footerDrawerButtons.unshift(
      <PopconfirmDelete key={2} onConfirmAction={handleDeleteRecord} />
    )
  }
  return (
    <DrawerComponent
      title={intl.formatMessage({
        id: pickedField._id ? 'edit field' : 'create field'
      })}
      onClose={handleHideDrawer}
      visible={drawerVisible}
      footerButtons={footerDrawerButtons}
    >
      <FieldDefinitionForm form={form} />
    </DrawerComponent>
  )
}

FieldDefinitionDrawer.propTypes = {
  auth: PropTypes.object,
  drawerVisible: PropTypes.bool,
  pickedField: PropTypes.object,
  form: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  drawerVisible: state.fields.drawerVisible,
  pickedField: state.fields.pickedField
})

export default Form.create({ name: 'field' })(
  connect(mapStateToProps, null)(FieldDefinitionDrawer)
)
