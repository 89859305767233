import React from 'react'
import FieldDefinitionHeader from './components/PageHeader'
import FieldDefinitionTable from './FieldDefinitionTable'
import FieldDefinitionDrawer from './FieldDefinitionDrawer'

const FieldDefinitionPage = () => {
  return (
    <>
      <FieldDefinitionHeader />
      <FieldDefinitionTable />
      <FieldDefinitionDrawer />
    </>
  )
}

export default FieldDefinitionPage
