import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import _ from 'lodash'
import { Form, Input, AutoComplete, Icon, Spin, Select, Switch } from 'antd'
import { fetchObjectTypeList } from '../../../ducks/objectTypes'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'

const Option = AutoComplete.Option

class ObjectForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
    this.handleInputThrottled = debounce(this.fetchObjectTypesOnSearch, 300)
  }

  fetchObjectTypesOnSearch = value => {
    const { dispatch } = this.props
    this.setState({ loading: true })
    dispatch(fetchObjectTypeList({ 'name[]': value }, true)).then(() => {
      this.setState({ loading: false })
    })
  }

  changeName = name => {
    this.props.updateObject('fields', {})
    this.props.updateObject('name', name)
  }

  changeLocation = name => {
    const location = {
      name
    }

    this.props.updateObject('location', location)
  }

  handleObjectTypePick = objectType => {
    const { updateObject } = this.props
    updateObject('objectType', objectType)
  }

  render () {
    const { getFieldDecorator } = this.props.form
    const { loading } = this.state
    const {
      object,
      objectName,
      projects,
      readOnlyNewProject,
      objectTypeList,
      intl,
      formLoading,
      fetchProjects,
      updateObject,
      fetchLocations
    } = this.props
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    // const fields = object.fields || {}
    const uniqueObjects = _.uniqBy(this.props.locations, x => {
      return x.name
    })

    const locations = uniqueObjects.map(location => (
      <Option key={location._id} value={location.name}>
        {location.name}
      </Option>
    ))

    return (
      <Form onSubmit={() => {}} className='objectForm'>
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              {objectName}
              <span style={{ color: '#005591' }}>*</span>
            </span>
          }
          required={false}
        >
          {getFieldDecorator('name', {
            initialValue: object.name,
            rules: [
              {
                required: true,
                message: intl.formatMessage({
                  id: 'name is required'
                })
              }
            ]
          })(
            <Input
              disabled={!!object._id}
              onChange={event => this.changeName(event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'project' })}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp='children'
            notFoundContent={
              formLoading ? (
                <div className='objects-spinner-container'>
                  <Spin size='small' />
                </div>
              ) : null
            }
            onFocus={() => fetchProjects('')}
            onSearch={value => fetchProjects(value)}
            onChange={projectID => updateObject('project', projectID)}
            defaultValue={object.project ? object.project.projectName : ''}
            disabled={readOnlyNewProject}
          >
            {projects.map(project => (
              <Select.Option key={project._id} value={project._id}>
                {project.projectName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'location' })}
        >
          <div className='certain-category-search-wrapper'>
            <AutoComplete
              className='certain-category-search'
              allowClear
              dropdownClassName='certain-category-search-dropdown'
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 300 }}
              style={{ width: '100%' }}
              dataSource={locations}
              optionLabelProp='value'
              notFoundContent={
                formLoading ? (
                  <div className='objects-spinner-container'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
              value={object.location ? object.location.name : ''}
              onFocus={() => fetchLocations('')}
              onSearch={value => fetchLocations(value)}
              onChange={value => this.changeLocation(value)}
            >
              <Input
                suffix={
                  <Icon type='search' className='certain-category-icon' />
                }
              />
            </AutoComplete>
          </div>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={event => updateObject('description', event.target.value)}
            value={object.description}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'comment' })}
        >
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={event => updateObject('comment', event.target.value)}
            value={object.comment}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'verified'
          })}
        >
          <Switch
            checked={object.isVerified}
            onChange={value => updateObject('isVerified', value)}
          />
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          required={false}
          label={intl.formatMessage({ id: 'object type' })}
        >
          {getFieldDecorator('form', {
            initialValue:
              (object.objectType && object.objectType.name) || undefined
          })(
            <Select
              showSearch
              style={{ width: '100%' }}
              notFoundContent={
                loading && (
                  <div className='project-spinner-container'>
                    <Spin size='small' />
                  </div>
                )
              }
              placeholder={intl.formatMessage({ id: 'select form' })}
              optionFilterProp='children'
              onSearch={this.handleInputThrottled}
              onFocus={() => this.handleInputThrottled('')}
              onChange={(value, event) =>
                this.handleObjectTypePick(event.props.data)
              }
            >
              {(objectTypeList || []).map(objectType => {
                return (
                  <Select.Option
                    key={objectType._id}
                    data={objectType}
                    value={objectType._id}
                  >
                    {objectType.name}
                  </Select.Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
      </Form>
    )
  }
}

ObjectForm.propTypes = {
  object: PropTypes.object.isRequired,
  updateObject: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  objectName: PropTypes.string,
  form: PropTypes.object.isRequired,
  projects: PropTypes.array,
  objectTypeList: PropTypes.array,
  readOnlyNewProject: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  formLoading: PropTypes.bool,
  fetchProjects: PropTypes.func,
  fetchLocations: PropTypes.func,
  dispatch: PropTypes.func
}

const mapStateToProps = state => ({
  objectTypeList: state.objectType.searchedObjectTypeList
})

export default injectIntl(connect(mapStateToProps, null)(ObjectForm))
