import React from 'react'

import moment from 'moment'
import { FormattedMessage, injectIntl } from 'react-intl'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form, Select, Switch, Icon, Tag } from 'antd'
import PropTypes from 'prop-types'

import { updatePickedRecording } from '../../../../../ducks/recordings'

export class StatusAndArchivedBlock extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      defectPage: false
    }
  }

  componentDidMount () {
    const {
      location: { pathname }
    } = this.props
    this.setState({
      defectPage: pathname.indexOf('defect') > -1
    })
  }

  handleFieldChange = (key, value) => {
    const { dispatch } = this.props
    dispatch(updatePickedRecording({ [key]: value }))
  }

  render () {
    const { readOnly, formItemLayout, recording, intl } = this.props
    const { defectPage } = this.state
    return (
      <>
        <Form.Item {...formItemLayout} label='Status'>
          <Select
            style={{
              width: '100%'
            }}
            allowClear
            value={recording.status}
            onChange={value => this.handleFieldChange('status', value)}
            disabled={defectPage}
          >
            <Select.Option value='opened'>
              <FormattedMessage id='opened' />
            </Select.Option>
            <Select.Option value='rejected'>
              <FormattedMessage id='rejected' />
            </Select.Option>
            <Select.Option value='approved'>
              <FormattedMessage id='approved' />
            </Select.Option>
            <Select.Option value='invalid'>
              <FormattedMessage id='invalid' />
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'archived'
          })}
        >
          <Switch
            disabled={readOnly}
            checkedChildren={<Icon type='check' />}
            unCheckedChildren={<Icon type='close' />}
            checked={recording.archived}
            onChange={checked => this.handleFieldChange('archived', checked)}
          />
        </Form.Item>

        {recording.submitted && (
          <>
            <h3>
              <FormattedMessage id='upload' />
            </h3>

            <Form.Item
              {...formItemLayout}
              label={intl.formatMessage({
                id: 'submitted at'
              })}
            >
              <span className='ant-form-text'>
                {!!recording.submittedAt &&
                  moment(recording.submittedAt).format('DD.MM.YYYY, HH:mm')}
              </span>
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              label={intl.formatMessage({
                id: 'reference number'
              })}
            >
              <span className='ant-form-text'>
                {!!recording.submittedRefNr && recording.submittedRefNr}
              </span>
            </Form.Item>

            <Form.Item
              {...formItemLayout}
              label={this.props.intl.formatMessage({
                id: 'status'
              })}
            >
              <span className='ant-form-text'>
                {!!recording.submittedRefNr && (
                  <Tag color='#87d068'>
                    <Icon type='check' /> erfolgreich
                  </Tag>
                )}
              </span>
            </Form.Item>
          </>
        )}
      </>
    )
  }
}

StatusAndArchivedBlock.propTypes = {
  recording: PropTypes.object,
  formItemLayout: PropTypes.object,
  location: PropTypes.object,
  intl: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  readOnly: PropTypes.bool
}

const mapStateToProps = state => ({
  recording: state.recordings.pickedRecording,
  projects: state.project.searchedProjectsList,
  readOnly: state.recordings.pickedReadOnly
})

export default withRouter(
  injectIntl(connect(mapStateToProps, null)(StatusAndArchivedBlock))
)
