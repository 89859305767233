import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'
import { Form, Input, DatePicker, Switch } from 'antd'

import StatusField from './Fields/StatusField'
import ExecutedBy from './Fields/ExecutedBy'
import SpecialWork from './Fields/SpecialWork'
import Priority from './Fields/Priority'
import '../../styles.scss'
import { updateWorkorderField } from '../../../../../../../../ducks/workorder'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

class Informations extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  render () {
    const { workorder, intl, dispatch } = this.props

    return (
      <>
        {workorder.income && workorder.income._id && <StatusField />}
        <Form.Item
          {...formItemLayout}
          label='Name'
          validateStatus={
            workorder.errors && workorder.errors.name ? 'error' : null
          }
          help={
            workorder.errors && workorder.errors.name
              ? intl.formatMessage({ id: 'name is required' })
              : null
          }
        >
          <Input
            value={
              workorder.value.name !== undefined
                ? workorder.value.name
                : workorder.income.name
            }
            onChange={event =>
              dispatch(updateWorkorderField({ name: event.target.value }))
            }
            disabled={workorder.income.archived}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'description'
          })}
        >
          <Input.TextArea
            value={workorder.value.description || workorder.income.description}
            onChange={event =>
              dispatch(
                updateWorkorderField({ description: event.target.value })
              )
            }
            disabled={workorder.income.archived}
          />
        </Form.Item>

        <ExecutedBy />

        <Priority />

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'special work required'
          })}
        >
          <Switch
            checked={
              workorder.value.specialWorkRequired !== undefined
                ? workorder.value.specialWorkRequired
                : workorder.income.specialWorkRequired
            }
            onChange={value =>
              dispatch(updateWorkorderField({ specialWorkRequired: value }))
            }
            disabled={workorder.income.archived}
          />
        </Form.Item>

        {((workorder.income.specialWorkRequired &&
          workorder.value.specialWorkRequired === undefined) ||
          workorder.value.specialWorkRequired) && <SpecialWork />}

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'due date'
          })}
        >
          <DatePicker
            disabledDate={disabledDate}
            allowClear={false}
            showTime
            value={
              (workorder.value.deliveryDate &&
                moment(workorder.value.deliveryDate)) ||
              (workorder.income.deliveryDate &&
                moment(workorder.income.deliveryDate))
            }
            format='DD.MM.YYYY HH:mm'
            onChange={date =>
              dispatch(updateWorkorderField({ deliveryDate: date.format() }))
            }
            disabled={workorder.income.archived}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'signature required'
          })}
        >
          <Switch
            checked={
              workorder.value.signatureRequired ||
              workorder.income.signatureRequired
            }
            defaultChecked
            onChange={value =>
              dispatch(updateWorkorderField({ signatureRequired: value }))
            }
            disabled={workorder.income.archived}
          />
        </Form.Item>
        {workorder.income._id && (
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'archive'
            })}
          >
            <Switch
              checked={
                workorder.value.archived !== undefined
                  ? workorder.value.archived
                  : workorder.income.archived
              }
              defaultChecked
              onChange={value =>
                dispatch(updateWorkorderField({ archived: value }))
              }
            />
          </Form.Item>
        )}
      </>
    )
  }
}

Informations.propTypes = {
  intl: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  workorder: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    workorder: state.workorder.pickedWorkorder,
    users: state.users.searchedUsersList
  }
}
export default injectIntl(connect(mapStateToProps)(Informations))

function disabledDate (current) {
  // Can not select days before today and today
  return (
    current &&
    current <
      moment()
        .endOf('day')
        .subtract(1, 'days')
  )
}
