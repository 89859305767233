import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'

import PageTitle from 'components/Global/PageTitle'
import Button from 'components/Global/Button'

import { exportCSV, exportProductCSV } from '../../../../../ducks/recordings'

export class RecordingsHeader extends React.Component {
  handleExportCSV = () => {
    const { dispatch } = this.props
    dispatch(exportCSV())
  }

  handleExportProductsCSV = async () => {
    const { dispatch } = this.props
    dispatch(exportProductCSV())
  }

  render () {
    const { intl, settings } = this.props

    return (
      <div>
        <FormattedMessage id='head.title.recordings'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={intl.formatMessage({ id: 'recordings' })}
          buttons={
            <>
              <Button
                onClick={this.handleExportCSV}
                icon='download'
                value={<FormattedMessage id='csv export' />}
              />
              {settings.data &&
                settings.data.datax &&
                settings.data.datax.enabled && (
                  <Button
                    style={{ marginLeft: 8 }}
                    onClick={this.handleExportProductsCSV}
                    icon='download'
                    value={<FormattedMessage id='csv product export' />}
                  />
                )}
            </>
          }
        />
      </div>
    )
  }
}

RecordingsHeader.propTypes = {
  settings: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    settings: state.settings
  }
}

export default injectIntl(connect(mapStateToProps)(RecordingsHeader))
