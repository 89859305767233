import React from 'react'

import moment from 'moment'
import { Button, Icon, Table } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PageTitle from 'components/Global/PageTitle'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

export class FormsPage extends React.Component {
  render () {
    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true,
        defaultSortOrder:
          this.props.params.sortField === 'name'
            ? this.props.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: (text, record) => {
          if (record.createdBy) {
            return record.createdBy.fullName
          }

          return ''
        }
      }
    ]

    if (!this.props.project) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project' }),
        key: 'project',
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      align: 'right',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <>
            <Button onClick={() => this.props.setForm(record)}>
              <Icon type='edit' />
            </Button>

            {this.props.auth.authUser.scope[0] === 'superadmin' && (
              <PopconfirmDelete
                icon='delete'
                value=''
                onConfirmAction={() => this.props.deleteForm(record)}
              />
            )}
          </>
        </div>
      )
    })

    const headerButtons = [
      <Button
        key={86568433536}
        type='primary'
        onClick={() => this.props.setForm({ name: '' })}
      >
        <FormattedMessage id='create form' />
      </Button>
    ]

    return (
      <div>
        <PageTitle
          title={this.props.intl.formatMessage({ id: 'forms' })}
          buttons={headerButtons}
        />
        <Table
          rowKey={record => record._id}
          columns={columns}
          dataSource={this.props.forms}
          onRow={record => ({
            onDoubleClick: () => {
              this.props.setForm(record)
            }
          })}
          loading={this.props.loading}
          onChange={this.props.onTableChange}
          defaultPageSize={this.props.pagination.defaultPageSize}
          pagination={this.props.pagination}
        />
      </div>
    )
  }
}

FormsPage.propTypes = {
  auth: PropTypes.object,
  params: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  project: PropTypes.object,
  setForm: PropTypes.func.isRequired,
  deleteForm: PropTypes.func.isRequired,
  forms: PropTypes.array.isRequired,
  onTableChange: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth
})

export default injectIntl(connect(mapStateToProps)(FormsPage))
