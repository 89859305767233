import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'

import { Form, Input } from 'antd'

import config from 'config'
import Zoom from 'react-medium-image-zoom'

class Images extends React.Component {
  render () {
    const images = this.props.request.images || []

    return (
      <>
        {images.map(image => (
          <div key={image._id}>
            <h3>
              <FormattedMessage id='photo' />
            </h3>

            <Form.Item
              {...this.props.formItemLayout}
              label={this.props.intl.formatMessage({ id: 'photo' })}
            >
              <Zoom zoomMargin={40}>
                <img
                  src={config.server.url + image.imageUrl}
                  className='img'
                  style={{ width: '100%' }}
                />
              </Zoom>
            </Form.Item>
            <Form.Item
              {...this.props.formItemLayout}
              label={this.props.intl.formatMessage({ id: 'comment' })}
            >
              <Input.TextArea
                disabled
                value={image.comment ? image.comment : ''}
              />
            </Form.Item>
          </div>
        ))}
      </>
    )
  }
}

Images.propTypes = {
  formItemLayout: PropTypes.object,
  request: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
  // readOnly: PropTypes.bool.isRequired
}

export default injectIntl(Images)
