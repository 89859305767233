import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Tabs } from 'antd'

import InformationTab from './Tabs/Information'
import CustomFormTab from './Tabs/CustomForm'
import PhotosTab from './Tabs/Photos'
import ProductsTab from './Tabs/Products'

import './styles.scss'

const { TabPane } = Tabs

class RecordingForm extends React.Component {
  render () {
    const {
      recording,
      customForms,
      dataXEnabled,
      acceptance,
      projects
    } = this.props

    let customForm =
      recording.project && recording.project.form
        ? recording.project.form
        : customForms.recording
    if (acceptance) {
      if (recording.project) {
        const projectId =
          recording.project && recording.project._id
            ? recording.project._id
            : null
        if (projectId) {
          const project = projects.find(project => project._id === projectId)
          customForm =
            (project.custom_forms && project.custom_forms.acceptance) ||
            customForms.acceptance
        }
      } else {
        customForm = customForms.acceptance
      }
    }
    return (
      <Tabs style={{ paddingBottom: 50 }} defaultActiveKey='form'>
        <TabPane
          tab={this.props.intl.formatMessage({
            id: 'informations'
          })}
          key='form'
        >
          <InformationTab />
        </TabPane>
        <TabPane
          tab={this.props.intl.formatMessage({ id: 'photos' })}
          key='photos'
        >
          <PhotosTab />
        </TabPane>
        {!!customForm && (customForm.fields || []).length > 0 && (
          <TabPane tab={customForm.name} key='extraFields'>
            <CustomFormTab customForm={customForm} />
          </TabPane>
        )}
        {dataXEnabled && (
          <TabPane
            tab={this.props.intl.formatMessage({ id: 'products' })}
            key='products'
          >
            <ProductsTab />
          </TabPane>
        )}
      </Tabs>
    )
  }
}

RecordingForm.propTypes = {
  recording: PropTypes.object,
  customForms: PropTypes.object,
  acceptance: PropTypes.bool,
  projects: PropTypes.array,
  intl: PropTypes.object.isRequired,
  dataXEnabled: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    projects: state.project.projectList,
    recording: state.recordings.pickedRecording,
    customForms: state.settings.customForms,
    dataXEnabled: state.settings.data.datax.enabled
  }
}

export default injectIntl(connect(mapStateToProps)(RecordingForm))
