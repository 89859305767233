import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Input, Select, Spin } from 'antd'
import isEqual from 'lodash/isEqual'

import { fetchProjectList } from '../../../ducks/project'
import { fetchContractSectionList } from '../../../ducks/contractSections'
import PropTypes from 'prop-types'

import './styles.scss'
import ContactSelect from '../../commonComponents/ContactSelect'

const { Option } = Select
const formItemLayout = {
  className: 'form-item-cover'
}

class CommonFields extends React.Component {
  state = {
    objects: [],
    projects: [],
    contractSections: [],
    contacts: []
  }

  fetchData = async (value, key) => {
    this.setState({
      formLoading: true
    })
    const { dispatch } = this.props
    const timer = setTimeout(async () => {
      try {
        if (key === 'projects') {
          await dispatch(
            fetchProjectList({ 'projectName[]': value, limit: 20 }, true)
          )
        } else if (key === 'contractSections') {
          await dispatch(
            fetchContractSectionList({ 'name[]': value, limit: 20 }, true)
          )
        }
        this.setState({
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          [key]: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { data, form } = this.props

    if (!isEqual(data, prevProps.data)) {
      form.resetFields()
    }
  }

  handleProjectChange = projectID => {
    const { updateStateDefect } = this.props
    this.setState({
      objects: []
    })

    updateStateDefect('project', projectID)
  }

  render () {
    const {
      updateStateDefect,
      data,
      intl,
      projects,
      contractSections
    } = this.props
    const { formLoading } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Form className='form-cover'>
        <p style={{ width: '100%', marginBottom: 0, marginTop: 16 }}>
          <b>{intl.formatMessage({ id: 'common data' })}</b>
        </p>
        <div style={{ width: '100%' }}>
          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({ id: 'project' })}
          >
            {getFieldDecorator('project', {
              initialValue: data.project ? data.project.projectName : ''
            })(
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                optionFilterProp='children'
                notFoundContent={
                  formLoading ? (
                    <div className='objects-spinner-container'>
                      <Spin size='small' />
                    </div>
                  ) : null
                }
                onFocus={() => this.fetchData('', 'projects')}
                onSearch={value => this.fetchData(value, 'projects')}
                onChange={this.handleProjectChange}
              >
                {projects.map(project => (
                  <Option key={project._id} value={project._id}>
                    {project.projectName}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </div>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'assignedTo'
          })}
        >
          {getFieldDecorator('assignedTo')(
            <ContactSelect
              pickContact={contact => updateStateDefect('assignedTo', contact)}
              currentValue={data.assignedTo}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'contract section'
          })}
        >
          {getFieldDecorator('contractSection', {
            initialValue: data.contractSection ? data.contractSection.name : ''
          })(
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              optionFilterProp='children'
              notFoundContent={
                formLoading ? (
                  <div className='objects-spinner-container'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              onFocus={() => this.fetchData('', 'contractSections')}
              onSearch={value => this.fetchData(value, 'contractSections')}
              onChange={(projectID, event) =>
                updateStateDefect(
                  'contractSection',
                  (event && event.props.data) || ''
                )
              }
            >
              {contractSections.map(element => (
                <Option key={element._id} data={element} value={element._id}>
                  {element.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'protocolResponsible'
          })}
        >
          {getFieldDecorator('protocolResponsible')(
            <ContactSelect
              pickContact={contact =>
                updateStateDefect('protocolResponsible', contact)
              }
              currentValue={data.protocolResponsible}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'protocolReference' })}
        >
          {getFieldDecorator('protocolReference', {
            initialValue: data.protocolReference || ''
          })(
            <Input
              onChange={event =>
                updateStateDefect('protocolReference', event.target.value)
              }
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'protocolExtResponsible'
          })}
        >
          {getFieldDecorator('protocolExtResponsible')(
            <ContactSelect
              pickContact={contact =>
                updateStateDefect('protocolExtResponsible', contact)
              }
              currentValue={data.protocolExtResponsible}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'protocolRefExt' })}
        >
          {getFieldDecorator('protocolRefExt', {
            initialValue: data.protocolRefExt || ''
          })(
            <Input
              onChange={event =>
                updateStateDefect('protocolRefExt', event.target.value)
              }
            />
          )}
        </Form.Item>
      </Form>
    )
  }
}

CommonFields.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  form: PropTypes.object,
  intl: PropTypes.object.isRequired,
  contractSections: PropTypes.array.isRequired,
  objects: PropTypes.array.isRequired,
  defectData: PropTypes.object,
  projects: PropTypes.array,
  objectName: PropTypes.string,
  errors: PropTypes.object,
  updateStateDefect: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired
}

const mapStateToProps = state => {
  return {
    defectData: state.recordings.recordingDefectCreationData,
    projects: state.project.projectList,
    objectName: state.settings.data.buildx.objectName,
    objects: state.objects.searchedObjectsList,
    contractSections: state.contractSections.searchedContractSections,
    contacts: state.contacts.searchedContacts
  }
}
export default Form.create()(injectIntl(connect(mapStateToProps)(CommonFields)))
