import { createReducer, createAction } from 'redux-act'

import { ContractSectionAPI } from '../services'
import { notification } from 'antd'

export const REDUCER = 'contractSection'
const NS = `@@${REDUCER}/`

export const setContractSectionList = createAction(
  `${NS}SET_CONTRACT_SECTION_LIST`
)
export const setPickedContractSection = createAction(
  `${NS}SET_PICKED_CONTRACT_SECTION`
)
export const setSearchedContractSections = createAction(
  `${NS}SET_SEARCHED_CONTRACT_SECTIONS`
)

const ContractSectionApi = new ContractSectionAPI()

export const initialState = {
  contractSectionList: [],
  pickedContractSection: {},
  searchedContractSections: []
}

export const fetchContractSectionList = (params, search = false) => (
  dispatch,
  getState
) => {
  return ContractSectionApi.listV2(params)
    .then(response => {
      if (search) {
        dispatch(setSearchedContractSections(response.docs))
      } else {
        dispatch(setContractSectionList(response.docs))
      }
      return true
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return false
    })
}

export default createReducer(
  {
    [setContractSectionList]: (state, contractSectionList) => ({
      ...state,
      contractSectionList
    }),
    [setPickedContractSection]: (state, pickedContractSection) => ({
      ...state,
      pickedContractSection
    }),
    [setSearchedContractSections]: (state, searchedContractSections) => ({
      ...state,
      searchedContractSections
    })
  },
  initialState
)
