import React, { useState, forwardRef } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'

const PriceInput = forwardRef(
  ({ defaultValue, precision, onChange: onChangeParent, ...props }, ref) => {
    const [state, setState] = useState(defaultValue)

    const isStateUsed = !('value' in props)
    const value = isStateUsed ? state : props.value

    const onChange = ({ target: { value } }) => {
      const parsed = PriceInput.parseValue(value)
      const handler = isStateUsed ? setState : onChangeParent
      handler(PriceInput.toFixed(parsed, precision))
    }

    const onBlur = ({ target: { value } }) => {
      const parsed = PriceInput.parseValue(value)
      const handler = isStateUsed ? setState : onChangeParent
      handler(PriceInput.toFixed(parsed, precision, true))
    }

    return (
      <Input
        ref={ref}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        {...props}
      />
    )
  }
)

PriceInput.parseValue = value => {
  const parsed = `${value || ''}`
    .replace(/[^0-9.-]/g, '')
    .replace(/^(-?)0{2,}/g, '$10')
    .replace(/\.{2,}/g, '.')
    .replace(/^(-?)0([1-9].*)/g, '$1$2')

  return parsed[0] === '.' ? `0${parsed}` : parsed
}

PriceInput.toFixed = (number, precision, pad = false) => {
  const toFixed = precision || -1
  const regexp = new RegExp(`^-?\\d+(?:\\.\\d{0,${toFixed}})?`, 'g')
  const [value] = number.toString().match(regexp) || []

  if (!value) return number === '-' ? number : value

  const dot = value.indexOf('.')
  if (toFixed <= 0 || dot === -1 || !pad) return value

  const count = toFixed - (value.length - dot) + 1
  return count > 0 ? `${value}${'0'.repeat(count)}` : value
}

PriceInput.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  precision: PropTypes.number,
  defaultValue: PropTypes.any
}

PriceInput.defaultProps = {
  precision: 2,
  onChange: () => {}
}

export default PriceInput
