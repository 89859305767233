import React from 'react'

import moment from 'moment'
import _ from 'lodash'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import formService from 'services/form'
import axios from 'axios'
import NumberFormat from 'react-number-format'

import {
  Table,
  notification,
  Icon,
  Form,
  Input,
  Menu,
  Dropdown,
  Tag,
  Modal,
  Spin,
  Button,
  Popconfirm,
  Upload
} from 'antd'

import defectsService from 'services/defects'
import imageService from 'services/image'
import projectService from 'services/project'
import contractSectionService from 'services/contractSection'
import config from 'config'

import DefectModalTable from 'components/Defect/ModalTable'
import PageTitle from 'components/Global/PageTitle'
import DrawerComponent from 'components/Drawer'
import RecordingForm from '../../../components/RecordingForm'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import DefectsDrawerTabs from './DefectsDrawerTabs'
import queryString from 'query-string'
import isEqual from 'lodash/isEqual'
import { setPickedProject } from '../../../ducks/project'
import {
  setPickedReadOnly,
  setPickedRecording
} from '../../../ducks/recordings'

import { downloadPDF } from '../../../ducks/defects'

export class DefectsPage extends React.Component {
  state = {
    items: [],
    item: {},
    new: false,
    projects: [],
    contractSections: [],
    selectedRowKeys: [],
    activeFilters: ['active', 'inactive'],
    loading: false,
    formLoading: false,
    sapModalShown: false,
    sapSending: false,
    sapUploadOBject: {},
    sapMessage: '',
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    filterOptions: {},
    defectRecording: {},
    defectDrawerVisible: false,
    recordingCustomForm: {},
    selectedRowObjects: [],
    assignToModalVisible: false,
    multipleUpdateField: '',
    fileUpload: false,
    uploading: false,
    uploadModal: false,
    uploadFile: null,
    params: {},
    recordingDrawerVisible: false,
    submitting: false
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.status) {
        searchQuery.status = ['open', 'in progress']
      }

      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, project } = this.props
    if (project) {
      paramsObject.project = project._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    if (isEqual(paramsObject.status, ['open', 'in progress'])) {
      delete paramsObject.status
    }
    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchDefects()
  }

  componentDidUpdate (prevProps) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      project,
      projects,
      dispatch
    } = this.props

    const params = { ...this.getParams() }

    if (params.project && !prevProps.projects.length && projects.length) {
      dispatch(
        setPickedProject(projects.find(item => item._id === params.project))
      )
    }

    if (project !== prevProps.project) {
      if (project && project._id) {
        this.setParams({ ...params, project: project._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }
    if (prevSearch !== nextSearch || this.props.project !== prevProps.project) {
      this.fetchDefects()
    }
  }

  fetchDefects = async () => {
    const params = this.getParams()
    this.setState({
      loading: true
    })
    try {
      const pagination = {
        ...this.state.pagination
      }

      const defect = await defectsService.listV2({
        limit: pagination.defaultPageSize,
        ...params
      })

      pagination.total = defect.totalDocs

      this.setState({
        items: defect.docs,
        loading: false,
        pagination: {
          ...pagination,
          pageSize: defect.limit,
          current: defect.page
        },
        params: { ...params },
        filterOptions: defect.filterOptions
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'defect'
        })} - download error`
      })

      this.setState({
        defectDrawerVisible: false,
        loading: false
      })
    }
  }

  fetchProjects = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const projects = await projectService.listV2({
          projectName: [value],
          limit: 100
        })

        this.setState({
          projects: projects.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          projects: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  fetchContractSections = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const contractSections = await contractSectionService.listV2({
          limit: 100
        })

        this.setState({
          contractSections: contractSections.docs,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          contractSections: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  handleOnTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon='search'
          size='small'
          disabled={!this.state[dataIndex + 'Search']}
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  handleUploadSAP = () => {
    const { sapUploadObject } = this.state
    const { intl } = this.props
    this.setState({
      sapSending: true
    })
    defectsService
      .submit(sapUploadObject._id)
      .then(response => {
        if (
          typeof response.response === 'object' &&
          response.response.submissions[0] &&
          response.response.submissions[0].reference
        ) {
          this.setState({
            sapSending: 'success',
            message: response.response.submissions[0].reference
          })
          this.fetchDefects()
        } else if (
          typeof response.response === 'string' &&
          response.response.indexOf('Error' > -1)
        ) {
          this.setState({
            sapSending: 'error'
          })
        }
      })
      .catch(e => {
        notification.open({
          type: 'error',
          message:
            e.error && e.error.indexOf('Attachment') > -1
              ? intl.formatMessage({ id: 'attachment error' })
              : intl.formatMessage({ id: 'error' })
        })
        this.setState({
          sapSending: 'error'
        })
      })
  }

  requestUploadSAP = defect => {
    this.setState({
      sapModalShown: true,
      sapSending: false,
      sapUploadObject: defect
    })
  }

  onSelectChange = selectedRowKeys => {
    const selectedRowObjects = this.state.items.filter(
      item => selectedRowKeys.indexOf(item._id) > -1
    )
    this.setState({
      selectedRowKeys,
      selectedRowObjects
    })
  }

  updateItem = (field, value) => {
    this.setState(state => ({
      item: {
        ...state.item,
        [field]: value
      }
    }))
  }

  showDrawer = item => {
    const { project } = this.props

    if (_.isEmpty(item, true)) {
      this.setState({
        defectDrawerVisible: true,
        item: {
          ...item,
          project
        },
        readOnlyNewProject: Object.keys(project).length !== 0
      })
    } else {
      this.setState({
        defectDrawerVisible: true,
        item: {
          ...item
        },
        readOnlyNewProject: false
      })
    }
  }

  handleSaveItem = async () => {
    const { item } = this.state

    this.props.form.validateFields(async err => {
      if (!err) {
        this.setState({
          submitting: true
        })
        try {
          ;(item.images || []).forEach(async image => {
            if (image.updated) {
              const formData = new FormData()
              formData.append('comment', image.comment)
              await imageService.update(image._id, formData)
            }
          })

          const savedItem = await defectsService.save(item)
          this.fetchDefects()
          if (this.state.item._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'updated successfully'
              })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'created successfully'
              })}`
            })
          }

          this.setState({
            defectDrawerVisible: false,
            item: {},
            items: [
              ...this.state.items.filter(item => item._id !== savedItem._id),
              savedItem
            ]
          })
          setTimeout(() => this.setState({ submitting: false }), 1500)
        } catch (error) {
          console.log(error)
          this.setState({
            submitting: false
          })
          notification.error({
            message: this.props.intl.formatMessage({ id: 'saving error' }),
            description: error.error
          })
        }
      }
    })
  }

  handleDeleteItem = async () => {
    try {
      const savedItem = await defectsService.deleteTask(this.state.item._id)
      this.fetchDefects()

      this.setState({
        defectDrawerVisible: false,
        item: {},
        items: [
          ...this.state.items.filter(item => item._id !== savedItem._id),
          savedItem
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'saving error'
        }),
        description: error.error
      })
    }
  }

  onHideDrawer = key => {
    this.setState({
      [key]: false
    })
    // this.props.dispatch(setPickedRecording({}))
    // this.props.dispatch(setPickedReadOnly(false))
  }

  onHandleRefClick = async defect => {
    const { globalCustomForms } = this.props

    let recordingCustomForm = {}
    if (defect.recording.project) {
      const project = defect.recording.project
      if (project && project.form) {
        if (defect.recording.acceptanceId) {
          recordingCustomForm =
            project.custom_form && project.custom_form.acceptance
        } else {
          recordingCustomForm = project.form
        }
      }
    } else {
      try {
        recordingCustomForm = await formService.get(
          defect.recording.acceptanceId
            ? globalCustomForms.acceptance
            : globalCustomForms.recording
        )
      } catch (error) {
        console.log(error)
      }
    }
    this.props.dispatch(setPickedRecording(defect.recording))
    this.props.dispatch(setPickedReadOnly(true))

    this.setState({
      defectRecording: defect.recording,
      defectDrawerVisible: true,
      recordingCustomForm
    })
  }

  handleExportCSV = () => {
    const { selectedRowObjects } = this.state
    let params = this.getParams()
    if (params && params.limit) {
      delete params.limit
    }
    if (selectedRowObjects) {
      params = {
        defects: selectedRowObjects.map(item => item._id)
      }
    }
    axios({
      method: 'get',
      url: `${config.server.url}/defect-csv`,
      responseType: 'text/csv',
      headers: {
        authorization: localStorage.getItem('authToken')
      },
      params: params
    }).then(function (response) {
      const blob = new Blob([response.data], { type: 'text/csv' })
      const fileName = `defects.csv`

      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName)
      } else {
        const dataURI = `data:text/csv;charset=utf-8,${response.data}`

        const URL = window.URL || window.webkitURL
        const downloadURI =
          typeof URL.createObjectURL === 'undefined'
            ? dataURI
            : URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.setAttribute('href', downloadURI)
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    })
  }

  finishSubmitting = () => {
    this.fetchDefects()
    this.setState({
      assignToModalVisible: false,
      selectedRowObjects: [],
      selectedRowKeys: []
    })
  }

  onHandleFileUpdate = async filesArray => {
    const { item } = this.state
    this.setState({
      item: { ...item, files: filesArray }
    })
  }

  onHandleImageUpdate = async imagesArray => {
    const { item } = this.state
    this.setState({
      item: { ...item, images: imagesArray }
    })
  }

  showUploadModal = () => {
    this.setState({
      uploadModal: true
    })
  }

  handleCsvUpload = async () => {
    if (!this.state.uploadFile || this.state.uploading) {
      return false
    }

    this.setState({
      uploading: true
    })

    try {
      const uploadReasponse = await defectsService.uploadCSV(
        this.state.uploadFile,
        this.state.force
      )

      if (uploadReasponse.success) {
        notification.success({
          message: this.props.intl.formatMessage({
            id: 'file has been imported successfully'
          })
        })

        await this.fetchDefects()

        this.handleHideUploadModal()
      } else {
        this.setState({
          force: true
        })

        notification.error({
          message: uploadReasponse.message
        })
      }
    } catch (error) {
      console.log(error)
      notification.error({
        message: this.props.intl.formatMessage({ id: 'uploading error' })
      })
    }

    this.setState({
      uploading: false
    })
  }

  handleHideUploadModal = () => {
    this.setState({
      uploadModal: false,
      uploadFile: null,
      uploading: false,
      force: false
    })
  }

  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      notification.error({
        message:
          this.props.intl.formatMessage({ id: 'image must smaller than' }) +
          ' 2MB'
      })
    }

    if (isLt2M) {
      this.setState({
        uploadFile: file
      })
    }

    return false
  }

  showPDF = defect => {
    const { dispatch, intl } = this.props
    dispatch(downloadPDF(defect)).then(response => {
      if (response.error) {
        notification.open({
          type: 'error',
          message:
            response.error === 422
              ? intl.formatMessage({ id: 'attachment error' })
              : intl.formatMessage({ id: 'error' })
        })
      }
    })
  }

  formContactConnection = data => {
    let value = ''
    if (data) {
      // eslint-disable-next-line  camelcase
      const { contact_sub, contact_sup } = data
      const formContactString = contact => {
        let valueString = ''
        if (contact.contact_type === 'company' || contact.company) {
          valueString += contact.company
        } else {
          valueString += `${contact.firstName || ''}${
            contact.lastName ? ` ${contact.lastName}` : ''
          }`
        }
        return valueString
      }
      // eslint-disable-next-line  camelcase
      if (contact_sup) {
        value += formContactString(contact_sup)
      }
      // eslint-disable-next-line  camelcase
      if (contact_sub) {
        const subValue = formContactString(contact_sub)
        if (subValue.length) {
          // eslint-disable-next-line  camelcase
          value += contact_sup ? ` (${subValue})` : `${subValue}`
        }
      }
    }

    return value
  }

  render () {
    const {
      sapSending,
      sapModalShown,
      sapUploadObject,
      sapMessage,
      drawerVisible,
      formLoading,
      contractSections,
      item,
      items,
      loading,
      selectedRowKeys,
      readOnlyNewProject,
      defectRecording,
      defectDrawerVisible,
      recordingCustomForm,
      selectedRowObjects,
      assignToModalVisible,
      multipleUpdateField,
      uploadModal,
      uploading,
      uploadFile,
      recordingDrawerVisible,
      submitting
    } = this.state

    const { projects, intl, objectName } = this.props
    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        sorter: true,
        width: 120,
        fixed: 'left',
        ...this.getColumnSearchProps('docNumber')
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 300,
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: objectName,
        dataIndex: 'objectId',
        key: 'objectId',
        width: 220,
        render: (text, record) => {
          const data = []

          if (record.objectId && record.objectId.name) {
            data.push(record.objectId.name)
          }

          return data
        },
        ...this.getColumnSearchProps('objectId')
      },
      {
        title: this.props.intl.formatMessage({ id: 'protocolReference' }),
        dataIndex: 'protocolReference',
        key: 'protocolReference',
        width: 180,
        ...this.getColumnSearchProps('protocolReference')
      },
      {
        title: this.props.intl.formatMessage({ id: 'protocolResponsible' }),
        dataIndex: 'protocolResponsible',
        key: 'protocolResponsible',
        width: 250,
        filters: (this.state.filterOptions.protocolResponsible || []).map(
          item => ({
            text: item.company,
            value: item._id
          })
        ),
        render: (text, record) => {
          return this.formContactConnection(record.protocolResponsible || {})
        },
        filteredValue: this.state.params.protocolResponsible
      },
      {
        title: this.props.intl.formatMessage({ id: 'protocolRefExt' }),
        width: 250,
        key: 'protocolRefExt',
        dataIndex: 'protocolRefExt',
        ...this.getColumnSearchProps('protocolRefExt')
      },
      {
        title: this.props.intl.formatMessage({ id: 'estimated_cost' }),
        dataIndex: 'estimated_cost',
        key: 'estimated_cost',
        width: 150,
        sorter: (a, b) => a.estimated_cost - b.estimated_cost,
        align: 'right',
        render: (text, record) => {
          return (
            <NumberFormat
              displayType='text'
              value={record.estimated_cost}
              thousandSeparator={"'"}
              prefix='CHF '
            />
          )
        }
      },
      {
        title: this.props.intl.formatMessage({ id: 'contract section' }),
        dataIndex: 'contractSection',
        key: 'contractSection',
        sorter: true,
        width: 220,
        render: (text, record) => {
          if (record.contractSection && record.contractSection.name) {
            return record.contractSection.name
          }
          return ''
        },
        ...this.getColumnSearchProps('contractSection')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 200,
        sorter: true,
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: (this.state.filterOptions.createdBy || []).map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: this.state.params.createdBy
      },
      {
        title: this.props.intl.formatMessage({ id: 'reference number' }),
        key: 'submittedRefNr',
        width: 250,
        dataIndex: 'submittedRefNr',
        ...this.getColumnSearchProps('submittedRefNr'),
        render: (text, record) => {
          if (record.recording && record.recording.docNumber) {
            return (
              <Tag
                style={{ cursor: 'pointer' }}
                onClick={() => this.onHandleRefClick(record)}
                color='blue'
              >
                <Icon type='check' />
                &nbsp;
                {record.recording.docNumber}
              </Tag>
            )
          }

          return ''
        }
      },
      {
        title: this.props.intl.formatMessage({ id: 'assigned to' }),
        dataIndex: 'assignedTo',
        key: 'assignedTo',
        width: 150,
        render: (text, record) => {
          return this.formContactConnection(record.protocolResponsible || {})
        },
        filters: (this.state.filterOptions.assignedTo || []).map(user => ({
          text: user.company,
          value: user._id
        })),
        filteredValue: this.state.params.assignedTo
      },
      {
        title: 'SAP',
        key: 'sap',
        sorter: true,
        width: 150,
        ...this.getColumnSearchProps('submittedRefNr'),
        render: (text, record) => {
          const data = []

          if (
            record.submissions &&
            record.submissions.some(e => e.name === 'sap')
          ) {
            data.push(
              <Tag color='green'>
                <Icon type='check' />
                &nbsp;
                {record.submissions.map((a, index) => (
                  <span key={index}>{a.reference}</span>
                ))}
              </Tag>
            )
          }

          return data
        }
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 100,
        filters: [
          {
            text: intl.formatMessage({ id: 'open' }),
            value: 'open'
          },
          {
            text: intl.formatMessage({ id: 'in progress' }),
            value: 'in progress'
          },
          {
            text: intl.formatMessage({ id: 'completed' }),
            value: 'completed'
          }
        ],
        filteredValue: this.state.params.status || ['open', 'in progress'],
        render: (text, record) => {
          switch (record.status) {
            case 'open':
              return intl.formatMessage({ id: 'open' })
            case 'in progress':
              return intl.formatMessage({ id: 'in progress' })
            case 'completed':
              return intl.formatMessage({
                id: 'completed'
              })
            default:
              return ''
          }
        }
      }
    ]

    if (!this.props.project._id) {
      columns.push({
        title: this.props.intl.formatMessage({ id: 'project' }),
        key: 'project',
        width: 220,
        dataIndex: 'project.projectName'
      })
    }

    columns.push({
      key: 'actions',
      fixed: 'right',
      width: 50,
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='0' onClick={() => this.showDrawer(record)}>
                  <Icon type='edit' />
                  &nbsp;
                  {this.props.intl.formatMessage({ id: 'edit' })}
                </Menu.Item>

                <Menu.Item key='1' onClick={() => this.showPDF(record)}>
                  <Icon type='file-pdf' />
                  <FormattedMessage id='generate pdf' />
                </Menu.Item>

                <Menu.Divider />

                {record.submissions &&
                  !record.submissions.some(e => e.name === 'sap') && (
                    <Menu.Item
                      key='3'
                      onClick={() => this.requestUploadSAP(record)}
                    >
                      <Icon type='upload' />
                      &nbsp;
                      {this.props.intl.formatMessage({
                        id: 'send to sap',
                        defaultMessage: 'Send to SAP'
                      })}
                    </Menu.Item>
                  )}

                {record.submissions &&
                  record.submissions.some(e => e.name === 'sap') && (
                    <Menu.Item key='3' disabled>
                      <Icon type='check' />
                      &nbsp;
                      {this.props.intl.formatMessage({
                        id: 'submitted to sap'
                      })}
                    </Menu.Item>
                  )}
              </Menu>
            }
            trigger={['click']}
          >
            <a className='ant-dropdown-link' href='#'>
              <Icon type='more' style={{ fontSize: '2rem', color: '#444' }} />
            </a>
          </Dropdown>
        </div>
      )
    })
    const drawerButtons = [
      <Button
        key={65493152}
        onClick={
          defectDrawerVisible
            ? () => this.onHideDrawer('defectDrawerVisible')
            : () => {
                this.onHideDrawer('recordingDrawerVisible')
                this.fetchDefects()
              }
        }
        style={{ marginRight: 8 }}
      >
        <FormattedMessage id='cancel' />
      </Button>,
      <Button
        key={237323536}
        onClick={this.handleSaveItem}
        type='primary'
        loading={submitting}
        disabled={recordingDrawerVisible || submitting}
      >
        <FormattedMessage id='save' />
      </Button>
    ]

    if (
      ['admin', 'superadmin'].includes(this.props.auth.authUser.scope[0]) &&
      item._id
    ) {
      drawerButtons.unshift(
        <PopconfirmDelete key={3} onConfirmAction={this.handleDeleteItem} />
      )
    }

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    }

    const menu = (
      <Menu>
        <Menu.Item
          disabled={selectedRowObjects.length === 0}
          onClick={() =>
            this.setState({
              assignToModalVisible: true
            })
          }
        >
          <Icon type='tool' />
          &nbsp;
          <FormattedMessage id='bulk change' />
        </Menu.Item>
        <Menu.Item disabled={loading} onClick={this.handleExportCSV}>
          <Icon type='export' />
          &nbsp;
          <FormattedMessage id='csv export' />{' '}
          {selectedRowObjects.length
            ? `(${selectedRowObjects.length} ${intl.formatMessage({
                id: 'defects'
              })})`
            : ''}
        </Menu.Item>

        <Menu.Item disabled={loading} onClick={() => this.showUploadModal({})}>
          <Icon type='import' />
          &nbsp;
          <FormattedMessage id='csv import' />
        </Menu.Item>
      </Menu>
    )

    const headerButtons = [
      <Dropdown overlay={menu} key='actions-dropdown'>
        <Button>
          <FormattedMessage id='actions' />
          <Icon type='down' />
        </Button>
      </Dropdown>,
      <Button
        style={{ marginLeft: 8 }}
        key={238397323536}
        type='primary'
        onClick={() => this.showDrawer({})}
      >
        <FormattedMessage id='create defect' />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id='head.title.defects'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'defects' })}
          buttons={headerButtons}
        />
        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => (this._table = ref)}
          columns={columns}
          rowSelection={rowSelection}
          size='small'
          dataSource={items}
          loading={loading}
          onRow={record => ({
            onDoubleClick: () => {
              this.showDrawer(record)
            }
          })}
          scroll={{ x: 2800 }}
          rowKey={record => record._id}
          onChange={this.handleOnTableChange}
          defaultPageSize={this.state.pagination.defaultPageSize}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={
            item.docNumber
              ? item.docNumber
              : this.props.intl.formatMessage({ id: 'create defect' })
          }
          onClose={() => this.onHideDrawer('defectDrawerVisible')}
          visible={defectDrawerVisible}
          footerButtons={drawerButtons}
        >
          <DefectsDrawerTabs
            workorder={this.props.workOrder}
            updateFiles={this.onHandleFileUpdate}
            updateImages={this.onHandleImageUpdate}
            defect={item}
            projects={projects}
            contractSections={contractSections}
            readOnlyNewProject={readOnlyNewProject}
            formLoading={formLoading}
            fetchProjects={this.fetchProjects}
            fetchContractSections={this.fetchContractSections}
            updateItem={this.updateItem}
            form={this.props.form}
            handleRefClick={this.onHandleRefClick}
            defectRecording={defectRecording}
            defectDrawerVisible={defectDrawerVisible}
            recordingCustomForm={recordingCustomForm}
            drawerButtons={drawerButtons}
            hideDrawer={this.onHideDrawer}
          />
        </DrawerComponent>
        {!drawerVisible && (
          <DrawerComponent
            title={
              this.props.intl.formatMessage({ id: 'recording details' }) +
              ` (${defectRecording.name})`
            }
            onClose={() => this.onHideDrawer('defectDrawerVisible')}
            visible={recordingDrawerVisible}
            footerButtons={drawerButtons}
            coverStyle={{ zIndex: '1001' }}
          >
            <RecordingForm
              recording={defectRecording}
              extraFieldsForm={recordingCustomForm}
              form={this.props.form}
              readOnly
              acceptance={defectRecording.acceptanceId}
              defectsPage
            />
          </DrawerComponent>
        )}

        <Modal
          title=''
          visible={sapModalShown}
          width={640}
          onCancel={() =>
            this.setState({ sapModalShown: false, sapSending: false })
          }
          onOk={this.handleUploadSAP}
          destroyOnClose
          confirmLoading={typeof sapSending === 'string' ? false : sapSending}
          footer={[
            sapSending !== 'success' && (
              <Button
                key='back'
                onClick={() =>
                  this.setState({ sapModalShown: false, sapSending: false })
                }
              >
                Cancel
              </Button>
            ),
            <Button
              key='submit'
              type='primary'
              loading={typeof sapSending === 'string' ? false : sapSending}
              onClick={
                sapSending !== 'success'
                  ? this.handleUploadSAP
                  : () =>
                      this.setState({
                        sapModalShown: false,
                        sapSending: false
                      })
              }
            >
              {sapSending === false && 'Submit'}
              {sapSending === true && 'Processing'}
              {sapSending === 'error' && 'Restart'}
              {sapSending === 'success' && 'Close'}
            </Button>
          ]}
        >
          <div
            style={{
              minHeight: 350,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {sapSending ? (
              <div>
                <h4 style={{ textAlign: 'center', lineHeight: 1.3 }}>
                  {sapSending === true && (
                    <>
                      <Spin style={{ marginRight: 8 }} size='large' />{' '}
                      {this.props.intl.formatMessage({
                        id: 'uploading to SAP'
                      })}{' '}
                      <br />
                      <b>{sapUploadObject.name}</b>
                    </>
                  )}
                  {sapSending === 'success' && (
                    <>
                      <Icon
                        type='check-circle'
                        style={{
                          fontSize: 30,
                          color: '#52c41a',
                          marginBottom: 20
                        }}
                      />{' '}
                      <br />
                      <b>{sapUploadObject.name}</b> <br />
                      {this.props.intl.formatMessage({
                        id: 'Uploaded successfully to SAP'
                      })}{' '}
                      <br />
                      {sapMessage}
                    </>
                  )}
                  {sapSending === 'error' && (
                    <>
                      <Icon
                        type='close-circle'
                        style={{ fontSize: 30, color: 'red', marginBottom: 20 }}
                      />{' '}
                      <br />
                      Error uploading <b>{sapUploadObject.name}</b> <br />
                      {sapMessage} <br />
                      <span style={{ fontStyle: 'italic' }}>
                        Please try again later or contact DDC Schweiz AG.
                      </span>
                    </>
                  )}
                </h4>
              </div>
            ) : (
              <div>
                <h4 style={{ textAlign: 'center', lineHeight: 1.3 }}>
                  {this.props.intl.formatMessage({
                    id: 'You are about to start sending'
                  })}{' '}
                  <b>{(sapUploadObject && sapUploadObject.name) || ''}</b>
                </h4>
                <p
                  style={{
                    fontSize: 16,
                    fontStyle: 'italic',
                    marginTop: 25,
                    marginBottom: 0,
                    textAlign: 'center'
                  }}
                >
                  {this.props.intl.formatMessage({
                    id: 'interface-process-description'
                  })}
                </p>
              </div>
            )}
          </div>
        </Modal>

        <DefectModalTable
          assignToModalVisible={assignToModalVisible}
          selectedRowObjects={selectedRowObjects}
          finishSubmitting={this.finishSubmitting}
          multipleUpdateField={multipleUpdateField}
        />

        <Modal
          title='Upload CSV'
          visible={!!uploadModal}
          onCancel={this.handleHideUploadModal}
          footer={
            this.state.force ? (
              <Popconfirm
                title={this.props.intl.formatMessage({
                  id: 'confirm overwriting'
                })}
                onConfirm={this.handleCsvUpload}
                okText={this.props.intl.formatMessage({ id: 'overwrite' })}
                cancelText={this.props.intl.formatMessage({ id: 'cancel' })}
              >
                <Button
                  type='danger'
                  disabled={uploading || !uploadFile}
                  loading={uploading}
                >
                  <FormattedMessage id='force import' />
                  <Icon type='upload' />
                </Button>
              </Popconfirm>
            ) : (
              <Button
                type='primary'
                disabled={uploading || !uploadFile}
                loading={uploading}
                onClick={this.handleCsvUpload}
              >
                <FormattedMessage id='import' />
                <Icon type='upload' />
              </Button>
            )
          }
          destroyOnClose
        >
          <Upload
            beforeUpload={this.beforeUpload}
            fileList={uploadFile ? [uploadFile] : []}
            onRemove={() => this.setState({ uploadFile: null })}
            destroyOnClose
          >
            <Button>
              <FormattedMessage id='select csv file' />
            </Button>
          </Upload>
        </Modal>
      </div>
    )
  }
}

DefectsPage.propTypes = {
  auth: PropTypes.object,
  intl: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  project: PropTypes.object,
  projects: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  globalCustomForms: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  workOrder: PropTypes.object,
  objectName: PropTypes.string,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    projects: state.project.projectList,
    project: state.project.pickedProject,
    objectName:
      (state.settings.data.buildx && state.settings.data.buildx.objectName) ||
      '',
    globalCustomForms:
      (state.settings.data.buildx && state.settings.data.buildx.custom_forms) ||
      {}
  }
}

export default injectIntl(
  Form.create({ name: 'defects_page' })(connect(mapStateToProps)(DefectsPage))
)
