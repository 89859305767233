import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Form, Input, Select, Spin } from 'antd'

class ProjectForm extends React.Component {
  render () {
    const { getFieldDecorator } = this.props.form
    const { project, users, forms, updateProject } = this.props

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    let projectManager
    if (project.projectManager) {
      if (typeof project.projectManager === 'object') {
        projectManager = project.projectManager.fullName
      } else {
        projectManager = ''
      }
    }

    let form
    if (project.form) {
      if (typeof project.form === 'object') {
        form = project.form.name
      } else {
        form = ''
      }
    }

    let custom_forms // eslint-disable-line
    if (project.custom_forms && project.custom_forms.acceptance) {
      custom_forms = project.custom_forms.acceptance // eslint-disable-line
    }

    return (
      <Form onSubmit={() => {}} className='projectForm'>
        <Form.Item {...formItemLayout} label='Name' required={false}>
          {getFieldDecorator('projectName', {
            initialValue: project.projectName,
            rules: [
              {
                required: true,
                message: `Name ${this.props.intl.formatMessage({
                  id: 'is required'
                })}`
              }
            ]
          })(
            <Input
              disabled={project.archived}
              onChange={event =>
                updateProject('projectName', event.target.value)
              }
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={this.props.intl.formatMessage({ id: 'number' })}
          required={false}
        >
          {getFieldDecorator('projectNumber', {
            initialValue: project.projectNumber,
            rules: [
              {
                required: true,
                message: `${this.props.intl.formatMessage({
                  id: 'number'
                })} ${this.props.intl.formatMessage({ id: 'is required' })}`
              }
            ]
          })(
            <Input
              disabled={project.archived}
              onChange={event =>
                updateProject('projectNumber', event.target.value)
              }
            />
          )}
        </Form.Item>

        <Form.Item {...formItemLayout} label='Manager'>
          <Select
            showSearch
            allowClear
            disabled={project.archived}
            style={{ width: '100%' }}
            placeholder={this.props.intl.formatMessage({
              id: 'select manager'
            })}
            notFoundContent={
              <div className='project-spinner-container'>
                <Spin size='small' />
              </div>
            }
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onSearch={value => this.props.fetchUsers(value)}
            onChange={value => updateProject('projectManager', value)}
            defaultValue={projectManager}
            onFocus={() => this.props.fetchUsers('')}
          >
            {users.map(user => (
              <Select.Option key={user._id} value={user._id}>
                {user.fullName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={this.props.intl.formatMessage({ id: 'recording form' })}
        >
          <Select
            showSearch
            allowClear
            disabled={project.archived}
            style={{ width: '100%' }}
            notFoundContent={
              <div className='project-spinner-container'>
                <Spin size='small' />
              </div>
            }
            placeholder={this.props.intl.formatMessage({ id: 'select form' })}
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onSearch={value => this.props.fetchForms(value)}
            onChange={value => updateProject('form', value)}
            defaultValue={form}
            onFocus={() => this.props.fetchForms('')}
            // value={form}
          >
            {forms.map(form => {
              return (
                <Select.Option key={form._id} value={form._id}>
                  {form.name}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={this.props.intl.formatMessage({ id: 'acceptance form' })}
        >
          <Select
            showSearch
            allowClear
            disabled={project.archived}
            style={{ width: '100%' }}
            notFoundContent={
              <div className='project-spinner-container'>
                <Spin size='small' />
              </div>
            }
            placeholder={this.props.intl.formatMessage({ id: 'select form' })}
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            onSearch={value => this.props.fetchForms(value)}
            onChange={value => updateProject('custom_forms.acceptance', value)}
            defaultValue={custom_forms && custom_forms.name} // eslint-disable-line
            onFocus={() => this.props.fetchForms('')}
            // value={form}
          >
            {forms.map(form => {
              return (
                <Select.Option key={form._id} value={form._id}>
                  {form.name}
                </Select.Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item {...formItemLayout} label='Status' required={false}>
          {getFieldDecorator('status', {
            initialValue: project.status,
            rules: [
              {
                required: true,
                message: `Status ${this.props.intl.formatMessage({
                  id: 'is required'
                })}`
              }
            ]
          })(
            <Select
              disabled={project.archived}
              style={{ width: '100%' }}
              onChange={value => updateProject('status', value)}
            >
              <Select.Option value='opened'>opened</Select.Option>
              <Select.Option value='inprogress'>in progress</Select.Option>
            </Select>
          )}
        </Form.Item>
      </Form>
    )
  }
}

ProjectForm.propTypes = {
  project: PropTypes.object,
  users: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  updateProject: PropTypes.func.isRequired,
  fetchForms: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default injectIntl(ProjectForm)
