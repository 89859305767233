import HttpClient from './index'
import config from 'config'

class TasksAPI extends HttpClient {
  async list (params = {}) {
    return this.get('/task', params)
  }

  async listV2 (params = {}) {
    return this.get(config.server.host + '/1.1/task', params)
  }

  async saveTask (data) {
    return this.post('/task', data)
  }

  async completeTask (id, data = {}) {
    return this.post(`/task/${id}/complete`, data)
  }

  async deleteTask (id) {
    return this.delete(`/task/${id}`)
  }

  async updateTask (id, data) {
    return this.put(`/task/${id}`, data)
  }
}
export default TasksAPI
