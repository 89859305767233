import { useState, useEffect, useRef } from 'react'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'

const usePagination = ({
  limit: defaultLimit = 10,
  offset: defaultOffset = 1,
  sortField: defaultSortField,
  sortOrder: defaultSortOrder
} = {}) => {
  const history = useHistory()
  const location = useLocation()
  const {
    limit: parseLimit,
    offset: parseOffset,
    sortField: parseSortField,
    sortOrder: parseSortOrder,
    ...parseFilters
  } = queryString.parse(location.search, { arrayFormat: 'bracket' })

  const [limit, setLimit] = useState(parseInt(parseLimit || defaultLimit, 10))
  const [offset, setOffset] = useState(
    parseInt(parseOffset || defaultOffset, 10)
  )

  const [sortField, setSortField] = useState(parseSortField || defaultSortField)
  const [sortOrder, setSortOrder] = useState(parseSortOrder || defaultSortOrder)

  const [filters, setFilters] = useState(parseFilters || {})
  const selectedProject = useSelector(
    ({ project: { pickedProject } }) => pickedProject
  )

  const push = (params = {}) =>
    history.push(
      `?${queryString.stringify(
        Object.assign(params, { project: selectedProject._id }),
        { skipNull: true, arrayFormat: 'bracket' }
      )}`
    )

  const mount = useRef(false)

  useEffect(() => {
    if (!mount.current) return
    push({
      offset: 1,
      limit,
      sortField,
      sortOrder,
      ...(filters || {})
    })
  }, [limit, sortField, sortOrder])

  useEffect(() => {
    mount.current = true
  }, [])

  useEffect(() => {
    const {
      limit,
      offset,
      sortField,
      sortOrder,
      ...filters
    } = queryString.parse(location.search, { arrayFormat: 'bracket' })
    setLimit(parseInt(limit || defaultLimit, 10))
    setOffset(parseInt(offset || defaultOffset, 10))
    setSortField(sortField || defaultSortField)
    setSortOrder(sortOrder || defaultSortOrder)
    setFilters(filters || {})
  }, [location])

  const onChange = ({ current, pageSize }, filters, { order, columnKey }) => {
    const params = {
      limit: pageSize,
      offset: current,
      ...(filters || {})
    }

    if (order) {
      Object.assign(params, {
        sortOrder: order,
        sortField: columnKey
      })
    }

    push(params)
  }

  return {
    limit,
    offset,
    sortField,
    sortOrder,
    filters,
    push,
    onChange
  }
}

export default usePagination
