import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl, IntlProvider } from 'react-intl'
import contactService from 'services/contact'
import contractSectionService from 'services/contractSection'
import { Button, Modal, notification, Select, Input, Spin, Table } from 'antd'
import defectsService from 'services/defects'
import { connect } from 'react-redux'

const { ...intl } = IntlProvider.propTypes
const { Option } = Select

const fieldsArray = [
  {
    key: 'assignedTo',
    label: 'change assignment',
    placeholder: 'choose a new contact',
    fetchField: 'contacts',
    fieldType: 'select'
  },
  {
    key: 'status',
    label: 'change status',
    placeholder: 'choose a new status',
    options: ['open', 'in progress', 'completed'],
    fieldType: 'select'
  },
  {
    key: 'protocolReference',
    label: 'protocol reference',
    placeholder: 'choose a new protocol reference',
    fieldType: 'input'
  },
  {
    key: 'protocolResponsible',
    label: 'protocol responsible',
    placeholder: 'choose protocol responsible',
    fetchField: 'contacts',
    fieldType: 'select'
  },
  {
    key: 'protocolRefExt',
    label: 'protocol reference external',
    placeholder: 'choose a new external protocol protocol reference',
    fieldType: 'input'
  },
  {
    key: 'protocolExtResponsible',
    label: 'protocolExtResponsible',
    placeholder: 'protocolExtResponsible',
    fieldType: 'select'
  },
  {
    key: 'contractSection',
    label: 'contract section',
    placeholder: 'contract section',
    fieldType: 'select'
  }
]

class ModalTable extends React.Component {
  state = {
    contactsArray: [],
    statusArray: ['open', 'in progress', 'completed']
  }

  saveItem = async item => {
    const { intl } = this.props
    try {
      const savedItem = await defectsService.save(item)
      if (savedItem._id) {
        return savedItem
      }
    } catch (error) {
      notification.error({
        message: intl.formatMessage({ id: 'saving error' }),
        description: error.error
      })
      return error
    }
  }

  fetchData = async (value, key) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        let data
        if (key.indexOf('contractSection') > -1) {
          data = await contractSectionService.listV2({
            name: [value],
            limit: 10
          })
        } else {
          data = await contactService.listV2({
            company: [value],
            limit: 10
          })
        }
        this.setState({
          [key]: data.docs || data,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          [key]: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  handlePick = (key, value) => {
    this.setState({
      [key]: value
    })
  }

  handleSubmitAssignment = async () => {
    const { selectedRowObjects, finishSubmitting, intl } = this.props

    const result = await Promise.all(
      selectedRowObjects.map(item => {
        fieldsArray.forEach(field => {
          if (this.state[field.key]) {
            item[field.key] = this.state[field.key]._id
              ? this.state[field.key]._id
              : this.state[field.key]
          }
        })

        return this.saveItem(item)
      })
    )

    if (!result.some(item => !item || item.error)) {
      finishSubmitting(true)
      notification.success({
        message: `${intl.formatMessage({
          id: 'updated successfully'
        })}`
      })
      this.setState({
        status: null,
        assignedTo: null
      })
    } else {
      notification.success({
        message: `${intl.formatMessage({ id: 'saving error' })}`
      })
    }
  }

  render () {
    const {
      assignToModalVisible,
      selectedRowObjects,
      finishSubmitting,
      intl
    } = this.props

    const { formLoading } = this.state
    const columns = [
      {
        title: intl.formatMessage({ id: 'number' }),
        key: 'docNumber',
        dataIndex: 'docNumber'
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: intl.formatMessage({ id: 'assigned to' }),
        dataIndex: 'assignedTo',
        key: 'assignedTo',
        render: (text, { assignedTo }) =>
          assignedTo &&
          assignedTo.contact_type &&
          assignedTo.contact_type === 'user'
            ? `${assignedTo.firstName || ''} ${assignedTo.lastName || ''}`
            : (assignedTo && (assignedTo.company || assignedTo.name)) || ''
      },
      {
        title: intl.formatMessage({ id: 'protocolReference' }),
        dataIndex: 'protocolReference',
        key: 'protocolReference'
      },
      {
        title: intl.formatMessage({ id: 'protocolResponsible' }),
        dataIndex: 'protocolResponsible',
        key: 'protocolResponsible',
        render: (text, { protocolResponsible }) =>
          protocolResponsible &&
          protocolResponsible.contact_type &&
          protocolResponsible.contact_type === 'user'
            ? `${protocolResponsible.firstName ||
                ''} ${protocolResponsible.lastName || ''}`
            : (protocolResponsible &&
                (protocolResponsible.company || protocolResponsible.name)) ||
              ''
      },
      {
        title: intl.formatMessage({ id: 'protocolRefExt' }),
        key: 'protocolRefExt',
        dataIndex: 'protocolRefExt'
      },
      {
        title: intl.formatMessage({ id: 'protocolExtResponsible' }),
        key: 'protocolExtResponsible',
        render: (text, { protocolExtResponsible }) =>
          protocolExtResponsible &&
          protocolExtResponsible.contact_type &&
          protocolExtResponsible.contact_type === 'user'
            ? `${protocolExtResponsible.firstName ||
                ''} ${protocolExtResponsible.lastName || ''}`
            : (protocolExtResponsible &&
                (protocolExtResponsible.company ||
                  protocolExtResponsible.name)) ||
              ''
      },
      {
        title: intl.formatMessage({ id: 'contract section' }),
        key: 'contractSection',
        render: (text, record) =>
          (record.contractSection && record.contractSection.name) || ''
      },
      {
        title: intl.formatMessage({ id: 'status' }),
        dataIndex: 'status',
        key: 'status',
        render: (text, record) =>
          record.status ? intl.formatMessage({ id: record.status }) : ''
      }
    ]

    const columnsUpdate = {}
    fieldsArray.forEach(field => {
      if (this.state[field.key]) {
        columnsUpdate[field.key] = this.state[field.key]
      }
    })
    const updatedRowObjects = selectedRowObjects.map(item => ({
      ...item,
      ...columnsUpdate
    }))

    return (
      <Modal
        title={<FormattedMessage id='bulk change button' />}
        visible={assignToModalVisible}
        width='93%'
        style={{ maxWidth: 1200, maxHeight: '50vh' }}
        onCancel={finishSubmitting}
        destroyOnClose
        footer={[
          <Button key='back' onClick={finishSubmitting}>
            <FormattedMessage id='cancel' />
          </Button>,
          <Button
            key='submit'
            type='primary'
            onClick={this.handleSubmitAssignment}
          >
            <FormattedMessage id='submit' />
          </Button>
        ]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingTop: 10
          }}
        >
          <Table
            columns={columns}
            rowKey={record => record._id}
            size='small'
            style={{ width: '100%', marginBottom: 16 }}
            dataSource={updatedRowObjects}
            pagination={false}
          />

          {fieldsArray.map((field, index) => (
            <div
              key={index}
              style={{
                marginTop: 16,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center'
              }}
            >
              <p
                style={{
                  marginBottom: 0
                }}
              >
                {intl.formatMessage({
                  id: field.label
                })}
              </p>
              {field.fieldType === 'select' && (
                <Select
                  showSearch
                  allowClear
                  placeholder={intl.formatMessage({
                    id: field.placeholder
                  })}
                  style={{ width: '50%' }}
                  optionFilterProp='children'
                  notFoundContent={
                    formLoading ? (
                      <div className='objects-spinner-container'>
                        <Spin size='small' />
                      </div>
                    ) : null
                  }
                  onFocus={() =>
                    field.key !== 'status'
                      ? this.fetchData('', field.key + 'Array')
                      : null
                  }
                  onSearch={value =>
                    field.key !== 'status'
                      ? this.fetchData(value, field.key + 'Array')
                      : null
                  }
                  onChange={value => {
                    this.handlePick(
                      field.key,
                      field.key !== 'status'
                        ? (this.state[field.key + 'Array'] || []).find(
                            item => item._id === value
                          )
                        : value
                    )
                  }}
                >
                  {(field.key === 'status'
                    ? this.state.statusArray
                    : this.state[field.key + 'Array'] || []
                  ).map(element => (
                    <Option
                      key={field.key !== 'status' ? element._id : element}
                      value={field.key !== 'status' ? element._id : element}
                    >
                      {element.contact_type && element.contact_type === 'user'
                        ? `${element.firstName || ''} ${element.lastName || ''}`
                        : element.company ||
                          element.name ||
                          intl.formatMessage({ id: element })}
                    </Option>
                  ))}
                </Select>
              )}
              {field.fieldType === 'input' && (
                <Input
                  style={{ width: '50%' }}
                  placeholder={intl.formatMessage({
                    id: field.placeholder
                  })}
                  onChange={event =>
                    this.handlePick(field.key, event.target.value)
                  }
                />
              )}
            </div>
          ))}
        </div>
      </Modal>
    )
  }
}

ModalTable.propTypes = {
  intl: PropTypes.shape(intl),
  selectedRowObjects: PropTypes.array.isRequired,
  finishSubmitting: PropTypes.func.isRequired,
  assignToModalVisible: PropTypes.bool
}

export default injectIntl(connect()(ModalTable))
