import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Result } from 'antd'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import { useIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import FormResetPassword from './Form/FormResetPassword'

const ResetPasswordPage = props => {
  const intl = useIntl()

  const [token, setToken] = useState('')
  const [showResult, setShowResult] = useState(null)

  useEffect(() => {
    const { token } = queryString.parse(props.location.search)
    setToken(token)
  }, [token, props.location.search])

  const handleFinishAction = result => {
    localStorage.clear()
    setShowResult(result)
  }

  return (
    <>
      <Helmet>
        <title>Neues Kennwort setzen</title>
      </Helmet>

      {!showResult && (
        <h2>
          <FormattedMessage id='set your new password' />
        </h2>
      )}
      {!showResult && (
        <FormResetPassword token={token} finishAction={handleFinishAction} />
      )}

      {showResult === 'success' && (
        <Result
          status='success'
          title='Kennwort geändert.'
          subTitle='Sie können sich jetzt mit dem neuen Kennwort einloggen.'
          extra={[
            <Link to='/login' key='login'>
              <Button>Login</Button>
            </Link>
          ]}
        />
      )}

      {showResult === 'error' && (
        <Result
          status='error'
          title={intl.formatMessage({ id: 'something went wrong.' })}
          subTitle={intl.formatMessage({
            id: 'something went wrong. please contact DDC Schweiz AG'
          })}
        />
      )}
    </>
  )
}

ResetPasswordPage.propTypes = {
  location: PropTypes.string.isRequired
}

export default ResetPasswordPage
