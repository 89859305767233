import React from 'react'
import { injectIntl } from 'react-intl'
import { List, Select, Form, Input, Button } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const Option = Select.Option

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
  className: 'formItem'
}

class AddProduct extends React.Component {
  state = {
    searchValue: '',
    selectedCategory: ''
  }

  updateSearch = value => {
    this.setState({
      searchValue: value
    })
  }

  updateCategory = value => {
    this.setState({
      selectedCategory: value
    })
  }

  renderSelect = array => {
    const { intl } = this.props

    let key = 0
    const selectItems = array.map(category => {
      key += 1
      return (
        <Option
          key={`${category.name}-${key}`}
          id={category._id}
          value={category.name}
        >
          {category.name}
        </Option>
      )
    })
    return (
      <Select
        defaultValue={intl.formatMessage({
          id: 'choose category'
        })}
        style={{ width: 250 }}
        onChange={value => this.updateCategory(value)}
      >
        {selectItems}
      </Select>
    )
  }

  render () {
    const { products, intl, categories, addProductSwitcher } = this.props
    const { searchValue, selectedCategory } = this.state
    const filteredProducts = products
      .filter(product => {
        return product.category && product.category.name === selectedCategory
      })
      .filter(product => {
        return product.name.indexOf(searchValue) !== -1
      })

    return (
      <div className='recordings-add-product-wrap'>
        <Button
          onClick={addProductSwitcher}
          type='primary'
          className='recordings-products-tab-back'
        >
          {intl.formatMessage({ id: 'back' })}
        </Button>
        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'category'
          })}
        >
          {this.renderSelect(categories)}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'search'
          })}
        >
          <Input
            value={searchValue}
            onChange={event => this.updateSearch(event.target.value)}
          />
        </Form.Item>
        <List
          className='recordings-products-list'
          header={<div>{intl.formatMessage({ id: 'products' })}</div>}
          bordered
          dataSource={filteredProducts}
          renderItem={item => (
            <List.Item
              className='recordings-products-list-item'
              onClick={() => this.props.addProductToSection(item)}
            >
              {item.name}
            </List.Item>
          )}
        />
      </div>
    )
  }
}

AddProduct.propTypes = {
  intl: PropTypes.object.isRequired,
  recording: PropTypes.object,
  products: PropTypes.array,
  categories: PropTypes.array.isRequired,
  addProductSwitcher: PropTypes.func.isRequired,
  addProductToSection: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    recording: state.recordings.pickedRecording,
    products: state.products.productsList,
    categories: state.category.categoryList
  }
}

export default injectIntl(connect(mapStateToProps)(AddProduct))
