import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { FormattedMessage } from 'react-intl'

import FormLogin from './Form/FormLogin'

const EntryText = styled.div`
  padding-bottom: 2rem;
`

function Login () {
  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <EntryText>
        <FormattedMessage id='log in to your ddSuite instance' />
      </EntryText>
      <FormLogin />
      <Link to='/forgot-password'>
        <FormattedMessage id='forgot password?' />
      </Link>
    </>
  )
}

export default Login
