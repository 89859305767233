import axios from '../../helpers/axios'
import config from 'config'
import HttpClient from './index'

class ObjectAPI extends HttpClient {
  async objectsList () {
    const response = await axios.get('/objectType')
    return response
  }

  async fetchObject (id) {
    return axios.get(`/objectType/${id}`)
  }

  async list (params = {}) {
    return axios.get('/objectType', params)
  }

  async listV2 (params = {}, incomeProjectId) {
    if (incomeProjectId) {
      params.project = incomeProjectId
    }

    return axios.get(config.server.host + '/1.1/objectType', params)
  }

  async save (objectType) {
    const payload = {}
    if (objectType.name) {
      payload.name = objectType.name
    }

    if (objectType.fields && objectType.fields.length) {
      payload.fields = objectType.fields.map(item => item._id)
    }

    if (objectType._id) {
      return axios.put(`/objectType/${objectType._id}`, payload)
    } else {
      return axios.post('/objectType', payload)
    }
  }

  async delete (id) {
    return axios.delete(`/objectType/${id}`)
  }
}

export default ObjectAPI
