import React from 'react'
import { Button, Icon, Input } from 'antd'
import { FormattedMessage } from 'react-intl'
/* eslint react/prop-types: 0 */
const searchColumn = ({ input, intl, value }) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={input}
        placeholder={intl.formatMessage({ id: 'search' })}
        onChange={({ target: { value } }) =>
          setSelectedKeys(value ? [value] : [])
        }
        value={selectedKeys[0]}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type='primary'
        onClick={() => confirm()}
        icon='search'
        disabled={!selectedKeys[0]}
        size='small'
        style={{ width: 90, marginRight: 8 }}
      />
      <Button onClick={() => clearFilters()} size='small' style={{ width: 90 }}>
        <FormattedMessage id='reset' />
      </Button>
    </div>
  ),
  filterIcon: filtered => (
    <Icon
      type='search'
      style={{
        color: filtered && value ? '#1890ff' : undefined
      }}
    />
  ),
  onFilterDropdownVisibleChange: async visible => {
    if (visible) {
      input.current.select()
    }
  },
  defaultFilteredValue: value
})

export default searchColumn
