import React from 'react'
import PropTypes from 'prop-types'
import ListItem from './ListItem'

import '../../styles.scss'

export class ListComponent extends React.Component {
  onDescriptionUpdate = file => {
    const { handleUpdate, files } = this.props
    handleUpdate(
      files.map(item => {
        if (item._id === file._id) {
          return { ...file, updated: true }
        } else {
          return item
        }
      })
    )
  }

  render () {
    const { handleDelete, files } = this.props

    return (
      <div className='upload-list-cover'>
        {files &&
          files.map(item => (
            <ListItem
              key={item._id}
              fileData={item}
              handleUpdate={this.onDescriptionUpdate}
              handleDelete={handleDelete}
            />
          ))}
      </div>
    )
  }
}

ListComponent.propTypes = {
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
  files: PropTypes.array
}

export default ListComponent
