import React from 'react'
import PropTypes from 'prop-types'
import Zoom from 'react-medium-image-zoom'
import { FormattedMessage, useIntl } from 'react-intl'

import { Form, Input } from 'antd'

import config from 'config'
import { connect } from 'react-redux'
import { updatePickedRecording } from '../../../../ducks/recordings'

const Photos = ({ dispatch, recording: { images }, readOnly }) => {
  const intl = useIntl()
  const updateImage = (incomeImage, value) => {
    const outputImages = images.map(image => {
      if (image._id === incomeImage._id) {
        image.comment = value
      }
      return image
    })
    dispatch(updatePickedRecording({ images: outputImages }))
  }
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
    className: 'formItem'
  }

  return (
    <>
      {(images || []).map(image => (
        <React.Fragment key={image._id}>
          <h3>
            <FormattedMessage id='photo' />
          </h3>

          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'photo'
            })}
          >
            <Zoom zoomMargin={40}>
              <img
                src={config.server.url + image.imageUrl}
                className='img'
                style={{ width: '100%' }}
              />
            </Zoom>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={intl.formatMessage({
              id: 'photo comment'
            })}
          >
            <Input.TextArea
              disabled={readOnly}
              value={image.comment}
              autoSize={{ minRows: 2, maxRows: 6 }}
              onChange={event => updateImage(image, event.target.value)}
            />
          </Form.Item>
        </React.Fragment>
      ))}
    </>
  )
}

Photos.propTypes = {
  recording: PropTypes.object,
  readOnly: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  recording: state.recordings.pickedRecording,
  projects: state.project.searchedProjectsList,
  readOnly: state.recordings.pickedReadOnly
})

export default connect(mapStateToProps, null)(Photos)
