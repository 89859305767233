import PasswordValidator from 'password-validator'

const passwordSchema = new PasswordValidator()

passwordSchema
  .is()
  .min(10) // Minimum length 8
  .is()
  .max(100) // Maximum length 100
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits() // Must have digits
  .has()
  .not()
  .spaces() // Should not have spaces

export default passwordSchema
