import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import InformationsSection from '../../Sections/Informations'
import IdentificationSection from '../../Sections/Identification'
import GeneralSection from '../../Sections/General'
import StatusAndArchivedBlock from './components/StatusAndArchivedBlock'
import AcceptanceContractSectionField from './components/AcceptanceContractSectionField'

import '../../styles.scss'

class InformationTab extends React.Component {
  render () {
    const { recording, readOnly, auth } = this.props

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <div className='recordingForm'>
        <InformationsSection
          formItemLayout={formItemLayout}
          readOnly={readOnly}
        />
        <IdentificationSection
          formItemLayout={formItemLayout}
          recording={recording}
          readOnly={readOnly}
        />
        <GeneralSection formItemLayout={formItemLayout} recording={recording} />

        {!recording.acceptanceId &&
          ['admin', 'superadmin'].includes(auth.authUser.scope[0]) && (
            <StatusAndArchivedBlock formItemLayout={formItemLayout} />
          )}

        {recording.acceptanceId && (
          <AcceptanceContractSectionField formItemLayout={formItemLayout} />
        )}
      </div>
    )
  }
}

InformationTab.propTypes = {
  recording: PropTypes.object,
  auth: PropTypes.object,
  readOnly: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    recording: state.recordings.pickedRecording
  }
}

export default injectIntl(connect(mapStateToProps)(InformationTab))
