import axios from '../../helpers/axios'
import config from 'config'
import HttpClient from './index'

class ContractSectionAPI extends HttpClient {
  list (params = {}) {
    return axios.get('/contract-section', params)
  }

  listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/contract-section', params)
  }

  save (contractSection) {
    const params = {
      name: contractSection.name,
      disabled: contractSection.disabled,
      project: contractSection.project || null
    }

    if (contractSection._id) {
      return axios.put(`/contract-section/${contractSection._id}`, params)
    } else {
      return axios.post('/contract-section', params)
    }
  }

  remove (contractSection) {
    return axios.delete(`/contract-section/${contractSection._id}`)
  }
}

export default ContractSectionAPI
