import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Form, Select } from 'antd'
import '../../../styles.scss'
import PropTypes from 'prop-types'
import { updateWorkorderField } from '../../../../../../../../../ducks/workorder'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

const { Option } = Select

const statusOptions = [
  { value: 0, label: 'none' },
  { value: 1, label: 'open' },
  { value: 2, label: 'in progress' },
  { value: 3, label: 'on hold' },
  { value: 4, label: 'complete' }
]

class StatusField extends React.Component {
  render () {
    const { workorder, intl, dispatch } = this.props

    return (
      <>
        <Form.Item {...formItemLayout} label='Status'>
          <Select
            defaultValue={
              statusOptions.find(item => item.value === workorder.income.status)
                .label || 'none'
            }
            style={{ width: 120 }}
            onChange={value =>
              dispatch(updateWorkorderField({ status: value }))
            }
            disabled={workorder.income.archived}
          >
            {statusOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {intl.formatMessage({
                  id: item.label
                })}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </>
    )
  }
}

StatusField.propTypes = {
  workorder: PropTypes.object,
  users: PropTypes.array,
  dispatch: PropTypes.func,
  intl: PropTypes.object
}

const mapStateToProps = state => {
  return {
    workorder: state.workorder.pickedWorkorder,
    users: state.users.searchedUsersList
  }
}
export default injectIntl(connect(mapStateToProps)(StatusField))
