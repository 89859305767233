import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Form, Icon, Select, Spin, Tooltip } from 'antd'
import { injectIntl, FormattedMessage, useIntl } from 'react-intl'
import debounce from 'lodash/debounce'
import { fetchFieldList } from '../../../../ducks/fields'
import { useDispatch, useSelector } from 'react-redux'
import { fetchContacts } from '../../../../ducks/contacts'

const MainX = props => {
  const { getFieldDecorator } = props.form
  const fields = useSelector(state => state.fields.searchedFieldsList)
  const contacts = useSelector(state => state.contacts.searchedContacts)

  const { data, updateMainX } = props
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
    className: 'formItem'
  }

  const fetchFormFieldsOnSearch = (value = '') => {
    setLoading(true)
    dispatch(fetchFieldList({ 'label[]': value, limit: 100 }, true)).then(
      () => {
        setLoading(false)
      }
    )
  }
  const fetchContactsOnSearch = async searchQueue => {
    dispatch(
      fetchContacts(
        {
          'company[]': searchQueue,
          limit: 1000,
          sortField: 'company',
          sortOrder: 'ascend'
        },
        true
      )
    )
  }

  const handlePick = (value, e) => {
    const outputArray = e.map((item, index) => {
      if (item.props.data) {
        return item.props.data
      } else {
        let field = fields.find(field => field.label === value[index])
        if (!field) {
          field = (data.fields || []).find(
            field => field.label === value[index]
          )
        }
        return field
      }
    })
    updateMainX('fields', outputArray)
  }

  const availableFields = (fields || []).filter(item => {
    return !(data.fields || []).some(field => field.key === item.key)
  })

  console.log('data')
  console.log(data)
  const handleDataString = data => {
    console.log('data')
    console.log(data)
    let contactEmailString = ''
    if (data.contact_type === 'company') {
      contactEmailString += `${data.company} `
    } else {
      contactEmailString += `${(data.firstName || '') +
        ((data.lastName && ' ' + data.lastName) || '')}`
    }

    if (data.email) {
      contactEmailString += data.email ? ` (${data.email})` : ''
    }
    return contactEmailString
  }

  return (
    <>
      <Form.Item
        {...formItemLayout}
        style={{ display: 'flex', justifyContent: 'center' }}
        label={
          <span>
            <FormattedMessage id='supportEmail' />
          </span>
        }
        required={false}
      >
        <Select
          showSearch
          style={{ display: 'block', flex: 1 }}
          value={handleDataString(data.contactPerson || {})}
          onSelect={(data, e) =>
            updateMainX('contactPerson', (e && e.props.data) || undefined)
          }
          onFocus={() => fetchContactsOnSearch('')}
          notFoundContent={
            loading ? (
              <div className='workorder-spinner-container'>
                <Spin size='small' />
              </div>
            ) : null
          }
          filterOption={(string, { props: { data } }) =>
            (data.company &&
              data.company.toLowerCase().indexOf(string.toLowerCase()) > -1) ||
            (data.firstName &&
              data.firstName.toLowerCase().indexOf(string.toLowerCase()) >
                -1) ||
            (data.lastName &&
              data.lastName.toLowerCase().indexOf(string.toLowerCase()) > -1)
          }
        >
          {(contacts || []).map(item => (
            <Select.Option key={item._id} data={item} value={item._id}>
              {handleDataString(item || {})}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={{ display: 'flex', justifyContent: 'center' }}
        label={
          <span>
            <Tooltip
              placement='top'
              title={<FormattedMessage id='will be attached' />}
            >
              <Icon type='info-circle' />
            </Tooltip>
            <FormattedMessage id='custom fields for tasks' />
          </span>
        }
      >
        {getFieldDecorator('fields', {
          initialValue:
            (data.fields &&
              data.fields.length &&
              data.fields.map(item => item.label)) ||
            undefined
        })(
          <Select
            mode='multiple'
            showSearch
            allowClear
            style={{ width: '100%' }}
            notFoundContent={
              loading && (
                <div className='project-spinner-container'>
                  <Spin size='small' />
                </div>
              )
            }
            placeholder={intl.formatMessage({ id: 'select field' })}
            optionFilterProp='children'
            onFocus={fetchFormFieldsOnSearch}
            onSearch={debounce(fetchFormFieldsOnSearch, 300)}
            onChange={(data, event) => handlePick(data, event)}
          >
            {(availableFields || []).map(field => {
              return (
                <Select.Option
                  key={field._id}
                  data={{ ...field }}
                  value={field.label}
                >
                  {field.label}
                </Select.Option>
              )
            })}
          </Select>
        )}
      </Form.Item>
    </>
  )
}

MainX.propTypes = {
  numeration: PropTypes.object.isRequired,
  formItemLayout: PropTypes.object,
  updateNumeration: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  updateMainX: PropTypes.func.isRequired
}

export default injectIntl(MainX)
