import React from 'react'
import PropTypes from 'prop-types'
import Dropzone from './DropZone'
import FileList from './List'

export class FileUploader extends React.Component {
  render () {
    const { handleUpload, handleUpdate, handleDelete, files } = this.props
    return (
      <>
        <Dropzone handleUpload={handleUpload} />
        <FileList
          files={files}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
        />
      </>
    )
  }
}

FileUploader.propTypes = {
  handleUpload: PropTypes.func,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
  files: PropTypes.array
}

export default FileUploader
