import React from 'react'

import moment from 'moment'
import { FormattedMessage, injectIntl } from 'react-intl'

import { connect } from 'react-redux'
import { Form, Input, DatePicker, Select, Spin } from 'antd'
import PropTypes from 'prop-types'

import { fetchProjectList } from '../../../ducks/project'
import { updatePickedRecording } from '../../../ducks/recordings'
import debounce from 'lodash/debounce'

export class InformationSection extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
    this.handleInputThrottled = debounce(this.fetchProjectsOnSearch, 300)
  }

  handleFieldChange = (key, value) => {
    const { dispatch } = this.props
    dispatch(updatePickedRecording({ [key]: value }))
  }

  fetchProjectsOnSearch = searchQueue => {
    const { dispatch } = this.props
    this.setState({
      loading: true
    })
    dispatch(fetchProjectList({ 'projectName[]': searchQueue }, true)).then(
      response => {
        this.setState({
          loading: false
        })
      }
    )
  }

  render () {
    const { readOnly, formItemLayout, projects, recording, intl } = this.props
    const { loading } = this.state
    return (
      <>
        <h3>
          <FormattedMessage id='informations' />
        </h3>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'title'
          })}
        >
          <Input
            disabled={readOnly}
            value={recording.name}
            maxLength={40}
            onChange={event =>
              this.handleFieldChange('name', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'project'
          })}
        >
          <Select
            showSearch
            allowClear
            loading={loading}
            disabled={readOnly}
            style={{ width: '100%' }}
            optionFilterProp='children'
            notFoundContent={
              loading ? (
                <div className='objects-spinner-container'>
                  <Spin size='small' />
                </div>
              ) : null
            }
            onFocus={() => this.fetchProjectsOnSearch('')}
            onSearch={value => this.handleInputThrottled(value)}
            onChange={(project, e) =>
              this.handleFieldChange(
                'project',
                (e && e.props && e.props.data) || ''
              )
            }
            defaultValue={
              recording.project ? recording.project.projectName : ''
            }
          >
            {projects.map(project => (
              <Select.Option
                key={project._id}
                data={project}
                value={project._id}
              >
                {project.projectName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'date'
          })}
        >
          <DatePicker
            disabled={readOnly}
            allowClear={false}
            showTime
            value={moment(recording.date)}
            format='DD.MM.YYYY HH:mm'
            onChange={date => this.handleFieldChange('date', date.format())}
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'created by'
          })}
        >
          <span className='ant-form-text'>
            {!!recording.createdBy && recording.createdBy.fullName}
          </span>
        </Form.Item>
      </>
    )
  }
}

InformationSection.propTypes = {
  formItemLayout: PropTypes.object,
  readOnly: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  recording: PropTypes.object,
  projects: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  recording: state.recordings.pickedRecording,
  projects: state.project.searchedProjectsList,
  readOnly: state.recordings.pickedReadOnly
})

export default injectIntl(connect(mapStateToProps, null)(InformationSection))
