import axios from '../../helpers/axios'
import HttpClient from './index'
import config from 'config'

class FormAPI extends HttpClient {
  async list (params = {}) {
    return axios.get('/form', params)
  }

  async get (formID) {
    return axios.get(`/form/${formID}`)
  }

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/form', params)
  }

  // async save (form) {
  //   const params = {
  //     name: form.name,
  //     fields: (form.fields || []).map(item => ({
  //       id: item.id,
  //       element: item.element,
  //       label: item.label,
  //       text: item.text,
  //       options: item.options
  //     }))
  //   }
  //
  //   let savedForm
  //   if (form._id) {
  //     savedForm = await axios.put(
  //       `/form/${form._id}`,
  //       params
  //     )
  //   } else {
  //     // const projectID = store.getState().projectReducer.project
  //     // if (projectID) {
  //     //   params.project = projectID._id
  //     // }
  //
  //     savedForm = await axios.post(
  //       '/form',
  //       params
  //     )
  //   }
  //
  //   return savedForm
  // }

  async delete (form) {
    return axios.delete(`/form/${form._id}`)
  }
}
export default FormAPI
