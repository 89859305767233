import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Input } from 'antd'
import Images from './Images'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

class RequestForm extends React.Component {
  state = {}

  render () {
    const { props } = this

    return (
      <>
        <Form className='requestForm'>
          <Form.Item {...formItemLayout} label='Name'>
            <Input
              disabled
              onChange={e => props.updateRequest('name', e.target.value)}
              value={props.request.name}
            />
          </Form.Item>
          <Form.Item {...formItemLayout} label={this.props.objectName}>
            <Input
              disabled
              value={props.request.objectId && props.request.objectId.name}
            />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            label={props.intl.formatMessage({ id: 'description' })}
          >
            <Input.TextArea
              disabled
              onChange={e => props.updateRequest('description', e.target.value)}
              value={props.request.description}
            />
          </Form.Item>
          <Images formItemLayout={formItemLayout} request={props.request} />
        </Form>
      </>
    )
  }
}

RequestForm.propTypes = {
  updateRequest: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  request: PropTypes.object,
  objectName: PropTypes.string
}

export default injectIntl(connect()(RequestForm))
