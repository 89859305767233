import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import FormCategory from './FormCategory'
import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import categoriesService from '../../../services/categories'
import PopconfirmDelete from '../../../components/Global/PopconfirmDelete'

const CategoryManagement = ({ visible, category, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createCategory] = useApi(categoriesService.addCategory)
  const [updating, updateCategory] = useApi(categoriesService.updateCategory)
  const [deleting, deleteCategory] = useApi(categoriesService.deleteCategory)

  const handleSubmit = () => {
    form.current.validateFields(async (errors, { _id, ...record }) => {
      if (errors) return

      onSubmit(
        _id ? await updateCategory(_id, record) : await createCategory(record)
      )
    })
  }

  const handleDelete = async () => onSubmit(await deleteCategory(category._id))

  const loading = creating || updating || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (scope === 'superadmin' && category && !category.isVerified) {
    drawerButtons.unshift(
      <PopconfirmDelete key='delete' onConfirmAction={handleDelete} />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${category ? 'edit' : 'add'} category`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormCategory ref={form} category={category} />
    </Drawer>
  )
}

CategoryManagement.propTypes = {
  category: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

CategoryManagement.defaultProps = {
  visible: false
}

export default CategoryManagement
