import axios from '../../helpers/axios'
import HttpClient from './index'
import config from 'config'

class AcceptanceAPI extends HttpClient {
  async list (params = {}) {
    return axios.get('/acceptance', params)
  }

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/acceptance', params)
  }

  async save (id, params) {
    return axios.put(`/acceptance/${id}`, params)
  }

  async delete (id) {
    return axios.delete(`/acceptance/${id}`)
  }
}
export default AcceptanceAPI
