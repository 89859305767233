import axios from '../../helpers/axios'
import config from 'config'
import HttpClient from './index'

const axiosCustom = require('axios')

class UserAPI extends HttpClient {
  list () {
    return axios.get('/user')
  }

  getUser () {
    return this.get('/me')
  }

  async searchUsers (limit, offset, name) {
    const token = localStorage.getItem('authToken')
    const response = await axiosCustom.get(
      `${config.server.host}/1.1/user?limit=${limit}&offset=${offset}&name[]=${name}`,
      {
        headers: {
          authorization: token
        }
      }
    )
    return response.data
  }

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/user', params)
  }

  async save (user) {
    const params = {
      firstName: user.firstName,
      lastName: user.lastName,
      function: user.function,
      employeeNumber: user.employeeNumber,
      scope: user.scope,
      activated: user.activated
    }

    if (user.password) {
      params.password = user.password
    }

    let savedUser
    if (user._id) {
      savedUser = await axios.put(`/user/${user._id}`, params)
    } else {
      params.email = user.email

      savedUser = await axios.post('/user', params)
    }

    return savedUser
  }

  async remove (user) {
    return axios.delete(`/user/${user._id}`)
  }
}

export default UserAPI
