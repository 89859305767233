import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Form, Collapse, Spin, Tag } from 'antd'

import queryString from 'query-string'
import { withRouter } from 'react-router-dom'

import { setPickedProject } from '../../../../../ducks/project'
import {
  fetchWorkorder,
  setWorkorderData
} from '../../../../../ducks/workorder'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import CollapseHeader from './CollapseHeader'
import { Scrollbar } from 'react-scrollbars-custom'

import CreateTaskDropDown from '../CreateTaskDropDown'
import { setPickedTask } from '../../../../../ducks/tasks'

const { Panel } = Collapse

const defaultParams = { limit: 100 }

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  background: 'white',
  borderWidth: 2,
  borderColor: isDragging ? '#005591' : 'rgb(217, 217, 217)',
  borderStyle: 'solid',
  ...draggableStyle
})

const getListStyle = isDraggingOver => {
  return {
    borderColor: isDraggingOver ? '#005591' : 'transparent',
    borderStyle: 'solid',
    borderWidth: 2
  }
}

export class WorkordersList extends React.Component {
  state = {
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    params: {}
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search

    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, project } = this.props
    if (project) {
      paramsObject.project = project._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }
    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchWorkorders()
  }

  componentDidUpdate (prevProps) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      projects,
      project,
      dispatch,
      showArchived
    } = this.props
    const params = { ...this.getParams() }

    if (params.project && !prevProps.projects.length && projects.length) {
      dispatch(
        setPickedProject(projects.find(item => item._id === params.project))
      )
    }

    if (project !== prevProps.project) {
      if (project && project._id) {
        this.setParams({ ...params, project: project._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }

    if (prevSearch !== nextSearch || this.props.project !== prevProps.project) {
      this.fetchWorkorders()
    }

    if (showArchived !== prevProps.showArchived) {
      this.fetchWorkorders()
    }
  }

  fetchWorkorders = async () => {
    const { dispatch } = this.props
    const params = this.getParams()
    this.setState({
      loading: true
    })

    dispatch(
      fetchWorkorder({
        ...defaultParams,
        ...params
      })
    ).then(() => {
      this.setState({
        loading: false
      })
    })
  }

  handleTaskCreation = (response, workorder) => {
    const { dispatch, workordersData, workorders } = this.props
    const docs = [...workorders].map(item => {
      if (item._id === workorder._id) {
        return response
      }
      return item
    })
    dispatch(
      setWorkorderData({
        ...workordersData,
        docs
      })
    )
  }

  handleTaskPick = task => {
    const { dispatch } = this.props
    dispatch(setPickedTask(task))
  }

  render () {
    const { workorders, pickedTask, intl } = this.props
    const { loading } = this.state

    return (
      <div className={`workorder-block-cover ${loading ? 'spinning' : ''}`}>
        <div className='spin-cover'>
          <Spin />
        </div>

        <Scrollbar>
          {(workorders || []).map(record => (
            <div key={record._id} className='workorder-item-cover'>
              <Collapse>
                <Panel header={<CollapseHeader workorder={record} />} key='1'>
                  <Droppable droppableId={record._id}>
                    {(provided, snapshot) => (
                      <div
                        className='workorder-list'
                        style={getListStyle(snapshot.isDraggingOver)}
                        ref={provided.innerRef}
                      >
                        {record.tasks.map((task, index) => (
                          <Draggable
                            key={(task && task._id) || ''}
                            draggableId={(task && task._id) || ''}
                            index={index}
                            isDragDisabled={false}
                          >
                            {(provided, snapshot) =>
                              task ? (
                                <div
                                  className={`workorder-list-item ${
                                    pickedTask._id === task._id
                                      ? 'task-active'
                                      : ''
                                  }`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    ...getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    ),
                                    borderBottomWidth:
                                      index + 1 !== record.tasks.length &&
                                      !snapshot.isDragging
                                        ? '0'
                                        : '2px'
                                  }}
                                  onClick={() => this.handleTaskPick(task)}
                                >
                                  {task.name && (
                                    <span className='task-text'>
                                      {task.name}
                                    </span>
                                  )}

                                  {task.objectId && (
                                    <span className='task-text text-center'>
                                      {task.objectId.name}
                                    </span>
                                  )}
                                  {task.status && (
                                    <span className='task-text text-center'>
                                      <Tag color='blue'>
                                        {intl.formatMessage({
                                          id: task.status
                                        })}
                                      </Tag>
                                    </span>
                                  )}
                                  {task.docNumber && (
                                    <span className='task-text text-right'>
                                      {task.docNumber}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                ''
                              )
                            }
                          </Draggable>
                        ))}
                        <CreateTaskDropDown
                          horizontal
                          handleTaskCreation={task =>
                            this.handleTaskCreation(task, record)
                          }
                          workorder={record}
                        />
                      </div>
                    )}
                  </Droppable>
                </Panel>
              </Collapse>
            </div>
          ))}
        </Scrollbar>
      </div>
    )
  }
}

WorkordersList.propTypes = {
  auth: PropTypes.object,
  project: PropTypes.object,
  path: PropTypes.object,
  workorders: PropTypes.array,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  workordersData: PropTypes.object.isRequired,
  pickedTask: PropTypes.object.isRequired,
  showArchived: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    projects: state.project.projectList,
    project: state.project.pickedProject,
    pickedTask: state.tasks.pickedTask,
    workorders: state.workorder.workorderData.docs,
    workordersData: state.workorder.workorderData,
    showArchived: state.workorder.showArchived
  }
}

export default withRouter(
  injectIntl(
    Form.create({ name: 'work_orders_form' })(
      connect(mapStateToProps)(WorkordersList)
    )
  )
)
