import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const NotFound = () => (
  <>
    <Helmet>
      <title>Not found</title>
    </Helmet>
    <div
      style={{
        textAlign: 'center',
        marginTop: 50
      }}
    >
      <h1>Page Not Found</h1>

      <Link className='ant-btn ant-btn-primary' to='/'>
        Home Page
      </Link>
    </div>
  </>
)

export default NotFound
