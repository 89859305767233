import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { StatsAPI } from '../services'

export const REDUCER = 'stats'
const NS = `@@${REDUCER}/`

export const setStats = createAction(`${NS}SET_STATS`)

const StatsApi = new StatsAPI()

export const initialState = {
  data: {}
}

export const fetchStats = () => (dispatch, getState) => {
  return StatsApi.get()
    .then(response => {
      dispatch(setStats(response))
      return true
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return false
    })
}

export default createReducer(
  {
    [setStats]: (state, data) => ({ ...state, data })
  },
  initialState
)
