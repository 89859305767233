import React from 'react'
import PropTypes from 'prop-types'
import ListItem from './ListItem'

import '../styles.scss'

export class ListComponent extends React.Component {
  render () {
    const { handleDelete, files, disabled } = this.props

    return (
      <div className='file-upload-list-cover'>
        {files &&
          files.map(item => (
            <ListItem
              disabled={disabled}
              key={item._id}
              fileData={item}
              handleDelete={handleDelete}
            />
          ))}
      </div>
    )
  }
}

ListComponent.propTypes = {
  handleDelete: PropTypes.func,
  files: PropTypes.array,
  disabled: PropTypes.bool
}

export default ListComponent
