import React from 'react'
import { injectIntl } from 'react-intl'
import { Form } from 'antd'
import isEqual from 'lodash/isEqual'

import CommonFields from './CommonFields'
import SpecificFields from './SpecificFields'
import PropTypes from 'prop-types'

import './styles.scss'

class DefectForm extends React.Component {
  componentDidUpdate (prevProps, prevState, snapshot) {
    const { data, form } = this.props

    if (!isEqual(data, prevProps.data)) {
      form.resetFields()
    }
  }

  render () {
    const { updateStateDefect, data, errors } = this.props
    return (
      <Form className='form-cover'>
        <CommonFields
          updateStateDefect={updateStateDefect}
          data={data}
          errors={errors}
        />

        <SpecificFields
          updateStateDefect={updateStateDefect}
          data={data}
          errors={errors}
        />
      </Form>
    )
  }
}

DefectForm.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object,
  errors: PropTypes.object,
  updateStateDefect: PropTypes.func.isRequired
}

export default Form.create()(injectIntl(DefectForm))
