import React from 'react'
import PropTypes from 'prop-types'
import { Drawer as DrawerComponent } from 'antd'
import styled from 'styled-components'

const Actions = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  padding: 10px 16px;
  text-align: right;
  z-index: 999;

  button {
    margin-left: 8px;
    &:first-child {
      margin-left: 0;
    }
  }
`

const Drawer = props => {
  const {
    title,
    visible,
    children,
    footerButtons,
    onClose,
    coverStyle,
    closable
  } = props

  return (
    <DrawerComponent
      {...props}
      title={title}
      width={700}
      destroyOnClose
      closable
      maskClosable={!!closable}
      keyboard={false}
      onClose={onClose}
      visible={visible}
      style={coverStyle}
    >
      {children}
      <Actions>{footerButtons}</Actions>
    </DrawerComponent>
  )
}

Drawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  footerButtons: PropTypes.array,
  coverStyle: PropTypes.object,
  closable: PropTypes.bool
}

Drawer.defaultProps = {
  bodyStyle: { paddingBottom: 60 }
}

export default Drawer
