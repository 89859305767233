import React from 'react'
import PropTypes from 'prop-types'

import { Select, AutoComplete, Spin } from 'antd'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { fetchObjectsList } from '../../../../../../ducks/object'

const { Option } = Select

class TableObjectSearch extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
    this.handleInputThrottled = debounce(this.fetchObjectsOnSearch, 300)
  }

  fetchObjectsOnSearch = searchQueue => {
    const { dispatch } = this.props
    this.setState({
      loading: true
    })
    dispatch(fetchObjectsList({ 'name[]': searchQueue }, true)).then(() => {
      this.setState({
        loading: false
      })
    })
  }

  render () {
    const { value, onPick } = this.props
    const { loading } = this.state

    const { objects } = this.props
    return (
      <AutoComplete
        allowClear
        className='certain-category-search'
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 188 }}
        style={{ display: 'block', marginBottom: 8 }}
        placeholder='Select Object'
        initialValue='Select Object'
        optionFilterProp='children'
        defaultValue={value}
        notFoundContent={
          loading ? (
            <div className='recordings-spinner-container'>
              <Spin size='small' />
            </div>
          ) : null
        }
        onFocus={() => this.fetchObjectsOnSearch('')}
        onSearch={this.handleInputThrottled}
        onSelect={(value, e) => onPick(e.props.data || '')}
      >
        {objects.map(object => (
          <Option key={object._id} data={object} value={object.name || ''}>
            {object.name}
          </Option>
        ))}
      </AutoComplete>
    )
  }
}

TableObjectSearch.propTypes = {
  dispatch: PropTypes.func.isRequired,
  objects: PropTypes.array.isRequired,
  value: PropTypes.string,
  onPick: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  projects: state.project.searchedProjectsList,
  objects: state.objects.searchedObjectsList
})

export default connect(mapStateToProps, null)(TableObjectSearch)
