import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Form, Input } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'

const Numeration = props => {
  const { numeration, formItemLayout, updateNumeration } = props
  const { getFieldDecorator } = props.form

  return (
    <>
      <Form.Item
        {...formItemLayout}
        style={{ display: 'flex', justifyContent: 'center' }}
        label={
          <span>
            <FormattedMessage id='recording' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('recordingPrefix', {
          initialValue: (numeration.recording || {}).prefix,
          rules: [
            {
              required: true,
              message: `Prefix ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            style={{
              maxWidth: 100,
              textAlign: 'center'
            }}
            onChange={e => updateNumeration('recording', e.target.value)}
            placeholder='Prefix'
          />
        )}
        -{_.padStart((numeration.recording || {}).nextNumber || '', 5, '0')}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={{ display: 'flex', justifyContent: 'center' }}
        label={
          <span>
            <FormattedMessage id='acceptance' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('acceptancePrefix', {
          initialValue: (numeration.acceptance || {}).prefix,
          rules: [
            {
              required: true,
              message: `Prefix ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            style={{
              maxWidth: 100,
              textAlign: 'center'
            }}
            onChange={e => updateNumeration('acceptance', e.target.value)}
            placeholder='Prefix'
          />
        )}
        -{_.padStart((numeration.acceptance || {}).nextNumber || '', 5, '0')}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={{ display: 'flex', justifyContent: 'center' }}
        label={
          <span>
            <FormattedMessage id='workorder' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('workorderPrefix', {
          initialValue: (numeration.workorder || {}).prefix,
          rules: [
            {
              required: true,
              message: `Prefix ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            style={{
              maxWidth: 100,
              textAlign: 'center'
            }}
            onChange={e => updateNumeration('workorder', e.target.value)}
            placeholder='Prefix'
          />
        )}
        -{_.padStart((numeration.workorder || {}).nextNumber || '', 5, '0')}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        style={{ display: 'flex', justifyContent: 'center' }}
        label={
          <span>
            <FormattedMessage id='request' />
            <span style={{ color: '#005591' }}>*</span>
          </span>
        }
        required={false}
      >
        {getFieldDecorator('requestPrefix', {
          initialValue: (numeration.request || {}).prefix,
          rules: [
            {
              required: true,
              message: `Prefix ${props.intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            style={{
              maxWidth: 100,
              textAlign: 'center'
            }}
            onChange={e => updateNumeration('request', e.target.value)}
            placeholder='Prefix'
          />
        )}
        -{_.padStart((numeration.request || {}).nextNumber || '', 5, '0')}
      </Form.Item>
    </>
  )
}

Numeration.propTypes = {
  numeration: PropTypes.object.isRequired,
  formItemLayout: PropTypes.object,
  updateNumeration: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default injectIntl(Numeration)
