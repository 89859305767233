import React from 'react'

import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import { Table, Icon, Button, notification, Form, Input } from 'antd'
import arrayMove from 'array-move'

import userService from 'services/user'
import scheduledWorkOrdersService from 'services/scheduledWorkOrders'
import specialWorkService from 'services/specialWork'
import objectService from 'services/object'
import recordingService from 'services/recording'

import WorkOrderForm from 'components/WorkOrders/Form'
import PageTitle from 'components/Global/PageTitle'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import queryString from 'query-string'
import { setPickedProject } from '../../../ducks/project'

export class ScheduledWorkordersPage extends React.Component {
  state = {
    workOrders: [],
    workOrder: {},
    users: [],
    objects: [],
    assignUsers: [],
    assignSpecialWork: [],
    modalCommentActive: '',
    visible: false,
    selectedRowKeys: [],
    createdByFilters: [],
    executedByFilters: [],
    loading: false,
    formLoading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    locations: {},
    tasks: {
      id: '',
      shareholders: []
    },
    taskEditMode: false,
    fileUploadMode: false,
    objectIdSearchResult: [],
    recordingIdSearchResult: [],
    allRecordings: [],
    params: {},
    submitting: false
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, project } = this.props
    if (project.pickedProject && project.pickedProject._id) {
      paramsObject.project = project.pickedProject._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }
    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  toggleTaskEditMode = () => {
    this.setState({
      taskEditMode: !this.state.taskEditMode,
      fileUploadMode: false
    })
  }

  toggleFileuploadMode = () => {
    this.setState({
      fileUploadMode: !this.state.fileUploadMode,
      taskEditMode: false
    })
  }

  showDrawer = () => {
    this.setState({
      visible: true
    })
  }

  handleClose = () => {
    this.setState({
      visible: false,
      taskEditMode: false,
      fileUploadMode: false,
      workOrder: {}
    })
  }

  componentDidMount () {
    this.fetchObjects()
    this.fetchWorkOrders()
  }

  componentDidUpdate (prevProps) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch },
      project,
      dispatch
    } = this.props
    const params = { ...this.getParams() }

    if (
      params.project &&
      !prevProps.project.projectList.length &&
      project.projectList.length
    ) {
      dispatch(
        setPickedProject(
          project.projectList.find(item => item._id === params.project)
        )
      )
    }

    if (project.pickedProject !== prevProps.project.pickedProject) {
      if (project && project.pickedProject) {
        this.setParams({ ...params, project: project.pickedProject._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }

    if (prevSearch !== nextSearch || this.props.project !== prevProps.project) {
      this.fetchWorkOrders()
    }
  }

  markAsDoneTaskHandler = async id => {
    const copyShareholders = [...this.state.tasks.shareholders]
    const objTaskIndex = this.state.tasks.shareholders.findIndex(
      obj => obj.id === id
    )
    copyShareholders[objTaskIndex].complete = !copyShareholders[objTaskIndex]
      .complete

    this.setState({
      tasks: {
        shareholders: copyShareholders
      }
    })
  }

  handleSortTasksEnd = ({ oldIndex, newIndex }) => {
    this.setState(previousState => ({
      tasks: {
        shareholders: arrayMove(
          previousState.tasks.shareholders,
          oldIndex,
          newIndex
        )
      }
    }))
  }

  setSharholdersOnMount = () => {
    this.setState(
      {
        tasks: {
          id: '',
          shareholders: []
        }
      },
      () => {
        this.state.workOrder.tasks.map(task => {
          return this.setState(previousState => ({
            tasks: {
              shareholders: [
                ...previousState.tasks.shareholders,
                {
                  id: task._id,
                  name: task.name,
                  complete: task.complete ? task.complete : false,
                  comments: task.comments,
                  objectId: task.objectId,
                  description: task.description
                }
              ]
            }
          }))
        })
      }
    )
  }

  onTaskIdChange = (field, idx) => evt => {
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return {
        ...shareholder,
        id: this.state.workOrder._id,
        [field]: evt.target.value
      }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  onTaskIdChangeAutocomplete = (field, idx, value) => {
    const currentObjectId = this.state.objects.filter(obj => {
      return obj.name === value
    })

    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return {
        ...shareholder,
        id: this.state.workOrder._id,
        [field]: currentObjectId[0]
      }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  onSearchObjectId = (field, value, idx) => {
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return {
        ...shareholder,
        id: this.state.workOrder._id,
        [field]: { _id: null, name: value }
      }
    })

    this.setState({
      tasks: { shareholders: newShareholders },
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const response = await objectService.listV2({
          name: [value]
        })

        this.setState({
          objectIdSearchResult: response.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        console.log('Fetching error')
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  onTaskIdSelectChange = (field, idx) => value => {
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      return {
        ...shareholder,
        id: this.state.workOrder._id,
        [field]: { name: value }
      }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  onAddShareholder = () => {
    const { tasks } = this.state
    this.setState({
      tasks: { shareholders: tasks.shareholders.concat([{ id: '' }]) }
    })
  }

  onRemoveTaskholder = idx => async () => {
    try {
      // await taskService.deleteTask(this.state.workOrder._id)
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'task removing'
        })} - upload fail`
      })
    }

    const { tasks } = this.state
    this.setState({
      tasks: {
        shareholders: tasks.shareholders.filter((s, sidx) => idx !== sidx)
      }
    })
  }

  fetchObjects = async () => {
    try {
      const objects = await objectService.objectsList()
      const uniqueObjects = _.uniqBy(objects, x => {
        return x.name
      })

      this.setState({
        objects: uniqueObjects
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'objects'
        })} - download error`
      })
    }
  }

  fetchSpecialWork = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const res = await specialWorkService.listV2({
          name: [value],
          limit: 100
        })

        this.setState({
          assignSpecialWork: res.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        notification.error({
          message: `${this.props.intl.formatMessage({
            id: 'specialwork'
          })} - download error`
        })

        this.setState({
          assignSpecialWork: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  fetchUsersHandler = async value => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const users = await userService.listV2({
          fullName: [value],
          limit: 100
        })

        this.setState({
          assignUsers: users.docs,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        console.log('Fetching error')
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  updateAssignedUser = value => {
    this.setState({
      workOrder: {
        ...this.state.workOrder,
        executedBy: value
      }
    })
  }

  sortList = list => {
    return list && list.sort(sortByallStatus)
  }

  handleCreateWorkOrder = async () => {
    const {
      name,
      description,
      recurring,
      priority,
      rrule,
      executedBy,
      status,
      specialWork,
      specialWorkRequired,
      signatureRequired
    } = this.state.workOrder
    this.setState({
      submitting: true
    })
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name,
            description,
            recurring,
            priority,
            rrule,
            status,
            signatureRequired,
            specialWorkRequired,
            specialWork,
            tasks: []
          }

          if (executedBy) {
            payload.executedBy = executedBy._id
          }

          if (this.state.tasks.shareholders.length) {
            this.state.tasks.shareholders.map(async task => {
              const payloadTasks = {}
              if (task.name) {
                payloadTasks.name = task.name
                payloadTasks.description = task.description

                if (task.objectId) {
                  const currentObjectId = this.state.objects.filter(obj => {
                    return obj.name === task.objectId.name
                  })
                  payloadTasks.objectId = currentObjectId[0]._id
                }

                payload.tasks.push(payloadTasks)
              }
            })
          }
          const savedWorkOrder = await scheduledWorkOrdersService.addWorkOrder(
            payload
          )

          this.fetchWorkOrders()
          notification.success({
            message: `${this.props.intl.formatMessage({
              id: 'created successfully'
            })}`
          })

          this.setState({
            visible: false,
            taskEditMode: false,
            fileUploadMode: false,
            workOrder: {},
            pagination: {
              current: 1
            },
            workOrders: this.sortList([
              ...this.state.workOrders,
              savedWorkOrder
            ])
          })
          setTimeout(() => this.setState({ submitting: false }), 1500)
        } catch (error) {
          this.setState({
            submitting: false
          })
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.message
          })
        }
      } else {
        this.setState({
          submitting: false
        })
      }
    })
  }

  handleUpdateWorkOrder = async () => {
    const {
      _id,
      name,
      description,
      recurring,
      priority,
      rrule,
      executedBy,
      status,
      specialWork,
      specialWorkRequired,
      signatureRequired
    } = this.state.workOrder
    this.setState({
      submitting: true
    })
    this.props.form.validateFields(async err => {
      if (!err) {
        try {
          const payload = {
            name,
            description,
            recurring,
            priority,
            rrule,
            status,
            signatureRequired,
            specialWorkRequired,
            specialWork,
            executedBy: executedBy ? executedBy._id : null
          }

          const copyWorkorders = [...this.state.workOrders]
          const objTaskIndex = this.state.workOrders.findIndex(
            obj => obj._id === this.state.workOrder._id
          )
          copyWorkorders[objTaskIndex].tasks = []

          if (this.state.tasks.shareholders.length) {
            this.state.tasks.shareholders.forEach(task => {
              const payloadTasks = {}
              payloadTasks.name = task.name
              payloadTasks.description = task.description
              payloadTasks.objectId = task.objectId

              // if (task.objectId) {
              //   var currentObjectId = this.state.objects.filter(obj => {
              //     return obj.name === task.objectId
              //   })
              //   payloadTasks.objectId = currentObjectId[0]._id
              // }

              payloadTasks.complete = task.complete
              copyWorkorders[objTaskIndex].tasks.push(payloadTasks)
            })
          }
          this.setState(
            {
              workOrders: copyWorkorders
            },
            async () => {
              payload.tasks = this.state.workOrder.tasks
              const savedWorkOrder = await scheduledWorkOrdersService.updateWorkOrder(
                _id,
                payload
              )

              this.fetchWorkOrders()
              notification.success({
                message: `${this.props.intl.formatMessage({
                  id: 'updated successfully'
                })}`
              })

              this.setState({
                visible: false,
                taskEditMode: false,
                fileUploadMode: false,
                workOrder: {},
                workOrders: this.sortList([
                  ...this.state.workOrders.filter(
                    workOrder => workOrder._id !== savedWorkOrder._id
                  ),
                  savedWorkOrder
                ])
              })
              setTimeout(() => this.setState({ submitting: false }), 1500)
            }
          )
        } catch (error) {
          console.log('error')
          console.log(error)
          this.setState({
            submitting: false
          })
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.message
          })
        }
      } else {
        this.setState({
          submitting: false
        })
      }
    })
  }

  handleDeleteWorkOrder = async () => {
    try {
      await scheduledWorkOrdersService.deleteWorkOrder(this.state.workOrder._id)

      this.setState({
        visible: false,
        workOrder: {},
        workOrders: [
          ...this.state.workOrders.filter(
            workOrder => workOrder._id !== this.state.workOrder._id
          )
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error'
        }),
        description: error.message
      })
    }
  }

  fetchWorkOrders = async () => {
    const params = this.getParams()

    this.setState({
      loading: true
    })
    try {
      const workOrders = await scheduledWorkOrdersService.list({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = workOrders.totalDocs

      this.setState({
        workOrders: workOrders.docs.sort(sortByallStatus),
        loading: false,
        createdByFilters: workOrders.filterOptions.createdBy,
        executedByFilters: workOrders.filterOptions.executedBy,
        pagination: {
          ...pagination,
          pageSize: workOrders.limit,
          current: workOrders.page
        },
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'recordings'
        })} - download error`
      })

      this.setState({
        workOrders: [],
        visible: false,
        loading: false,
        workOrder: {}
      })
    }
  }

  showModalEdit = async workOrder => {
    this.setSharholdersOnMount()
    this.setState({
      visible: true,
      new: false,
      workOrder,
      modalOption: 'edit'
    })
  }

  handleShowModalAdd = async () => {
    this.setState({
      visible: true,
      new: true,
      workOrder: {},
      modalOption: 'add',
      tasks: {
        id: '',
        shareholders: [{ id: '' }]
      }
    })
  }

  hideModal = () => {
    this.setState({
      visible: false
    })
  }

  showModalComment = id => {
    this.setState({
      modalCommentActive: id
    })
  }

  onHideModalComment = () => {
    this.setState({
      modalCommentActive: ''
    })
  }

  updateStateWorkOrder = (field, value) => {
    this.setState({
      workOrder: {
        ...this.state.workOrder,
        [field]: value
      }
    })
  }

  updateStateSpecialWork = value => {
    const specialWorks = value || []
    const newArraysIds = []

    if (specialWorks.length && this.state.assignSpecialWork.length) {
      specialWorks.forEach(item => {
        const currentObjectId = this.state.assignSpecialWork.filter(obj => {
          return obj.name === item
        })
        newArraysIds.push(currentObjectId[0]._id)
      })
      this.setState({
        workOrder: {
          ...this.state.workOrder,
          specialWork: newArraysIds
        }
      })
    }
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys
    })
  }

  handleOnTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon='search'
          size='small'
          disabled={!this.state[dataIndex + 'Search']}
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  onSearchRecordingId = (field, value, idx, e) => {
    this.setState({
      formLoading: true
    })
    const timer = setTimeout(async () => {
      try {
        const response = await recordingService.listV2({
          name: [value],
          limit: 100
        })
        const uniqueRecordings = _.uniqBy(response.docs, x => {
          return x.name
        })

        const concatRecordings = [
          ...this.state.allRecordings,
          ...uniqueRecordings
        ]
        this.setState({
          recordingIdSearchResult: uniqueRecordings,
          allRecordings: concatRecordings,
          formLoading: false
        })
        clearTimeout(timer)
      } catch (error) {
        console.log('Fetching error')
        this.setState({
          formLoading: false
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  onRecordingSelect = (field, idx, value, e) => {
    const currentRecording = this.state.recordingIdSearchResult.filter(obj => {
      return obj._id === e.props.id
    })
    const { tasks } = this.state
    const newShareholders = tasks.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder
      if (shareholder.recording) {
        return {
          ...shareholder,
          id: this.state.workOrder._id,
          [field]: [...currentRecording]
        }
      }
      return {
        ...shareholder,
        id: this.state.workOrder._id,
        [field]: currentRecording
      }
    })

    this.setState({ tasks: { shareholders: newShareholders } })
  }

  render () {
    const columns = [
      {
        title: this.props.intl.formatMessage({ id: 'number' }),
        key: 'docNumber',
        dataIndex: 'docNumber',
        ...this.getColumnSearchProps('docNumber')
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        ...this.getColumnSearchProps('name')
      },
      {
        title: this.props.intl.formatMessage({ id: 'description' }),
        dataIndex: 'description',
        key: 'description',
        ...this.getColumnSearchProps('description')
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: status => {
          let statusText = ''
          switch (status) {
            case 1: {
              statusText = this.props.intl.formatMessage({ id: 'open' })
              break
            }
            case 2: {
              statusText = this.props.intl.formatMessage({
                id: 'in progress'
              })
              break
            }
            case 3: {
              statusText = this.props.intl.formatMessage({
                id: 'on hold'
              })
              break
            }
            case 4: {
              statusText = this.props.intl.formatMessage({
                id: 'complete'
              })
              break
            }
            default:
              console.log('no status')
          }
          return <span>{statusText}</span>
        },
        sorter: true,
        filteredValue: this.state.params.status
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true,
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'assignee' }),
        key: 'executedBy',
        dataIndex: 'executedBy',
        render: executedBy =>
          executedBy ? `${executedBy.firstName} ${executedBy.lastName}` : '',
        filters: this.state.executedByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: this.state.params.executedBy
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : '',
        filters: this.state.createdByFilters.map(user => ({
          text: `${user.firstName} ${user.lastName}`,
          value: user._id
        })),
        filteredValue: this.state.params.createdBy
      },
      {
        title: this.props.intl.formatMessage({ id: 'starts at' }),
        key: 'startDate',
        dataIndex: 'startDate',
        render: (text, record) => {
          if (record.startDate) {
            return moment(record.startDate).format('DD.MM.YYYY, HH:mm')
          }
          return ''
        },
        sorter: true
      },
      {
        key: 'actions',
        render: (text, record) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-end'
            }}
          >
            <Button onClick={() => this.showModalEdit(record)}>
              <Icon
                type={
                  record.isVerified || record.isInvalid ? 'info-circle' : 'edit'
                }
              />
            </Button>
          </div>
        )
      }
    ]
    const {
      workOrders,
      visible,
      modalOption,
      workOrder,
      assignUsers,
      objects,
      assignSpecialWork,
      taskEditMode,
      fileUploadMode,
      modalCommentActive,
      formLoading,
      submitting
    } = this.state

    const headerButtons = [
      <Button
        key={526362332235}
        type='primary'
        onClick={this.handleShowModalAdd}
      >
        <FormattedMessage id='create scheduled workorder' />
      </Button>
    ]

    const footerDrawerButtons = [
      <Button key={1} onClick={this.handleClose} style={{ marginRight: 8 }}>
        <FormattedMessage id='cancel' />
      </Button>
    ]

    if (
      ['admin', 'superadmin'].includes(this.props.auth.authUser.scope[0]) &&
      this.state.workOrder &&
      !this.state.new
    ) {
      footerDrawerButtons.unshift(
        <PopconfirmDelete
          key={2}
          onConfirmAction={this.handleDeleteWorkOrder}
        />
      )
    }

    if (this.state.new) {
      footerDrawerButtons.push(
        <Button
          key={3}
          onClick={this.handleCreateWorkOrder}
          type='primary'
          loading={submitting}
          disabled={submitting}
        >
          {this.props.intl.formatMessage({
            id: 'save workorder'
          })}
        </Button>
      )
    } else {
      footerDrawerButtons.push(
        <Button
          key={4}
          onClick={this.handleUpdateWorkOrder}
          type='primary'
          loading={submitting}
          disabled={submitting}
        >
          {this.props.intl.formatMessage({
            id: 'update workorder'
          })}
        </Button>
      )
    }

    return (
      <div>
        <FormattedMessage id='head.title.workorders'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({
            id: 'workorder planning'
          })}
          buttons={headerButtons}
        />

        <Table
          // eslint-disable-next-line no-return-assign
          ref={ref => (this._table = ref)}
          rowKey={record => record._id}
          columns={columns}
          dataSource={workOrders}
          loading={this.state.loading}
          onRow={record => ({
            onDoubleClick: () => {
              this.showModalEdit(record)
            }
          })}
          onChange={this.handleOnTableChange}
          defaultPageSize={this.state.pagination.defaultPageSize}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={
            this.state.new === true
              ? this.props.intl.formatMessage({
                  id: 'create a new scheduled Workorder'
                })
              : this.props.intl.formatMessage({
                  id: 'edit workorder'
                })
          }
          onClose={this.handleClose}
          visible={visible}
          footerButtons={footerDrawerButtons}
        >
          <WorkOrderForm
            scheduled
            isNew={modalOption}
            workOrder={workOrder}
            users={assignUsers}
            objects={objects}
            formLoading={formLoading}
            fetchSpecialWork={this.fetchSpecialWork}
            fetchUsersHandler={this.fetchUsersHandler}
            updateAssignedUser={this.updateAssignedUser}
            recordingIdSearchResult={this.state.recordingIdSearchResult}
            objectIdSearchResult={this.state.objectIdSearchResult}
            specialWork={assignSpecialWork}
            taskEditMode={taskEditMode}
            toggleTaskEditMode={this.toggleTaskEditMode}
            fileUploadMode={fileUploadMode}
            toggleFileuploadMode={this.toggleFileuploadMode}
            updateStateWorkOrder={this.updateStateWorkOrder}
            updateStateSpecialWork={this.updateStateSpecialWork}
            option={modalOption}
            shareholders={this.state.tasks.shareholders}
            taskIdChange={this.onTaskIdChange}
            handleTaskIdSelectChange={this.onTaskIdSelectChange}
            handleTaskIdChangeAutocomplete={this.onTaskIdChangeAutocomplete}
            handleSearchObjectId={this.onSearchObjectId}
            addShareholder={this.onAddShareholder}
            removeTaskholder={this.onRemoveTaskholder}
            markAsDoneTask={this.markAsDoneTaskHandler}
            onSortTasksEnd={this.handleSortTasksEnd}
            showModalComment={this.showModalComment}
            hideModalComment={this.onHideModalComment}
            form={this.props.form}
            modalCommentActive={modalCommentActive}
            handleSearchRecordingId={this.onSearchRecordingId}
            handleRecordingSelect={this.onRecordingSelect}
          />
        </DrawerComponent>
      </div>
    )
  }
}

const sortByallStatus = (a, b) => {
  return a.status - b.status
}

ScheduledWorkordersPage.propTypes = {
  auth: PropTypes.object,
  project: PropTypes.object,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  project: state.project
})

export default injectIntl(
  Form.create({ name: 'work_orders_form' })(
    connect(mapStateToProps)(ScheduledWorkordersPage)
  )
)
