import React from 'react'

import moment from 'moment'
import { Button, Form, Icon, notification, Popconfirm, Table } from 'antd'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { injectIntl, FormattedMessage } from 'react-intl'

import PageTitle from 'components/Global/PageTitle'
import ProjectForm from 'components/Project/Form'
import DrawerComponent from 'components/Drawer'

import projectService from 'services/project'
import userService from 'services/user'
import formService from 'services/form'
import reduxActions from 'actions'
import queryString from 'query-string'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'

export class ProjectsPage extends React.Component {
  state = {
    users: [],
    forms: [],
    projects: [],
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    drawer: false,
    project: {},
    archivedFilters: [],
    params: {},
    submitting: false
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.archived) {
        searchQuery.archived = ['showUnarchived']
      }

      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'projectName'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, project } = this.props
    if (project && project.project) {
      paramsObject.project = project.project._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'projectName' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    if (isEqual(paramsObject.archived, ['showUnarchived'])) {
      delete paramsObject.archived
    }
    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchProjects()
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch }
    } = this.props
    if (this.props.project !== prevProps.project) {
      const params = { ...this.getParams() }
      if (this.props.project && this.props.project.project) {
        this.setParams({ ...params, project: this.props.project.project._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }
    if (prevSearch !== nextSearch) {
      this.fetchProjects()
    }
  }

  fetchProjects = async () => {
    const params = this.getParams()
    if (params) {
      delete params.project
    }
    this.setState({
      loading: true
    })
    try {
      const projects = await projectService.listV2({
        ...params
      })
      this.props.dispatch(reduxActions.setProjects(projects.docs))

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = projects.totalDocs

      this.setState({
        projects: projects.docs,
        loading: false,
        pagination: {
          ...pagination,
          pageSize: projects.limit,
          current: projects.page
        },
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'projects'
        })} - download error`
      })

      this.setState({
        projects: [],
        loading: false
      })
    }
  }

  fetchUsers = async value => {
    const timer = setTimeout(async () => {
      try {
        const users = await userService.listV2({
          fullName: [value],
          limit: 100
        })

        this.setState({
          users: users.docs
        })
        clearTimeout(timer)
      } catch (error) {
        notification.error({
          message: `${this.props.intl.formatMessage({
            id: 'users'
          })} - download error`
        })

        this.setState({
          users: []
        })
        clearTimeout(timer)
      }
    }, 1000)
  }

  fetchForms = async value => {
    try {
      const forms = await formService.listV2({
        name: [value],
        limit: 100
      })

      this.setState({
        forms: forms.docs
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'forms'
        })} - download error`
      })

      this.setState({
        forms: []
      })
    }
  }

  showDrawer = project => {
    this.setState({
      drawer: true,
      project: {
        ...project
      }
    })
  }

  handleHideDrawer = () => {
    this.setState({
      drawer: false,
      project: {}
    })
  }

  handleSaveProject = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        this.setState({
          submitting: true
        })
        try {
          const savedProject = await projectService.save(this.state.project)

          this.fetchProjects()
          if (this.state.project._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'updated successfully'
              })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'created successfully'
              })}`
            })
          }
          setTimeout(() => this.setState({ submitting: false }), 1500)

          const updatedProjects = [
            ...this.props.projects.filter(
              project => project._id !== savedProject._id
            ),
            savedProject
          ]

          this.props.dispatch(reduxActions.setProjects(updatedProjects))

          this.setState({
            drawer: false,
            project: {}
          })

          this.props.dispatch(
            reduxActions.setProjectLastUpdated(moment().format())
          )
        } catch (error) {
          this.setState({
            submitting: false
          })
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  archiveProject = async archived => {
    try {
      const savedProject = await projectService.save({
        ...this.state.project,
        archived: archived
      })

      this.setState({
        drawer: false,
        project: {},
        projects: [
          ...this.state.projects.filter(
            project => project._id !== savedProject._id
          ),
          savedProject
        ]
      })

      this.props.dispatch(reduxActions.setProjectLastUpdated(moment().format()))
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'saving error'
        }),
        description: error.error
      })
    }
  }

  updateProject = (field, value) => {
    if (field === 'custom_forms.acceptance') {
      this.setState(state => ({
        project: {
          ...state.project,
          custom_forms: {
            ...this.state.custom_forms,
            acceptance: value
          }
        }
      }))
      return
    }
    this.setState(state => ({
      project: {
        ...state.project,
        [field]: value
      }
    }))
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  render () {
    const { intl } = this.props
    const { submitting } = this.state
    const columns = [
      {
        title: 'Name',
        key: 'projectName',
        dataIndex: 'projectName',
        sorter: true,
        defaultSortOrder:
          this.state.params.sortField === 'createdAt'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        sorter: true
      },
      {
        title: this.props.intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: (text, record) => {
          if (record.createdBy) {
            return record.createdBy.fullName
          }

          return ''
        }
      },
      {
        title: this.props.intl.formatMessage({ id: 'archived' }),
        key: 'archived',
        dataIndex: 'archived',
        align: 'center',
        render: archived => (
          <>{archived && <Icon type='check' style={{ color: '#005591' }} />}</>
        ),
        filters: [
          {
            text: intl.formatMessage({
              id: 'show all'
            }),
            value: 'showAll'
          },
          {
            text: intl.formatMessage({
              id: 'show unarchived'
            }),
            value: 'showUnarchived'
          },
          {
            text: intl.formatMessage({
              id: 'show archived'
            }),
            value: 'showArchived'
          }
        ],
        filteredValue: this.state.params.archived || ['showUnarchived']
      },
      {
        key: 'actions',
        align: 'right',
        render: (text, record) => (
          <Button onClick={() => this.showDrawer(record)}>
            <Icon type={record.archived ? 'info-circle' : 'edit'} />
          </Button>
        )
      }
    ]

    const drawerButtons = [
      <Button key='cancel' onClick={this.handleHideDrawer}>
        <FormattedMessage id='cancel' />
      </Button>,
      <Button
        style={{ marginLeft: 8 }}
        key='save'
        type='primary'
        onClick={this.handleSaveProject}
        disabled={
          (this.state.project && this.state.project.archived) || submitting
        }
        loading={submitting}
      >
        <FormattedMessage id='save' />
      </Button>
    ]
    if (this.state.project && this.state.project._id) {
      if (this.state.project.archived) {
        drawerButtons.unshift(
          <Popconfirm
            key='archive'
            title={this.props.intl.formatMessage({
              id: 'do you want to unarchive this project?'
            })}
            onConfirm={() => this.archiveProject(false)}
            okText={this.props.intl.formatMessage({ id: 'yes' })}
            cancelText={this.props.intl.formatMessage({ id: 'no' })}
          >
            <Button
              type='danger'
              style={{
                float: 'left'
              }}
            >
              <FormattedMessage id='open' />
            </Button>
          </Popconfirm>
        )
      } else {
        drawerButtons.unshift(
          <Popconfirm
            key='archive'
            title={this.props.intl.formatMessage({
              id: 'do you want to archive this project?'
            })}
            onConfirm={() => this.archiveProject(true)}
            okText={this.props.intl.formatMessage({ id: 'yes' })}
            cancelText={this.props.intl.formatMessage({ id: 'no' })}
          >
            <Button
              type='danger'
              style={{
                float: 'left'
              }}
            >
              <FormattedMessage id='archive' />
            </Button>
          </Popconfirm>
        )
      }
    }

    const { projects, project, drawer, users, forms, loading } = this.state

    const headerButtons = [
      <Button
        key={85623525325222}
        type='primary'
        onClick={() => this.showDrawer({})}
      >
        <FormattedMessage id='create project' />
      </Button>
    ]

    return (
      <div>
        <FormattedMessage id='head.title.projects'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'projects' })}
          buttons={headerButtons}
        />

        <Table
          columns={columns}
          dataSource={projects}
          rowKey={record => record._id}
          loading={loading}
          onChange={this.handleTableChange}
          onRow={record => ({
            onDoubleClick: () => {
              this.showDrawer(record)
            }
          })}
          defaultPageSize={this.state.pagination.defaultPageSize}
          pagination={this.state.pagination}
        />
        <DrawerComponent
          title={
            project._id
              ? project.projectName
              : this.props.intl.formatMessage({
                  id: 'create project'
                })
          }
          onClose={this.handleHideDrawer}
          visible={!!drawer}
          footerButtons={drawerButtons}
        >
          <ProjectForm
            project={project}
            users={users}
            forms={forms}
            fetchForms={this.fetchForms}
            fetchUsers={this.fetchUsers}
            updateProject={this.updateProject}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

ProjectsPage.propTypes = {
  intl: PropTypes.object.isRequired,
  project: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  projects: state.project.projectList,
  project: state.project.pickedProject
})

export default injectIntl(
  Form.create({ name: 'project_form' })(connect(mapStateToProps)(ProjectsPage))
)
