import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { setDrawerVisible } from '../../../../../ducks/fields'

import PageTitle from 'components/Global/PageTitle'
import Button from 'components/Global/Button'

const FieldDefinitionHeader = props => {
  const intl = useIntl()
  const dispatch = useDispatch()

  return (
    <>
      <FormattedMessage id='head.title.fieldDefinition'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({ id: 'field definition' })}
        buttons={
          <Button
            style={{ marginLeft: 8 }}
            key={238397323536}
            type='primary'
            onClick={() => dispatch(setDrawerVisible(true))}
          >
            <FormattedMessage id='create field' />
          </Button>
        }
      />
    </>
  )
}

export default connect()(FieldDefinitionHeader)
