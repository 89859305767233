import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import FormManufacturer from './FormManufacturer'
import manufacturersService from '../../../services/manufacturers'
import PopconfirmDelete from '../../../components/Global/PopconfirmDelete'

const ManufacturerManagement = ({
  visible,
  manufacturer,
  onSubmit,
  onClose
}) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createManufacturer] = useApi(
    manufacturersService.addManufacturer
  )
  const [updating, updateManufacturer] = useApi(
    manufacturersService.updateManufacturer
  )
  const [deleting, deleteManufacturer] = useApi(
    manufacturersService.deleteManufacturer
  )

  const handleSubmit = () => {
    form.current.validateFields(async (errors, { _id, ...record }) => {
      if (errors) return

      onSubmit(
        _id
          ? await updateManufacturer(_id, record)
          : await createManufacturer(record)
      )
    })
  }

  const handleDelete = async () =>
    onSubmit(await deleteManufacturer(manufacturer._id))

  const loading = creating || updating || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (scope === 'superadmin' && manufacturer && !manufacturer.isVerified) {
    drawerButtons.unshift(
      <PopconfirmDelete key='delete' onConfirmAction={handleDelete} />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${manufacturer ? 'edit' : 'add'} manufacturer`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormManufacturer ref={form} manufacturer={manufacturer} />
    </Drawer>
  )
}

ManufacturerManagement.propTypes = {
  manufacturer: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

ManufacturerManagement.defaultProps = {
  visible: false
}

export default ManufacturerManagement
