import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { Checkbox, Form, Input, Select, Spin } from 'antd'

class LocationForm extends React.Component {
  render () {
    const { getFieldDecorator } = this.props.form
    const { location, projects } = this.props

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <Form onSubmit={() => {}} className='locationForm'>
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              Name<span style={{ color: '#005591' }}>*</span>
            </span>
          }
          required={false}
        >
          {getFieldDecorator('name', {
            initialValue: location.name,
            rules: [
              {
                required: true,
                message: this.props.intl.formatMessage({
                  id: 'name is required'
                })
              }
            ]
          })(
            <Input
              onChange={event =>
                this.props.updateLocation('name', event.target.value)
              }
            />
          )}
        </Form.Item>

        <>
          <Form.Item
            {...formItemLayout}
            label={this.props.intl.formatMessage({
              id: 'project'
            })}
          >
            <Select
              showSearch
              allowClear
              style={{ width: '100%' }}
              optionFilterProp='children'
              filterOption={(input, option) => {
                return (
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                )
              }}
              notFoundContent={
                this.props.formLoading ? (
                  <div className='location-spinner-container'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              onFocus={() => this.props.fetchProjects('')}
              onSearch={value => this.props.fetchProjects(value)}
              onChange={projectID =>
                this.props.updateLocation('project', projectID)
              }
              defaultValue={
                location.project ? location.project.projectName : ''
              }
              disabled={this.props.readOnlyNewProject}
            >
              {projects.map(project => {
                return (
                  <Select.Option key={project._id} value={project._id}>
                    {project.projectName}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label={this.props.intl.formatMessage({
              id: 'disabled'
            })}
          >
            <Checkbox
              checked={!!location.disabled}
              onChange={event =>
                this.props.updateLocation('disabled', event.target.checked)
              }
            />
          </Form.Item>
        </>
      </Form>
    )
  }
}

LocationForm.propTypes = {
  location: PropTypes.object.isRequired,
  updateLocation: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  formLoading: PropTypes.bool,
  fetchProjects: PropTypes.func.isRequired,
  readOnlyNewProject: PropTypes.bool
}

export default injectIntl(LocationForm)
