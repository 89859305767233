import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, Tooltip, Input, Form, Spin, Select } from 'antd'
import {
  updateTask,
  setPickedTask,
  setTasksData
} from '../../../../../ducks/tasks'
import { fetchObjectsList } from '../../../../../ducks/object'
import debounce from 'lodash/debounce'
import { setWorkorderData } from '../../../../../ducks/workorder'

const { Option } = Select

const statusOptions = ['opened', 'in process', 'finished', 'completed']

export class FieldEdit extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: undefined,
      error: false,
      loading: false
    }
    this.handleInputThrottled = debounce(this.fetchObjectsOnSearch, 300)
  }

  handleCancelEdit = () => {
    const { handleCancel } = this.props

    this.setState({
      value: undefined
    })
    handleCancel()
  }

  handleValue = value => {
    this.setState({
      value
    })
  }

  handleSubmit = () => {
    const {
      editingField,
      pickedTask,
      dispatch,
      tasksData,
      workorderData
    } = this.props
    const { value } = this.state
    if (
      !value ||
      (typeof value === 'object' && Object.keys(value).length === 0)
    ) {
      this.setState({ error: true })
      return
    }

    dispatch(updateTask(pickedTask._id, { [editingField]: value })).then(
      response => {
        if (!response.error) {
          dispatch(setPickedTask(response))
          if (!pickedTask.workorder) {
            dispatch(
              setTasksData({
                ...tasksData,
                docs: [
                  ...tasksData.docs.map(task => {
                    if (task._id === response._id) {
                      return response
                    }
                    return task
                  })
                ]
              })
            )
          } else {
            dispatch(
              setWorkorderData({
                ...workorderData,
                docs: [
                  ...workorderData.docs.map(workorder => {
                    if (workorder._id === pickedTask.workorder._id) {
                      const outputWorkorder = { ...workorder }
                      const tasksList = workorder.tasks.map(task => {
                        if (task._id === response._id) {
                          return response
                        }
                        return task
                      })
                      outputWorkorder.tasks = [...tasksList]
                      return outputWorkorder
                    }
                    return workorder
                  })
                ]
              })
            )
          }

          this.handleCancelEdit()
        }
      }
    )
  }

  fetchObjectsOnSearch = searchQueue => {
    const { dispatch } = this.props
    this.setState({
      loading: true
    })
    dispatch(fetchObjectsList({ 'name[]': searchQueue }, true)).then(
      response => {
        this.setState({
          loading: false
        })
      }
    )
  }

  render () {
    const {
      pickedTask,
      intl,
      children,
      editingField,
      objects,
      editing
    } = this.props
    const { value, error, loading } = this.state

    return (
      <div className='editing'>
        {!editing && (
          <Tooltip title={intl.formatMessage({ id: 'edit' })}>
            {children}
          </Tooltip>
        )}
        {editing && (
          <div>
            <Form.Item
              validateStatus={error ? 'error' : null}
              help={error ? intl.formatMessage({ id: 'is required' }) : null}
            >
              {editingField === 'name' && (
                <Input
                  maxLength={40}
                  placeholder={intl.formatMessage({ id: 'name' })}
                  value={value !== undefined ? value : pickedTask[editingField]}
                  onChange={event => this.handleValue(event.target.value)}
                />
              )}
              {editingField === 'description' && (
                <>
                  <strong> {intl.formatMessage({ id: 'description' })}</strong>

                  <Input.TextArea
                    onChange={e => this.handleValue(e.target.value)}
                    value={
                      value !== undefined ? value : pickedTask[editingField]
                    }
                  />
                </>
              )}
              {editingField === 'objectId' && (
                <Select
                  showSearch
                  allowClear
                  loading={loading}
                  className='certain-category-search'
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: 200 }}
                  style={{ width: '100%' }}
                  value={
                    value
                      ? value.name
                      : (pickedTask[editingField] &&
                          pickedTask[editingField].name) ||
                        undefined
                  }
                  placeholder='Select Object'
                  initialValue='Select Object'
                  optionFilterProp='children'
                  notFoundContent={
                    loading ? (
                      <div className='recordings-spinner-container'>
                        <Spin size='small' />
                      </div>
                    ) : null
                  }
                  onFocus={() => this.fetchObjectsOnSearch('')}
                  onSearch={this.handleInputThrottled}
                  onSelect={(value, e) => this.handleValue(e.props.data)}
                >
                  {objects.map(object => (
                    <Option
                      key={object._id}
                      data={object}
                      value={object.name || ''}
                    >
                      {object.name}
                    </Option>
                  ))}
                </Select>
              )}

              {editingField === 'status' && (
                <Select
                  value={value || pickedTask[editingField] || undefined}
                  style={{ width: '100%' }}
                  onChange={value => this.handleValue(value)}
                >
                  {statusOptions.map(item => (
                    <Option key={item} value={item}>
                      {intl.formatMessage({
                        id: item
                      })}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <div className='buttons-cover'>
              <Button htmlType='button' onClick={this.handleCancelEdit}>
                <FormattedMessage id='cancel' />
              </Button>
              <Button key='ok' type='primary' onClick={this.handleSubmit}>
                Ok
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  }
}

FieldEdit.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  objects: PropTypes.array.isRequired,
  handleCancel: PropTypes.func,
  editingField: PropTypes.string,
  pickedTask: PropTypes.object,
  tasksData: PropTypes.object,
  workorderData: PropTypes.object,
  children: PropTypes.element,
  editing: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    pickedTask: state.tasks.pickedTask,
    tasksData: state.tasks.tasksData,
    workorderData: state.workorder.workorderData,
    objects: state.objects.searchedObjectsList
  }
}

export default injectIntl(connect(mapStateToProps)(FieldEdit))
