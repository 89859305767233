import { createReducer, createAction } from 'redux-act'

import { LoginAPI, UserAPI } from '../services'

import { notification } from 'antd'

export const REDUCER = 'login'
const NS = `@@${REDUCER}/`

export const setUserState = createAction(`${NS}SET_USER_STATE`)
export const setUserToken = createAction(`${NS}SET_RESET_TOKEN`)

const LoginApi = new LoginAPI()
const UserApi = new UserAPI()

export const initialState = {
  authUser: {},
  authToken: localStorage.getItem('authToken') || ''
}

export const fetchUser = () => (dispatch, getState) => {
  UserApi.getUser()
    .then(response => {
      dispatch(setUserState(response))
    })
    .catch(error => {
      if (error.response) {
        // dispatch(setLoginErrors(error.response.data.message))
      } else {
        notification.open({
          type: 'error',
          message: error.message
        })
      }
    })
}

export const login = (email, password) => (dispatch, getState) => {
  LoginApi.login({ email, password })
    .then(response => {
      localStorage.setItem('authToken', response.token)
      dispatch(setUserToken(response.token))
      dispatch(fetchUser())
    })
    .catch(error => {
      console.log(error)
      notification.open({
        type: 'error',
        message: 'Fehler',
        description: 'Die Zugangsdaten sind ungültig.'
      })
    })
}

export const logout = () => (dispatch, getState) => {
  localStorage.clear()
  dispatch(setUserState({}))
  dispatch(setUserToken(''))
}

export default createReducer(
  {
    [setUserToken]: (state, authToken) => ({ ...state, authToken }), // eslint-disable-line no-console
    [setUserState]: (state, authUser) => ({ ...state, authUser }) // eslint-disable-line no-console
  },
  initialState
)
