import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Button, notification, Form } from 'antd'

import WorkOrderForm from './WorkorderForm'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import {
  setPickedWorkorder,
  submitPickedWorkorder,
  deleteWorkOrder
} from '../../../../../ducks/workorder'

export class WorkorderDrawer extends React.Component {
  state = {
    submitting: false
  }

  handleClose = () => {
    const { dispatch } = this.props
    dispatch(setPickedWorkorder({}))
  }

  handleUpdateWorkOrder = async () => {
    const {
      dispatch,
      pickedWorkorder: { income },
      pickedWorkorder
    } = this.props
    this.setState({
      submitting: true
    })
    if (
      (!pickedWorkorder.income._id && !pickedWorkorder.value.name) ||
      (pickedWorkorder.income._id && pickedWorkorder.value.name === '')
    ) {
      dispatch(
        setPickedWorkorder({
          ...pickedWorkorder,
          errors: {
            name: true
          }
        })
      )
      this.setState({
        submitting: false
      })
      return
    }

    dispatch(submitPickedWorkorder()).then(response => {
      if (!response.error) {
        notification.success({
          message: `${this.props.intl.formatMessage({
            id: !income._id ? 'created successfully' : 'updated successfully'
          })}`
        })
        dispatch(setPickedWorkorder({}))
        setTimeout(() => this.setState({ submitting: false }), 1500)
      } else {
        this.setState({
          submitting: false
        })
      }
    })
  }

  handleDeleteWorkOrder = async () => {
    const { dispatch } = this.props
    dispatch(deleteWorkOrder()).then(response => {
      if (response.error) {
        notification.error({
          message: this.props.intl.formatMessage({
            id: 'deleting error'
          }),
          description: response.error.message
        })
      }
    })
  }

  render () {
    const { intl, pickedWorkorder } = this.props
    const { submitting } = this.state

    const footerDrawerButtons = [
      <Button key={1} onClick={this.handleClose} style={{ marginRight: 8 }}>
        <FormattedMessage id='cancel' />
      </Button>,
      <Button
        key={3}
        onClick={this.handleUpdateWorkOrder}
        type='primary'
        loading={submitting}
        disabled={submitting}
      >
        <FormattedMessage
          id={`${
            !(pickedWorkorder.income && pickedWorkorder.income._id)
              ? 'save'
              : 'update workorder'
          }`}
        />
      </Button>
    ]

    if (
      ['admin', 'superadmin'].includes(this.props.auth.authUser.scope[0]) &&
      pickedWorkorder.income &&
      pickedWorkorder.income._id
    ) {
      footerDrawerButtons.unshift(
        <PopconfirmDelete
          key={2}
          onConfirmAction={this.handleDeleteWorkOrder}
        />
      )
    }

    return (
      <>
        <DrawerComponent
          title={
            !(pickedWorkorder.income && pickedWorkorder.income._id)
              ? intl.formatMessage({
                  id: 'create a new workorder'
                })
              : intl.formatMessage({
                  id: 'edit workorder'
                })
          }
          onClose={this.handleClose}
          visible={Object.keys(pickedWorkorder).length > 0}
          footerButtons={footerDrawerButtons}
        >
          <WorkOrderForm />
        </DrawerComponent>
      </>
    )
  }
}

WorkorderDrawer.propTypes = {
  auth: PropTypes.object,
  pickedWorkorder: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  settings: state.settings,
  projects: state.project.projectList,
  pickedWorkorder: state.workorder.pickedWorkorder
})

export default injectIntl(
  Form.create({ name: 'work_orders_form' })(
    connect(mapStateToProps)(WorkorderDrawer)
  )
)
