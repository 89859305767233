import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { connect } from 'react-redux'
import { injectIntl, FormattedMessage } from 'react-intl'
import queryString from 'query-string'
import { withRouter } from 'react-router-dom'
import { Table, Icon, Menu, Dropdown } from 'antd'

import {
  fetchObjectTypeList,
  setPickedObjectType,
  setDrawerVisible
} from '../../../../../ducks/objectTypes'

export class RecordingsTable extends React.Component {
  state = {
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    params: {}
  }

  requestObjectTypes = async () => {
    const { dispatch } = this.props
    const params = await this.getParams()
    this.setState({
      loading: true
    })
    dispatch(
      fetchObjectTypeList({
        limit: this.state.defaultPageSize,
        ...params
      })
    ).then(data => {
      this.handleObjectTypeResponse(data)
    })
  }

  componentDidMount () {
    this.requestObjectTypes()
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch }
    } = this.props

    if (prevSearch !== nextSearch) {
      this.requestObjectTypes()
    }
  }

  getParams = async () => {
    const { history } = this.props
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }

      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'createdAt'
        searchQuery.sortOrder = 'descend'
      }
      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { history } = this.props

    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }
    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'createdAt' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    this.setState({ params: paramsObject })
    history.push({
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  handleObjectTypeResponse = async recordings => {
    const params = await this.getParams()
    const pagination = {
      ...this.state.pagination
    }
    pagination.total = recordings.totalDocs
    this.setState({
      loading: false,
      pagination: {
        ...pagination,
        pageSize: recordings.limit,
        current: recordings.page
      },
      params: { ...params }
    })
  }

  showDrawer = recording => {
    const { dispatch } = this.props
    dispatch(setPickedObjectType(recording))
    dispatch(setDrawerVisible(true))
  }

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    const stateParams = { ...this.state.params }
    pager.offset = pagination.current
    const params = {
      ...stateParams,
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    Object.keys(filters).forEach(key => {
      if (filters[key].length === 0) {
        delete params[key]
      }
      if (key === 'objectId') {
        params.objectId = [filters.objectId[0]._id]
      }
    })
    this.setParams(params)
  }

  render () {
    const { params, pagination, loading } = this.state
    const { intl, objectTypeList } = this.props

    const columns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        sorter: true
      },
      {
        title: intl.formatMessage({ id: 'form' }),
        key: 'form',
        dataIndex: 'form',
        sorter: true,
        render: form => (form && form.name) || ''
      },
      {
        title: intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
        defaultSortOrder:
          params.sortField === 'createdAt' ? params.sortOrder : 'descend'
      },
      {
        title: intl.formatMessage({ id: 'created by' }),
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: createdBy =>
          createdBy ? `${createdBy.firstName} ${createdBy.lastName}` : ''
      }
    ]

    columns.push({
      key: 'actions',
      render: (text, record) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-end'
          }}
        >
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key='0' onClick={() => this.showDrawer(record)}>
                  <Icon type={text.status === 'approved' ? 'eye' : 'edit'} />
                  {intl.formatMessage({
                    id: text.status === 'approved' ? 'view' : 'edit'
                  })}
                </Menu.Item>
                <Menu.Item
                  key='1'
                  // onClick={() => this.showPDF(record)}
                >
                  <Icon type='file-pdf' />
                  <FormattedMessage id='download template' />
                </Menu.Item>
                <Menu.Divider />
              </Menu>
            }
            trigger={['click']}
          >
            <a className='ant-dropdown-link' href='#'>
              <Icon type='more' style={{ fontSize: '2rem', color: '#444' }} />
            </a>
          </Dropdown>
        </div>
      )
    })

    return (
      <Table
        key={JSON.stringify(params)}
        ref={ref => (this._table = ref)}
        rowKey={record => record._id}
        loading={loading}
        columns={columns}
        dataSource={objectTypeList || []}
        onChange={this.handleTableChange}
        onRow={record => ({ onDoubleClick: () => this.showDrawer(record) })}
        defaultPageSize={pagination.defaultPageSize}
        pagination={pagination}
      />
    )
  }
}

RecordingsTable.propTypes = {
  objectTypeList: PropTypes.array,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  settings: state.settings,
  objectTypeList: state.objectType.objectTypeData.docs,
  objects: state.objects.searchedObjectsList
})

export default withRouter(
  injectIntl(connect(mapStateToProps, null)(RecordingsTable))
)
