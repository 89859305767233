import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Checkbox, Form, Input, Select, Tabs } from 'antd'

import useApi from '../../../hooks/useApi'
import projectService from '../../../services/project'
import PriceInput from '../../../components/PriceInput'
import categoriesService from '../../../services/categories'
import manufacturersService from '../../../services/manufacturers'

const { Item } = Form
const { TabPane } = Tabs
const { Option } = Select
const { TextArea } = Input

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  margin-top: 20px;

  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const StyledPriceInput = styled(PriceInput)`
  &.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 50px;
  }
`

const FormProduct = ({ form, product }) => {
  const intl = useIntl()
  const { getFieldDecorator } = form
  const [loadingProjects, fetchProjects] = useApi(projectService.listV2)
  const [loadingCategories, fetchCategories] = useApi(categoriesService.listV2)
  const [loadingManufacturers, fetchManufacturers] = useApi(
    manufacturersService.listV2
  )

  const {
    _id,
    name,
    ordernumber,
    description,
    ean,
    width,
    height,
    depth,
    supplierNumber,
    length,
    price,
    project,
    active,
    category,
    manufacturer
  } = product || {}

  _id && getFieldDecorator('_id', { initialValue: _id })

  const [projects, setProjects] = useState([])
  const [categories, setCategories] = useState([])
  const [manufacturers, setManufacturers] = useState([])
  const selectedProject = useSelector(
    ({ project: { pickedProject } }) => pickedProject
  )

  const loadProjects = async (value = '') => {
    if (!!selectedProject._id && !project) {
      return setProjects([selectedProject])
    }

    const { docs = [] } = await fetchProjects({
      projectName: [value],
      limit: 100
    })

    if (project && !docs.some(({ _id }) => _id === project._id)) {
      docs.push(project)
    }

    setProjects(docs)
  }

  const loadCategories = async (value = '') => {
    const { docs = [] } = await fetchCategories({
      name: [value],
      limit: 100
    })

    if (category && !docs.some(({ _id }) => _id === category._id)) {
      docs.push(category)
    }

    setCategories(docs)
  }

  const loadManufacturers = async (value = '') => {
    const { docs = [] } = await fetchManufacturers({
      name: [value],
      limit: 100
    })

    if (manufacturer && !docs.some(({ _id }) => _id === manufacturer._id)) {
      docs.push(manufacturer)
    }

    setManufacturers(docs)
  }

  useEffect(() => {
    loadProjects()
    loadCategories()
    loadManufacturers()
  }, [])

  return (
    <Tabs defaultActiveKey='informations'>
      <TabPane
        tab={intl.formatMessage({ id: 'informations' })}
        key='informations'
      >
        <StyledForm {...formLayout}>
          <Item label={intl.formatMessage({ id: 'name' })}>
            {getFieldDecorator('name', {
              initialValue: name,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'name is required'
                  })
                }
              ]
            })(<Input />)}
          </Item>
          <Item label={intl.formatMessage({ id: 'project' })}>
            {getFieldDecorator('project', {
              initialValue: project ? project._id : selectedProject._id,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'project is required'
                  })
                }
              ]
            })(
              <Select
                showSearch
                allowClear
                filterOption={false}
                loading={loadingProjects}
                style={{ width: '100%' }}
                placeholder={intl.formatMessage({
                  id: 'choose a project'
                })}
                onSearch={debounce(loadProjects, 500)}
                disabled={!!selectedProject._id && !project}
              >
                {projects.map(({ _id, projectName }) => (
                  <Option key={_id}>{projectName}</Option>
                ))}
              </Select>
            )}
          </Item>
          <Item label={intl.formatMessage({ id: 'ordernumber' })}>
            {getFieldDecorator('ordernumber', {
              initialValue: ordernumber,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'ordernumber is required'
                  })
                }
              ]
            })(<Input />)}
          </Item>
          <Item label={intl.formatMessage({ id: 'description' })}>
            {getFieldDecorator('description', { initialValue: description })(
              <TextArea />
            )}
          </Item>
          <Item label={intl.formatMessage({ id: 'manufacturer' })}>
            {getFieldDecorator('manufacturer', {
              initialValue: manufacturer && manufacturer._id,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'manufacturer is required'
                  })
                }
              ]
            })(
              <Select
                showSearch
                allowClear
                filterOption={false}
                style={{ width: '100%' }}
                loading={loadingManufacturers}
                placeholder={intl.formatMessage({
                  id: 'choose a manufacturer'
                })}
                onSearch={debounce(loadManufacturers, 500)}
              >
                {manufacturers.map(({ _id, name }) => (
                  <Option key={_id}>{name}</Option>
                ))}
              </Select>
            )}
          </Item>
          <Item label={intl.formatMessage({ id: 'category' })}>
            {getFieldDecorator('category', {
              initialValue: category && category._id,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'category is required'
                  })
                }
              ]
            })(
              <Select
                showSearch
                allowClear
                filterOption={false}
                style={{ width: '100%' }}
                loading={loadingCategories}
                placeholder={intl.formatMessage({
                  id: 'choose a category'
                })}
                onSearch={debounce(loadCategories, 500)}
              >
                {categories.map(({ _id, name }) => (
                  <Option key={_id}>{name}</Option>
                ))}
              </Select>
            )}
          </Item>
          <Item label={intl.formatMessage({ id: 'price' })}>
            {getFieldDecorator('price', {
              initialValue: price,
              rules: [
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'price is required'
                  })
                }
              ]
            })(<StyledPriceInput prefix='CHF' />)}
          </Item>
          <Item label={intl.formatMessage({ id: 'active' })}>
            {getFieldDecorator('active', {
              initialValue: !!active,
              valuePropName: 'checked'
            })(<Checkbox />)}
          </Item>
        </StyledForm>
      </TabPane>
      <TabPane tab={intl.formatMessage({ id: 'settings' })} key='settings'>
        <StyledForm {...formLayout}>
          <Item label='EAN'>
            {getFieldDecorator('ean', { initialValue: ean })(<Input />)}
          </Item>
          <Item label={intl.formatMessage({ id: 'width' })}>
            {getFieldDecorator('width', { initialValue: width })(
              <PriceInput />
            )}
          </Item>
          <Item label={intl.formatMessage({ id: 'height' })}>
            {getFieldDecorator('height', { initialValue: height })(
              <PriceInput />
            )}
          </Item>
          <Item label={intl.formatMessage({ id: 'depth' })}>
            {getFieldDecorator('depth', { initialValue: depth })(
              <PriceInput />
            )}
          </Item>
          <Item label={intl.formatMessage({ id: 'supplier number' })}>
            {getFieldDecorator('supplierNumber', {
              initialValue: supplierNumber
            })(<Input />)}
          </Item>
          <Item label={intl.formatMessage({ id: 'length' })}>
            {getFieldDecorator('length', { initialValue: length })(
              <PriceInput />
            )}
          </Item>
        </StyledForm>
      </TabPane>
    </Tabs>
  )
}

FormProduct.propTypes = {
  product: PropTypes.object,
  form: PropTypes.object.isRequired
}

export default Form.create({ name: 'form_product' })(FormProduct)
