import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { UserAPI } from '../services'

export const REDUCER = 'users'
const NS = `@@${REDUCER}/`

export const setUsersData = createAction(`${NS}SET_USER_DATA`)
export const setSearchedUsersList = createAction(`${NS}SET_SEARCHED_USER_LIST`)

const UserApi = new UserAPI()

export const initialState = {
  usersData: {},
  searchedUsersList: []
}

export const fetchUsers = (params, search = false) => (dispatch, getState) => {
  return UserApi.listV2(params)
    .then(response => {
      if (search) {
        dispatch(setSearchedUsersList(response.docs))
      } else {
        dispatch(setUsersData(response))
      }
      return true
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return false
    })
}

export default createReducer(
  {
    [setUsersData]: (state, usersData) => ({ ...state, usersData }),
    [setSearchedUsersList]: (state, searchedUsersList) => ({
      ...state,
      searchedUsersList
    })
  },
  initialState
)
