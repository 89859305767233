import axios from '../../helpers/axios'
import config from 'config'
import HttpClient from './index'

class RecordingsAPI extends HttpClient {
  async list (params = {}) {
    return axios.get('/recording', params)
  }

  submit (recordingID) {
    return axios.post(`/recording/${recordingID}/submit`)
  }

  async archive (recordingID) {
    const params = {
      archived: true
    }
    return axios.put(`/recording/${recordingID}`, params)
  }

  async unarchive (recordingID) {
    const params = {
      archived: false
    }
    return axios.put(`/recording/${recordingID}`, params)
  }

  async createWorkorder (recordingID) {
    return axios.post(`/recording/${recordingID}/create-workorder`)
  }

  async createTask (recordingID) {
    return axios.post(`/recording/${recordingID}/create-task`)
  }

  createDefect (recordingID, params) {
    return axios.post(`/recording/${recordingID}/create-defect`, params)
  }

  async updateImage (id, data) {
    return axios.put(`/recording-image/${id}`, data)
  }

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/recording', params)
  }

  async save (id, params) {
    return axios.put(`/recording/${id}`, params)
  }

  async delete (id) {
    return axios.delete(`/recording/${id}`)
  }
}

export default RecordingsAPI
