import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Table, Icon, Button } from 'antd'
import { useIntl, FormattedMessage } from 'react-intl'

import useApi from '../../../hooks/useApi'
import ProductManagement from './ProductManagement'
import searchColumn from '../../../utils/searchColumn'
import PriceInput from '../../../components/PriceInput'
import productService from '../../../services/products'
import usePagination from '../../../hooks/usePagination'
import PageTitle from '../../../components/Global/PageTitle'

const ProductsPage = () => {
  const intl = useIntl()
  const input = useRef()
  const [product, setProduct] = useState()
  const [data, setData] = useState({})
  const [loading, fetchProducts] = useApi(productService.listV2)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const selectedProject = useSelector(
    ({ project: { pickedProject } }) => pickedProject
  )
  const {
    limit,
    offset,
    sortOrder,
    sortField,
    filters,
    onChange
  } = usePagination({
    sortOrder: 'descend',
    sortField: 'createdAt'
  })

  const loadData = async () => {
    const response = await fetchProducts({
      limit,
      offset,
      sortOrder,
      sortField,
      ...filters
    })
    setData(response)
  }

  const handleOpen = product => {
    setProduct(product)
    setDrawerVisible(true)
  }

  const handleClose = () => {
    setProduct()
    setDrawerVisible(false)
  }

  const handleSubmit = () => {
    handleClose()
    return loadData()
  }

  useEffect(() => {
    !loading && loadData()
  }, [limit, offset, sortOrder, sortField, filters])

  const pagination = {
    total: data.totalDocs || 0,
    current: offset,
    pageSize: limit,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '50', '100']
  }

  const {
    filterOptions: {
      category: categoryFilters = [],
      createdBy: createdByFilters = [],
      manufacturer: manufacturerFilters = []
    } = {}
  } = data

  const columns = [
    {
      title: intl.formatMessage({ id: 'name' }),
      dataIndex: 'name',
      sorter: true,
      sortOrder: sortField === 'name' && sortOrder,
      ...searchColumn({ input, intl, value: filters.name })
    },
    {
      title: intl.formatMessage({ id: 'ordernumber' }),
      dataIndex: 'ordernumber'
    },
    {
      title: intl.formatMessage({ id: 'manufacturer' }),
      key: 'manufacturer',
      dataIndex: 'manufacturer.name',
      filterMultiple: false,
      filters: manufacturerFilters.map(({ _id, name }) => ({
        text: name,
        value: _id
      })),
      filteredValue: filters.manufacturer || []
    },
    {
      title: intl.formatMessage({ id: 'category' }),
      key: 'category',
      dataIndex: 'category.name',
      filters: categoryFilters.map(({ _id, name }) => ({
        text: name,
        value: _id
      })),
      sorter: true,
      filteredValue: filters.category || [],
      sortOrder: sortField === 'category' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'active' }),
      dataIndex: 'active',
      render: active =>
        active && <Icon type='check' style={{ color: '#005591' }} />
    },
    {
      title: intl.formatMessage({ id: 'price' }),
      dataIndex: 'price',
      render: price => {
        const parsedValue = PriceInput.toFixed(price, 2)
        return <div style={{ width: 135 }}>{`CHF ${parsedValue}`}</div>
      },
      sorter: true,
      sortOrder: sortField === 'price' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created at' }),
      dataIndex: 'createdAt',
      render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm'),
      sorter: true,
      sortOrder: sortField === 'createdAt' && sortOrder
    },
    {
      title: intl.formatMessage({ id: 'created by' }),
      dataIndex: 'createdBy',
      render: value => {
        const { firstName = '', lastName = '' } = value || {}
        return `${firstName} ${lastName}`
      },
      sorter: true,
      filters: createdByFilters.map(user => {
        const { _id, firstName = '', lastName = '' } = user
        return {
          text: `${firstName} ${lastName}`,
          value: _id
        }
      }),
      filteredValue: filters.createdBy || [],
      sortOrder: sortField === 'createdBy' && sortOrder
    }
  ]

  if (!selectedProject._id) {
    columns.push({
      title: intl.formatMessage({ id: 'project' }),
      key: 'project',
      dataIndex: 'project.projectName'
    })
  }

  columns.push({
    width: 65,
    align: 'right',
    key: 'actions',
    render: record => (
      <Button onClick={() => handleOpen(record)}>
        <Icon type='edit' />
      </Button>
    )
  })

  const dataSource = data.docs || []

  return (
    <div>
      <FormattedMessage id='head.title.products'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({ id: 'products' })}
        buttons={[
          <Button
            type='primary'
            key='create_product'
            onClick={() => handleOpen()}
          >
            <FormattedMessage id='create product' />
          </Button>
        ]}
      />

      <Table
        rowKey='_id'
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={onChange}
        onRow={record => ({
          onDoubleClick: () => handleOpen(record)
        })}
        pagination={pagination}
      />

      <ProductManagement
        product={product}
        visible={drawerVisible}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </div>
  )
}

export default ProductsPage
