import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { Form, Select, Spin } from 'antd'

import { fetchUsers } from '../../../../../../../../../ducks/users'
import { updateWorkorderField } from '../../../../../../../../../ducks/workorder'
import '../../../styles.scss'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  className: 'formItem'
}

const { Option } = Select

class ExecutedBy extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
    this.handleInputThrottled = debounce(this.fetchDataOnSearch, 300)
  }

  fetchDataOnSearch = text => {
    const { dispatch } = this.props
    this.setState({
      loading: true
    })
    dispatch(fetchUsers({ 'fullName[]': text }, true)).then(response => {
      if (response) {
        this.setState({
          loading: false
        })
      }
    })
  }

  assignUserHandler = value => {
    const { dispatch } = this.props
    dispatch(updateWorkorderField({ executedBy: value }))
  }

  render () {
    const { workorder, intl, users } = this.props
    const { loading } = this.state
    let selectValue
    if (workorder.value.executedBy) {
      selectValue = `${workorder.value.executedBy.fullName} ( ${workorder.value.executedBy.email} )`
    } else if (workorder.income.executedBy) {
      selectValue = `${workorder.income.executedBy.fullName} ( ${workorder.income.executedBy.email} )`
    } else {
      selectValue = undefined
    }
    return (
      <>
        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'assignee'
          })}
        >
          <Select
            showSearch
            allowClear
            placeholder={intl.formatMessage({
              id: 'select user'
            })}
            value={selectValue}
            style={{ width: '100%' }}
            optionFilterProp='children'
            notFoundContent={
              loading ? (
                <div className='workorder-spinner-container'>
                  <Spin size='small' />
                </div>
              ) : null
            }
            onSearch={this.handleInputThrottled}
            onChange={(value, e) =>
              this.assignUserHandler(e ? e.props.data : null)
            }
            onFocus={() => this.fetchDataOnSearch('')}
            filterOption={(input, option) => {
              const cuttedValue = option.props.value.substring(
                0,
                option.props.value.indexOf('(')
              )
              return cuttedValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}
            disabled={workorder.income.archived}
          >
            {users.map((user, i) => (
              <Option
                key={i}
                data={user}
                value={`${user.fullName} ( ${user.email} )`}
              >
                {`${user.fullName} (${user.email})`}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </>
    )
  }
}

ExecutedBy.propTypes = {
  intl: PropTypes.object.isRequired,
  users: PropTypes.array,
  workorder: PropTypes.object,
  dispatch: PropTypes.func
}

const mapStateToProps = state => {
  return {
    workorder: state.workorder.pickedWorkorder,
    users: state.users.searchedUsersList
  }
}
export default injectIntl(connect(mapStateToProps)(ExecutedBy))
