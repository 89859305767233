import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { SpecialWorkAPI } from '../services'

export const REDUCER = 'stats'
const NS = `@@${REDUCER}/`

export const setSpecialWorkData = createAction(`${NS}SET_SPECIAL_WORK_DATA`)
export const setSearchedSpecialWork = createAction(
  `${NS}SET_SEARCHED_SPECIAL_WORK`
)

const SpecialWorkApi = new SpecialWorkAPI()

export const initialState = {
  specialWorkData: {},
  searchedSpecialWork: []
}

export const fetchSpecialWork = (params, search = false) => (
  dispatch,
  getState
) => {
  const pickedProject = getState().project.pickedProject._id
  if (pickedProject) {
    params.project = pickedProject
  }
  return SpecialWorkApi.listV2(params)
    .then(response => {
      if (search) {
        dispatch(setSearchedSpecialWork(response.docs))
      } else {
        dispatch(setSpecialWorkData(response))
      }
      return true
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return false
    })
}

export default createReducer(
  {
    [setSpecialWorkData]: (state, specialWorkData) => ({
      ...state,
      specialWorkData
    }),
    [setSearchedSpecialWork]: (state, searchedSpecialWork) => ({
      ...state,
      searchedSpecialWork
    })
  },
  initialState
)
