import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Form, Input, Select, Spin, InputNumber } from 'antd'
import isEqual from 'lodash/isEqual'

import { fetchObjectsList } from '../../../ducks/object'
import PropTypes from 'prop-types'

import './styles.scss'

const { Option } = Select
const formItemLayout = {
  className: 'form-item-cover'
}

const groupData = [
  'Kabel',
  'Leittechnik',
  'Materialschaden / falsches Material',
  'falsche Montage',
  'Beschriftung',
  'Brandschottung',
  'Doppelboden',
  'Reinigung',
  'Dokumentation',
  'Schema',
  'Schäden',
  'No-Break',
  'Andere',
  'Zu klären / fertigstellen',
  'Altlasten',
  'Baufehler'
]

class SpecificFields extends React.Component {
  state = {
    objects: [],
    projects: [],
    contractSections: [],
    contacts: []
  }

  fetchData = async (value, key) => {
    this.setState({
      formLoading: true
    })
    const { dispatch } = this.props
    const timer = setTimeout(async () => {
      try {
        await dispatch(fetchObjectsList({ 'name[]': value, limit: 20 }, true))
        this.setState({
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          [key]: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { data, form } = this.props

    if (!isEqual(data, prevProps.data)) {
      form.resetFields()
    }
  }

  render () {
    const {
      updateStateDefect,
      data,
      intl,
      errors,
      objectName,
      objects
    } = this.props
    const { formLoading } = this.state
    const { getFieldDecorator } = this.props.form
    return (
      <Form className='form-cover'>
        <p style={{ width: '100%', marginBottom: 0, marginTop: 16 }}>
          <b>{intl.formatMessage({ id: 'specific data' })}</b>
        </p>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'name' })}
          validateStatus={errors.name ? 'error' : null}
          help={errors.name ? intl.formatMessage({ id: 'is required' }) : null}
        >
          {getFieldDecorator('name')(
            <Input
              onChange={event => updateStateDefect('name', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={objectName}
          validateStatus={errors.objectId ? 'error' : null}
          help={
            errors.objectId ? intl.formatMessage({ id: 'is required' }) : null
          }
        >
          {getFieldDecorator('objectId', {
            initialValue: data.objectId
              ? data.objectId.projectName || data.objectId.name
              : ''
          })(
            <Select
              showSearch
              style={{ width: '100%' }}
              optionFilterProp='children'
              notFoundContent={
                formLoading ? (
                  <div className='objects-spinner-container'>
                    <Spin size='small' />
                  </div>
                ) : null
              }
              onFocus={() => this.fetchData('', 'objects')}
              onSearch={value => this.fetchData(value, 'objects')}
              onChange={objectId => updateStateDefect('objectId', objectId)}
            >
              {objects.map(object => (
                <Option key={object._id} value={object._id}>
                  {object.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'estimated_cost'
          })}
        >
          {getFieldDecorator('estimated_cost', {
            initialValue: data.estimated_cost || ''
          })(
            <InputNumber
              onChange={value => updateStateDefect('estimated_cost', value)}
              formatter={
                value => `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '') // eslint-disable-line no-useless-escape
              }
              style={{ width: '100%' }}
              parser={value => value.replace(' ', '').match(/[0-9 , \.]+/g)} // eslint-disable-line no-useless-escape
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'group'
          })}
        >
          {getFieldDecorator(
            'group',
            {}
          )(
            <Select onChange={group => updateStateDefect('group', group)}>
              {groupData.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'actualSituation'
          })}
        >
          {getFieldDecorator('actualSituation', {
            initialValue:
              (data.attributes && data.attributes.actualStatus) ||
              data.actualSituation ||
              ''
          })(
            <Input.TextArea
              onChange={event =>
                updateStateDefect('actualSituation', event.target.value)
              }
            />
          )}
        </Form.Item>
      </Form>
    )
  }
}

SpecificFields.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  form: PropTypes.object,
  intl: PropTypes.object.isRequired,
  contractSections: PropTypes.array.isRequired,
  objects: PropTypes.array.isRequired,
  defectData: PropTypes.object,
  projects: PropTypes.array,
  objectName: PropTypes.string,
  errors: PropTypes.object,
  updateStateDefect: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    defectData: state.recordings.recordingDefectCreationData,
    projects: state.project.projectList,
    objectName: state.settings.data.buildx.objectName,
    objects: state.objects.searchedObjectsList,
    contractSections: state.contractSections.searchedContractSections
  }
}
export default Form.create()(
  injectIntl(connect(mapStateToProps)(SpecificFields))
)
