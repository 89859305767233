import React from 'react'
import ObjectTypeTable from './components/ObjectTypeTable'
import PageHeader from './components/PageHeader'
import ObjectTypeDrawer from './components/ObjectTypeDrawer'

const ObjectTypePage = () => {
  return (
    <>
      <PageHeader />
      <ObjectTypeTable />
      <ObjectTypeDrawer />
    </>
  )
}

export default ObjectTypePage
