import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import HeaderRadioButtons from './HeaderRadioButtons'
import { Form, Input, Select, Spin } from 'antd'
import { fetchContacts } from '../../../../ducks/contacts'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'

const { Option } = Select

class ContactForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      formType: null,
      formLoading: false,
      contacts: [],
      loading: false
    }
    this.handleInputThrottled = debounce(this.fetchContactsOnSearch, 300)
  }

  onFormTypeChange = formType => {
    const { updateContact } = this.props
    this.setState({
      formType
    })
    updateContact('contact_type', formType === 'person' ? 'user' : formType)
  }

  fetchContactsOnSearch = async searchQueue => {
    const { dispatch } = this.props
    this.setState()
    dispatch(
      fetchContacts(
        {
          'company[]': searchQueue,
          'contact_type[]': 'company',
          limit: 1000,
          sortField: 'company',
          sortOrder: 'ascend'
        },
        true
      )
    ).then(() => {
      this.setState({
        loading: false
      })
    })
  }

  handlePick = (value, dataArray) => {
    const { contact, updateContact } = this.props
    const outputArray = (contact.assignedToCompany || []).filter(
      (connection, index) => {
        return dataArray.some(element => {
          return element.props.data._id === connection.contact_sup._id
        })
      }
    )
    dataArray.forEach(element => {
      if (
        !outputArray.some(
          connection => element.props.data._id === connection.contact_sup._id
        )
      ) {
        outputArray.push({
          contact_sup: element.props.data,
          contact_sub: contact
        })
      }
    })
    updateContact('assignedToCompany', outputArray)
  }

  render () {
    const {
      form: { getFieldDecorator },
      intl,
      contact,
      updateContact,
      action,
      subDrawer,
      contacts
    } = this.props

    const { formType, loading } = this.state
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <div>
        {action === 'create' && !subDrawer && (
          <HeaderRadioButtons handleFormType={this.onFormTypeChange} />
        )}

        <Form onSubmit={() => {}} className='contactForm'>
          {(formType === 'company' ||
            (action === 'edit' && contact.contact_type === 'company') ||
            (action === 'edit' && contact.company)) && (
            <>
              <Form.Item
                {...formItemLayout}
                label={
                  <span>
                    {intl.formatMessage({ id: 'name' })}
                    <span style={{ color: '#005591' }}>*</span>
                  </span>
                }
                required={false}
              >
                {getFieldDecorator('company', {
                  initialValue: contact.company,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'company is required'
                      })
                    }
                  ]
                })(
                  <Input
                    onChange={event =>
                      updateContact('company', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'street' })}
              >
                {getFieldDecorator('street', {
                  initialValue: contact.street
                })(
                  <Input
                    onChange={event =>
                      updateContact('street', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'zipcode' })}
              >
                {getFieldDecorator('postcode', {
                  initialValue: contact.postcode
                })(
                  <Input
                    onChange={event =>
                      updateContact('postcode', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'city' })}
              >
                {getFieldDecorator('city', {
                  initialValue: contact.city
                })(
                  <Input
                    onChange={event =>
                      updateContact('city', event.target.value)
                    }
                  />
                )}
              </Form.Item>
            </>
          )}

          {(formType === 'person' ||
            (action === 'edit' && contact.contact_type === 'user') ||
            subDrawer) && (
            <>
              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'first name' })}
              >
                {getFieldDecorator('firstName', {
                  initialValue: contact.firstName,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'field is required'
                      })
                    }
                  ]
                })(
                  <Input
                    onChange={event =>
                      updateContact('firstName', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'last name' })}
              >
                {getFieldDecorator('lastName', {
                  initialValue: contact.lastName,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'field is required'
                      })
                    }
                  ]
                })(
                  <Input
                    onChange={event =>
                      updateContact('lastName', event.target.value)
                    }
                  />
                )}
              </Form.Item>
            </>
          )}

          {(formType !== null || action === 'edit' || subDrawer) && (
            <>
              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'email' })}
              >
                {getFieldDecorator('email', {
                  initialValue: contact.email,
                  rules: [
                    {
                      required: true,
                      message: intl.formatMessage({
                        id: 'email is required'
                      })
                    }
                  ]
                })(
                  <Input
                    onChange={event =>
                      updateContact('email', event.target.value)
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                {...formItemLayout}
                label={intl.formatMessage({ id: 'phone' })}
              >
                {getFieldDecorator('phone', {
                  initialValue: contact.phone
                })(
                  <Input
                    onChange={event =>
                      updateContact('phone', event.target.value)
                    }
                  />
                )}
              </Form.Item>
            </>
          )}

          {(formType === 'company' ||
            (action === 'edit' && contact.contact_type === 'company') ||
            (action === 'edit' && contact.company)) && (
            <Form.Item
              {...formItemLayout}
              label={intl.formatMessage({ id: 'website' })}
            >
              {getFieldDecorator('website', {
                initialValue: contact.website
              })(
                <Input
                  onChange={event =>
                    updateContact('website', event.target.value)
                  }
                />
              )}
            </Form.Item>
          )}
          {/* {formType === 'person' || (action === 'edit' && contact.contact_type !== 'company')  && ( */}

          {formType === 'person' && action !== 'edit' && (
            <Form.Item
              {...formItemLayout}
              label={intl.formatMessage({
                id: 'company'
              })}
            >
              <Select
                showSearch
                value={
                  (contact.assignedToCompany &&
                    contact.assignedToCompany.contact_sup &&
                    contact.assignedToCompany.contact_sup.company) ||
                  ''
                }
                onSelect={(data, e) =>
                  updateContact('assignedToCompany', {
                    contact_sup: e.props.data,
                    contact_sub: contact
                  })
                }
                onFocus={() => this.fetchContactsOnSearch('')}
                onSearch={this.handleInputThrottled}
                notFoundContent={
                  loading ? (
                    <div className='workorder-spinner-container'>
                      <Spin size='small' />
                    </div>
                  ) : null
                }
                filterOption={false}
              >
                {(contacts || []).map(item => (
                  <Option key={item._id} data={item} value={item.company}>
                    {item.company}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {contact.contact_type !== 'company' && action === 'edit' && (
            <Form.Item
              {...formItemLayout}
              label={intl.formatMessage({
                id: 'company'
              })}
            >
              {getFieldDecorator('assignedToCompany', {
                initialValue:
                  (contact.assignedToCompany &&
                    contact.assignedToCompany.map(
                      item => item.contact_sup && item.contact_sup.company
                    )) ||
                  []
              })(
                <Select
                  mode='multiple'
                  showSearch
                  allowClear
                  style={{ width: '100%' }}
                  notFoundContent={
                    loading && (
                      <div className='project-spinner-container'>
                        <Spin size='small' />
                      </div>
                    )
                  }
                  placeholder={intl.formatMessage({ id: 'select field' })}
                  optionFilterProp='children'
                  onFocus={() => this.fetchContactsOnSearch('')}
                  onSearch={this.handleInputThrottled}
                  onChange={(data, event) => this.handlePick(data, event)}
                >
                  {(contacts || []).map(contact => {
                    return (
                      <Option
                        key={contact._id}
                        data={{ ...contact }}
                        value={contact && contact.company}
                      >
                        {contact && contact.company}
                      </Option>
                    )
                  })}
                </Select>
              )}
            </Form.Item>
          )}
        </Form>
      </div>
    )
  }
}

ContactForm.propTypes = {
  contact: PropTypes.object.isRequired,
  updateContact: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  contacts: PropTypes.array.isRequired,
  action: PropTypes.string,
  subDrawer: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  projects: state.project.searchedProjectsList,
  objects: state.objects.searchedObjectsList,
  contacts: state.contacts.searchedContacts
})

export default connect(mapStateToProps, null)(injectIntl(ContactForm))
