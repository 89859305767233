import React from 'react'
import { Button, Dropdown, Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import actions from 'actions'
import { setLanguage } from '../../ducks/settings'

const DefaultLayout = props => (
  <Layout style={{ minHeight: '100vh' }}>
    <Helmet titleTemplate='%s | ddSuite'>
      <title>App</title>
    </Helmet>
    <Layout.Header
      style={{
        background: '#fff'
      }}
    >
      <img
        src='/images/ddSuite-icon.svg'
        alt='logo'
        style={{
          height: 30
        }}
      />

      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => props.dispatch(setLanguage('en'))}>
              <span
                className='nav-text'
                style={{
                  fontWeight: props.settings.lang === 'en' ? 'bold' : 'normal'
                }}
              >
                EN
              </span>
            </Menu.Item>

            <Menu.Item onClick={() => props.dispatch(setLanguage('de'))}>
              <span
                className='nav-text'
                style={{
                  fontWeight: props.settings.lang === 'en' ? 'normal' : 'bold'
                }}
              >
                DE
              </span>
            </Menu.Item>
          </Menu>
        }
        placement='bottomCenter'
        trigger={['click']}
      >
        <Button
          shape='circle'
          icon='global'
          style={{ float: 'right', marginTop: 15, marginLeft: 10 }}
        />
      </Dropdown>

      {!localStorage.getItem('token') ? (
        <Link to='/'>
          <Button
            type='primary'
            shape='circle'
            icon='login'
            style={{ float: 'right', marginTop: 15 }}
          />
        </Link>
      ) : (
        <Button
          type='danger'
          shape='circle'
          icon='poweroff'
          style={{ float: 'right', marginTop: 15 }}
          onClick={() => props.dispatch(actions.authLogout())}
        />
      )}
    </Layout.Header>

    <Layout.Content>{props.children}</Layout.Content>
  </Layout>
)

DefaultLayout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired
}

const mapStateToProps = state => ({
  settings: state.settings
})

export default connect(mapStateToProps)(DefaultLayout)
