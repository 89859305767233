import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { notification } from 'antd'

import RecordingForm from '../../components/RecordingForm'
import Button from 'components/Global/Button'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

import DrawerComponent from 'components/Drawer'

import {
  fetchRecordings,
  handleRecordingPick,
  setPickedRecording,
  submitPickedRecording,
  deleteRecording
} from '../../ducks/recordings'
import { fetchAcceptance } from '../../ducks/acceptance'

export class RecordingsDrawer extends React.Component {
  handleHideDrawer = () => {
    const { dispatch } = this.props
    dispatch(handleRecordingPick({}))
  }

  handleSaveRecording = async () => {
    const { dispatch, acceptance } = this.props

    dispatch(submitPickedRecording()).then(response => {
      if (response) {
        if (acceptance) {
          dispatch(fetchAcceptance())
        } else {
          dispatch(fetchRecordings())
        }
        notification.success({
          message: `${this.props.intl.formatMessage({
            id: 'updated successfully'
          })}`
        })
        dispatch(setPickedRecording({}))
      }
    })
  }

  handleDeleteRecording = async () => {
    const { dispatch } = this.props
    dispatch(deleteRecording()).then(response => {
      if (response.error) {
        notification.error({
          message: this.props.intl.formatMessage({
            id: 'deleting error'
          }),
          description: response.message
        })
      }
    })
  }

  render () {
    const { intl, pickedRecording, readOnly, acceptance } = this.props
    const footerDrawerButtons = [
      <React.Fragment key={1}>
        <Button
          onClick={this.handleHideDrawer}
          style={{ marginRight: 8 }}
          value={<FormattedMessage id='cancel' />}
        />

        <Button
          onClick={this.handleSaveRecording}
          type='primary'
          value={<FormattedMessage id='update recording' />}
        />
      </React.Fragment>
    ]

    if (['admin', 'superadmin'].includes(this.props.auth.authUser.scope[0])) {
      footerDrawerButtons.unshift(
        <PopconfirmDelete
          key={2}
          onConfirmAction={this.handleDeleteRecording}
        />
      )
    }
    return (
      <DrawerComponent
        title={
          readOnly === true
            ? intl.formatMessage({
                id: 'recording details'
              })
            : this.props.intl.formatMessage({
                id: 'edit recording'
              })
        }
        onClose={this.handleHideDrawer}
        visible={Object.keys(pickedRecording).length > 0}
        footerButtons={footerDrawerButtons}
      >
        <RecordingForm acceptance={acceptance} />
      </DrawerComponent>
    )
  }
}

RecordingsDrawer.propTypes = {
  auth: PropTypes.object,
  pickedRecording: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  acceptance: PropTypes.bool,
  readOnly: PropTypes.bool
}

const mapStateToProps = state => ({
  auth: state.auth,
  pickedRecording: state.recordings.pickedRecording,
  readOnly: state.recordings.pickedReadOnly
})

export default injectIntl(connect(mapStateToProps, null)(RecordingsDrawer))
