import config from 'config'
import axios from '../helpers/axios'

export default {
  async list (params = {}) {
    return axios.get('/workorder', params)
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/workorder', params)
  },
  async addWorkOrder (data) {
    const response = await axios.post('/workorder', data)
    return response
  },
  async updateWorkOrder (id, data) {
    const response = await axios.put(`/workorder/${id}`, data)
    return response
  },
  async deleteWorkOrder (id) {
    const response = await axios.delete(`/workorder/${id}`)
    return response
  },
  async addImage (id, data) {
    const response = await axios.post(`/workorder/${id}/image`, data)
    return response
  },

  async addFile (id, data) {
    const response = await axios.post(`/workorder/${id}/file`, data)
    return response
  },

  async addComment (id, data) {
    const response = await axios.post(`/workorder/${id}/comment`, data)
    return response
  }
}
