import { createReducer, createAction } from 'redux-act'

import { notification } from 'antd'
import { FieldDefinitionAPI } from '../services'
export const REDUCER = 'field'
const NS = `@@${REDUCER}/`

export const setFieldsData = createAction(`${NS}SET_FIELDS_DATA`)
export const setPickedField = createAction(`${NS}SET_PICKED_FIELD`)
export const setSearchedFields = createAction(`${NS}SET_SEARCHED_FIELDS_LIST`)
export const setDrawerVisible = createAction(`${NS}SET_DRAWER_VISIBLE`)

const FieldDefinitionApi = new FieldDefinitionAPI()

export const initialState = {
  fieldsData: {},
  searchedFieldsList: [],
  pickedField: {},
  drawerVisible: false
}

export const fetchFieldList = (params, search = false) => (
  dispatch,
  getState
) => {
  return FieldDefinitionApi.listV2(params)
    .then(response => {
      if (search) {
        dispatch(setSearchedFields(response.docs))
      } else {
        dispatch(setFieldsData(response))
      }
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return false
    })
}

export const submitPickedField = () => (dispatch, getState) => {
  const data = getState().fields.pickedField
  return FieldDefinitionApi.save(data)
    .then(response => {
      return response
    })
    .catch(error => {
      return error
    })
}

export const deletePickedField = () => (dispatch, getState) => {
  const data = getState().fields.pickedField
  return FieldDefinitionApi.delete(data._id)
    .then(response => {
      return response
    })
    .catch(error => {
      return error
    })
}

export const updatePickedField = data => (dispatch, getState) => {
  const currentState = { ...getState().fields.pickedField }
  dispatch(setPickedField({ ...currentState, ...data }))
}

export default createReducer(
  {
    [setFieldsData]: (state, fieldsData) => ({
      ...state,
      fieldsData
    }),
    [setDrawerVisible]: (state, drawerVisible) => ({ ...state, drawerVisible }),
    [setPickedField]: (state, pickedField) => ({
      ...state,
      pickedField
    }),
    [setSearchedFields]: (state, searchedFieldsList) => ({
      ...state,
      searchedFieldsList
    })
  },
  initialState
)
