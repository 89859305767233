import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { setDrawerVisible } from '../../../../../ducks/objectTypes'

import PageTitle from 'components/Global/PageTitle'
import Button from 'components/Global/Button'

export class ObjectTypeHeader extends React.Component {
  render () {
    const { intl, dispatch } = this.props

    return (
      <div>
        <FormattedMessage id='head.title.objectTypes'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={intl.formatMessage({ id: 'object types' })}
          buttons={
            <>
              <Button
                style={{ marginLeft: 8 }}
                key={238397323536}
                type='primary'
                onClick={() => dispatch(setDrawerVisible(true))}
              >
                <FormattedMessage id='create objectType' />
              </Button>
            </>
          }
        />
      </div>
    )
  }
}

ObjectTypeHeader.propTypes = {
  intl: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default injectIntl(connect()(ObjectTypeHeader))
