import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select, AutoComplete } from 'antd'
import { connect } from 'react-redux'

import { fetchObjectsList } from '../../../ducks/object'
import { updatePickedRecording } from '../../../ducks/recordings'

const { Option } = Select

const Identification = props => {
  const { recording, readOnly, settings, objects, dispatch } = props
  const objectName =
    (settings && settings.buildx && settings.buildx.objectName) || ''

  const handleFetchObjectsOnSearch = searchQueue => {
    dispatch(fetchObjectsList({ 'name[]': searchQueue }, true))
  }

  const handleFieldChange = (key, value) => {
    dispatch(updatePickedRecording({ [key]: value }))
  }

  return (
    <Form.Item label={<span>{objectName}</span>} {...props.formItemLayout}>
      <AutoComplete
        allowClear
        dropdownMatchSelectWidth={false}
        style={{ width: '100%' }}
        placeholder='Select Object'
        defaultValue={recording.objectId && recording.objectId.name}
        onSearch={handleFetchObjectsOnSearch}
        onSelect={(value, e) => handleFieldChange('objectId', e.props.data)}
        disabled={readOnly}
      >
        {objects.map(object => (
          <Option key={object._id} data={object} value={object.name || ''}>
            {object.name}
          </Option>
        ))}
      </AutoComplete>
    </Form.Item>
  )
}

Identification.propTypes = {
  readOnly: PropTypes.bool,
  formItemLayout: PropTypes.object,
  settings: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  recording: PropTypes.object,
  objects: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
  recording: state.recordings.pickedRecording,
  readOnly: state.recordings.pickedReadOnly,
  objects: state.objects.searchedObjectsList,
  settings: state.settings.data
})

export default connect(mapStateToProps, null)(Identification)
