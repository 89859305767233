import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { PageHeader } from 'antd'
import PropTypes from 'prop-types'

import FileUploader from './FileUploader'
import imageService from 'services/image'
import defectService from 'services/defects'

import './styles.scss'

class ImagesUploadTab extends React.Component {
  state = {
    fileList: [],
    deleteFile: {}
  }

  onDelete = file => {
    const { defect, updateImages } = this.props
    imageService
      .delete(file._id)
      .then(() => {
        updateImages(defect.images.filter(item => item._id !== file._id))
      })
      .catch(error => {
        console.log(error)
      })
  }

  onChange = info => {
    this.setState({
      image: { ...info.file, fileName: info.file.name }
    })
  }

  uploadFile = async ([file]) => {
    const { defect, updateImages } = this.props
    const formData = new FormData()
    formData.append('image', file)

    defectService
      .addImage(defect._id, formData)
      .then(response => {
        updateImages([response, ...defect.images])
        this.setState({
          image: undefined
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  waitUntilImageLoaded = resolve => {
    setTimeout(() => {
      this.state.image
        ? resolve() // from onChange method
        : this.waitUntilImageLoaded(resolve)
    }, 10)
  }

  render () {
    const { defect, updateImages, action: onAction, intl } = this.props

    return (
      <>
        <PageHeader
          onBack={onAction}
          title={intl.formatMessage({
            id: 'images upload'
          })}
        />
        <div className='dragger-cover'>
          <FileUploader
            handleUpload={this.uploadFile}
            handleDelete={this.onDelete}
            handleUpdate={updateImages}
            files={defect.images}
          />
        </div>
      </>
    )
  }
}

ImagesUploadTab.propTypes = {
  intl: PropTypes.object.isRequired,
  defect: PropTypes.object.isRequired,
  updateImages: PropTypes.func.isRequired,
  action: PropTypes.func
}

export default injectIntl(connect()(ImagesUploadTab))
