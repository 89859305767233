import { createReducer, createAction } from 'redux-act'

// import { DefectsAPI } from '../services'
// import { notification } from 'antd'
import axios from 'axios'
import config from 'config'
import { handleDownloadResponse } from './recordings'
// import moment from 'moment'

export const REDUCER = 'defects'
const NS = `@@${REDUCER}/`

export const setDefectsData = createAction(`${NS}SET_DEFECTS_DATA`)
export const setPickedDefect = createAction(`${NS}SET_PICKED_DEFECT`)
export const setDefectQueryParams = createAction(`${NS}SET_QUERY_PARAMS`)

// const DefectsApi = new DefectsAPI()

export const initialState = {
  defectsData: {},
  pickedDefect: {},
  queryParams: {},
  pickedReadOnly: false
}

// export const updatePickedRecording = data => (dispatch, getState) => {
//   const currentState = { ...getState().recordings.pickedRecording }
//   dispatch(setPickedRecording({ ...currentState, ...data }))
// }

// export const fetchRecordings = params => (dispatch, getState) => {
//   const pickedProject = getState().project.pickedProject._id
//   if (pickedProject) {
//     params.project = pickedProject
//   }
//   dispatch(setRecordingQueryParams(params))
//   return RecordingsApi.listV2(params)
//     .then(response => {
//       dispatch(setRecordingsData(response))
//       return response
//     })
//     .catch(error => {
//       notification.open({
//         type: 'error',
//         message: error.message
//       })
//     })
// }

export const downloadPDF = defect => (dispatch, getState) => {
  return axios({
    method: 'get',
    url: `${config.server.url}/defect/${defect._id}/pdf`,
    responseType: 'arraybuffer',
    headers: {
      authorization: localStorage.getItem('authToken')
    }
  })
    .then(function (response) {
      handleDownloadResponse(
        response,
        'application/pdf',
        `${defect.docNumber}.pdf`
      )
      return response
    })
    .catch(error => {
      return { error: error.response && error.response.status }
    })
}

export default createReducer(
  {
    [setDefectsData]: (state, recordingsData) => ({
      ...state,
      recordingsData
    }),
    [setDefectQueryParams]: (state, queryParams) => ({
      ...state,
      queryParams
    }),
    [setPickedDefect]: (state, pickedDefect) => ({
      ...state,
      pickedDefect
    })
    // [setPickedReadOnly]: (state, pickedReadOnly) => ({
    //   ...state,
    //   pickedReadOnly
    // }),
    // [setRecordingDefectCreationData]: (state, recordingDefectCreationData) => ({
    //   ...state,
    //   recordingDefectCreationData
    // })
  },
  initialState
)
