import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import ExtraFieldsSection from '../../Sections/ExtraFields'
import '../../styles.scss'

class CustomFormTab extends React.Component {
  render () {
    const { customForm } = this.props
    return <ExtraFieldsSection form={customForm} />
  }
}

CustomFormTab.propTypes = {
  customForm: PropTypes.object.isRequired
}

export default injectIntl(CustomFormTab)
