import { createReducer, createAction } from 'redux-act'

import { ProjectAPI } from '../services'
import { notification } from 'antd'

export const REDUCER = 'project'
const NS = `@@${REDUCER}/`

export const setProjectList = createAction(`${NS}SET_PROJECT_LIST`)
export const setPickedProject = createAction(`${NS}SET_PICKED_PROJECT`)
export const setSearchedProjects = createAction(`${NS}SET_SEARCHED_PROJECT`)

const ProjectApi = new ProjectAPI()

export const initialState = {
  projectList: [],
  pickedProject: {},
  searchedProjectsList: []
}

export const fetchProjectList = (params, search = false) => (
  dispatch,
  getState
) => {
  if (!!params && !!search) {
    return ProjectApi.listV2(params)
      .then(response => {
        dispatch(setSearchedProjects(response.docs))
        return true
      })
      .catch(error => {
        notification.open({
          type: 'error',
          message: error.message
        })
        return false
      })
  } else {
    return ProjectApi.list()
      .then(response => {
        dispatch(setProjectList(response))
        return true
      })
      .catch(error => {
        notification.open({
          type: 'error',
          message: error.message
        })
        return false
      })
  }
}

export default createReducer(
  {
    [setProjectList]: (state, projectList) => ({ ...state, projectList }),
    [setPickedProject]: (state, pickedProject) => ({ ...state, pickedProject }),
    [setSearchedProjects]: (state, searchedProjectsList) => ({
      ...state,
      searchedProjectsList
    })
  },
  initialState
)
