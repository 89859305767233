import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Form, Input, Checkbox, Select } from 'antd'
import _ from 'lodash'

class UserForm extends React.Component {
  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form
    if (!value) {
      form.validateFields(['passwordRetype'], { force: true })
      callback()
      return
    }
    if (value && value.length >= 8) {
      form.validateFields(['passwordRetype'], { force: true })
      callback()
    } else {
      callback(
        this.props.intl.formatMessage({
          id: 'new password tooltip'
        })
      )
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form
    if (value && value !== form.getFieldValue('password')) {
      callback(
        this.props.intl.formatMessage({
          id: 'password compare tooltip'
        })
      )
    } else {
      callback()
    }
  }

  render () {
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <Form onSubmit={() => {}} className='userForm'>
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              E-Mail<span style={{ color: '#005591' }}>*</span>
            </span>
          }
          required={false}
        >
          {getFieldDecorator('email', {
            initialValue: _.isEmpty(this.props.user)
              ? ''
              : this.props.user.email,
            rules: [
              {
                required: true,
                message: `E-Mail ${this.props.intl.formatMessage({
                  id: 'is required'
                })}`
              },
              {
                type: 'email',
                message: this.props.intl.formatMessage({
                  id: 'email is not valid'
                })
              }
            ]
          })(
            <Input
              onChange={event =>
                this.props.updateUser('email', event.target.value)
              }
              disabled={!!this.props.user._id}
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={
            <span>
              <FormattedMessage id='first name' />
              <span style={{ color: '#005591' }}>*</span>
            </span>
          }
          required={false}
        >
          {getFieldDecorator('firstName', {
            initialValue: _.isEmpty(this.props.user)
              ? ''
              : this.props.user.firstName,
            rules: [
              {
                required: true,
                message: `${this.props.intl.formatMessage({
                  id: 'first name'
                })} ${this.props.intl.formatMessage({ id: 'is required' })}`
              }
            ]
          })(
            <Input
              onChange={event =>
                this.props.updateUser('firstName', event.target.value)
              }
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={
            <span>
              <FormattedMessage id='last name' />
              <span style={{ color: '#005591' }}>*</span>
            </span>
          }
          required={false}
        >
          {getFieldDecorator('lastName', {
            initialValue: _.isEmpty(this.props.user)
              ? ''
              : this.props.user.lastName,
            rules: [
              {
                required: true,
                message: `${this.props.intl.formatMessage({
                  id: 'last name'
                })} ${this.props.intl.formatMessage({ id: 'is required' })}`
              }
            ]
          })(
            <Input
              onChange={event =>
                this.props.updateUser('lastName', event.target.value)
              }
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={this.props.intl.formatMessage({ id: 'function' })}
        >
          <Input
            value={this.props.user.function}
            onChange={event =>
              this.props.updateUser('function', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={this.props.intl.formatMessage({ id: 'employee number' })}
        >
          <Input
            value={this.props.user.employeeNumber}
            onChange={event =>
              this.props.updateUser('employeeNumber', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={this.props.intl.formatMessage({ id: 'password' })}
        >
          {getFieldDecorator('password', {
            rules: [
              {
                // required: true,
              },
              {
                validator: this.validateToNextPassword
              }
            ]
          })(
            <Input.Password
              autoComplete='new-password'
              // value={this.props.user.password}
              onChange={event =>
                this.props.updateUser('password', event.target.value)
              }
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={this.props.intl.formatMessage({ id: 'retype password' })}
        >
          {getFieldDecorator('passwordRetype', {
            rules: [
              {
                // required: true,
              },
              {
                validator: this.compareToFirstPassword
              }
            ]
          })(
            <Input.Password
              // value={this.props.user.passwordRetype}
              onChange={event =>
                this.props.updateUser('passwordRetype', event.target.value)
              }
            />
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={
            <span>
              <FormattedMessage id='group' />
              <span style={{ color: '#005591' }}>*</span>
            </span>
          }
          required={false}
        >
          {getFieldDecorator('scope', {
            initialValue: this.props.user.scope,
            rules: [
              {
                required: true,
                message: `${this.props.intl.formatMessage({
                  id: 'group'
                })} ${this.props.intl.formatMessage({ id: 'is required' })}`
              }
            ]
          })(
            <Select
              style={{ width: '100%' }}
              onChange={value => this.props.updateUser('scope', value)}
            >
              {Object.keys(this.props.roles).map(role => (
                <Select.Option key={role} value={role}>
                  {this.props.roles[role]}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={this.props.intl.formatMessage({ id: 'activated' })}
        >
          <Checkbox
            checked={this.props.user.activated}
            onChange={event =>
              this.props.updateUser('activated', event.target.checked)
            }
          />
        </Form.Item>
      </Form>
    )
  }
}

UserForm.propTypes = {
  user: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired
}

export default injectIntl(UserForm)
