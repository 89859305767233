import React, { useRef } from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'

import FormProduct from './FormProduct'
import useApi from '../../../hooks/useApi'
import Drawer from '../../../components/Drawer'
import productService from '../../../services/products'
import PopconfirmDelete from '../../../components/Global/PopconfirmDelete'

const ProductManagement = ({ visible, product, onSubmit, onClose }) => {
  const form = useRef()
  const intl = useIntl()
  const scope = useSelector(
    ({
      auth: {
        authUser: { scope }
      }
    }) => scope[0]
  )

  const [creating, createProduct] = useApi(productService.addProduct)
  const [updating, updateProduct] = useApi(productService.updateProduct)
  const [deleting, deleteProduct] = useApi(productService.deleteProduct)

  const handleSubmit = () => {
    form.current.validateFields(async (errors, { _id, ...record }) => {
      if (errors) return

      onSubmit(
        _id ? await updateProduct(_id, record) : await createProduct(record)
      )
    })
  }

  const handleDelete = async () => onSubmit(await deleteProduct(product._id))

  const loading = creating || updating || deleting

  const drawerButtons = [
    <Button key='cancel' onClick={onClose}>
      <FormattedMessage id='cancel' />
    </Button>,
    <Button
      key='save'
      type='primary'
      onClick={handleSubmit}
      loading={loading}
      disabled={loading}
    >
      <FormattedMessage id='save' />
    </Button>
  ]

  if (
    ['admin', 'superadmin'].includes(scope) &&
    product &&
    !product.isVerified
  ) {
    drawerButtons.unshift(
      <PopconfirmDelete key='delete' onConfirmAction={handleDelete} />
    )
  }

  return (
    <Drawer
      title={intl.formatMessage({
        id: `${product ? 'edit' : 'add'} manufacturer`
      })}
      visible={visible}
      onClose={onClose}
      footerButtons={drawerButtons}
    >
      <FormProduct ref={form} product={product} />
    </Drawer>
  )
}

ProductManagement.propTypes = {
  product: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
}

ProductManagement.defaultProps = {
  visible: false
}

export default ProductManagement
