import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Form, Input, Select } from 'antd'

import useApi from '../../../hooks/useApi'
import formService from '../../../services/form'
import projectService from '../../../services/project'

const { Item } = Form
const { Option } = Select

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  margin-top: 20px;

  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormCategory = ({ form, category }) => {
  const intl = useIntl()
  const { getFieldDecorator } = form
  const [loadingForms, fetchForms] = useApi(formService.listV2)
  const [loadingProjects, fetchProjects] = useApi(projectService.listV2)

  const { _id, name, project, custom_form: customForm } = category || {}

  _id && getFieldDecorator('_id', { initialValue: _id })

  const [forms, setForms] = useState(customForm ? [customForm] : [])
  const [projects, setProjects] = useState(project ? [project] : [])
  const selectedProject = useSelector(
    ({ project: { pickedProject } }) => pickedProject
  )

  const loadForms = async (value = '') => {
    const { docs = [] } = await fetchForms({
      name: [value],
      limit: 100
    })

    if (customForm && !docs.some(({ _id }) => _id === customForm._id)) {
      docs.push(customForm)
    }

    setForms(docs)
  }

  const loadProjects = async (value = '') => {
    if (!!selectedProject._id && !project) {
      return setProjects([selectedProject])
    }

    const { docs = [] } = await fetchProjects({
      projectName: [value],
      limit: 100
    })

    if (project && !docs.some(({ _id }) => _id === project._id)) {
      docs.push(project)
    }

    setProjects(docs)
  }

  useEffect(() => {
    loadForms()
    loadProjects()
  }, [])

  return (
    <StyledForm {...formLayout}>
      <Item label={intl.formatMessage({ id: 'name' })}>
        {getFieldDecorator('name', {
          initialValue: name,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'name is required'
              })
            }
          ]
        })(
          <Input
            placeholder={intl.formatMessage({
              id: 'set a name'
            })}
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'project' })}>
        {getFieldDecorator('project', {
          initialValue: project ? project._id : selectedProject._id,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'project is required'
              })
            }
          ]
        })(
          <Select
            showSearch
            allowClear
            filterOption={false}
            loading={loadingProjects}
            style={{ width: '100%' }}
            placeholder={intl.formatMessage({
              id: 'choose a project'
            })}
            onSearch={debounce(loadProjects, 500)}
            disabled={!!selectedProject._id && !project}
          >
            {projects.map(({ _id, projectName }) => (
              <Option key={_id}>{projectName}</Option>
            ))}
          </Select>
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'category form' })}>
        {getFieldDecorator('custom_form', {
          initialValue: customForm && customForm._id,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'category form is required'
              })
            }
          ]
        })(
          <Select
            showSearch
            allowClear
            filterOption={false}
            loading={loadingForms}
            style={{ width: '100%' }}
            placeholder={intl.formatMessage({
              id: 'choose a form'
            })}
            onSearch={debounce(loadForms, 500)}
          >
            {forms.map(({ _id, name }) => (
              <Option key={_id}>{name}</Option>
            ))}
          </Select>
        )}
      </Item>
    </StyledForm>
  )
}

FormCategory.propTypes = {
  category: PropTypes.object,
  form: PropTypes.object.isRequired
}

export default Form.create({ name: 'form_category' })(FormCategory)
