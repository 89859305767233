import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { ContactsAPI } from '../services'

export const REDUCER = 'contacts'
const NS = `@@${REDUCER}/`

export const setSearchedContacts = createAction(`${NS}SET_SEARCHED_CONTACTS`)
export const setContactsList = createAction(`${NS}SET_CONTACTS_LIST`)

const ContactsApi = new ContactsAPI()

export const initialState = {
  searchedContacts: [],
  contractsList: []
}

export const fetchContacts = (params, search = false) => (
  dispatch,
  getState
) => {
  return ContactsApi.listV2(params)
    .then(response => {
      if (search) {
        dispatch(setSearchedContacts(response.docs))
      } else {
        dispatch(setContactsList(response.docs))
      }
      return true
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return error
    })
}

export default createReducer(
  {
    [setSearchedContacts]: (state, searchedContacts) => ({
      ...state,
      searchedContacts
    }),
    [setContactsList]: (state, contractsList) => ({ ...state, contractsList })
  },
  initialState
)
