import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'

import PageTitle from 'components/Global/PageTitle'
import AcceptanceTable from './components/Table'
import AcceptanceDrawer from './components/Drawer'
import RecordingDrawer from '../../../components/RecordingsDrawer'
import ModalDefectCreation from '../../../components/ModalDefectCreation'

const AcceptancesPage = () => {
  const intl = useIntl()

  return (
    <>
      <FormattedMessage id='head.title.acceptances'>
        {title => (
          <Helmet>
            <title>{title}</title>
          </Helmet>
        )}
      </FormattedMessage>

      <PageTitle
        title={intl.formatMessage({
          id: 'acceptances'
        })}
      />
      <AcceptanceTable />

      <AcceptanceDrawer />

      <RecordingDrawer acceptance />
      <ModalDefectCreation />
    </>
  )
}

export default AcceptancesPage
