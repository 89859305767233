import { createReducer, createAction } from 'redux-act'

import { RecordingsAPI, ObjectsAPI } from '../services'
import { notification } from 'antd'
import axios from 'axios'
import config from 'config'

import moment from 'moment'

export const REDUCER = 'recordings'
const NS = `@@${REDUCER}/`

export const setRecordingsData = createAction(`${NS}SET_RECORDINGS_DATA`)
export const setPickedRecording = createAction(`${NS}SET_PICKED_RECORDING`)
export const setPickedReadOnly = createAction(`${NS}SET_PICKED_READ_ONLY`)
export const setRecordingQueryParams = createAction(`${NS}SET_QUERY_PARAMS`)
export const setRecordingDefectCreationData = createAction(
  `${NS}SET_RECORDING_DEFECT_CREATION_DATA`
)

const RecordingsApi = new RecordingsAPI()
const ObjectsApi = new ObjectsAPI()

export const initialState = {
  recordingsData: {},
  pickedRecording: {},
  queryParams: {},
  recordingDefectCreationData: {},
  pickedReadOnly: false
}

export const updatePickedRecording = data => (dispatch, getState) => {
  const currentState = { ...getState().recordings.pickedRecording }
  dispatch(setPickedRecording({ ...currentState, ...data }))
}

export const deleteRecording = data => (dispatch, getState) => {
  const pickedRecording = getState().recordings.pickedRecording
  const recordingsData = getState().recordings.recordingsData
  return RecordingsApi.delete(pickedRecording._id)
    .then(result => {
      if (!result.error) {
        dispatch(
          setRecordingsData({
            ...recordingsData,
            docs: recordingsData.docs.filter(
              item => item._id !== pickedRecording._id
            )
          })
        )
        dispatch(setPickedRecording({}))
      }
      return result
    })
    .catch(error => {
      return error
    })
}

export const createRecordingDefect = (payload, formValue) => async (
  dispatch,
  getState
) => {
  const currentState = { ...getState().recordings.recordingDefectCreationData }
  const { income } = currentState
  if (
    !formValue.protocolRefExt &&
    formValue.protocolRefExt !== '' &&
    income.protocolRefExt
  ) {
    payload.protocolRefExt = income.protocolRefExt
  }

  if (
    !formValue.actualSituation &&
    formValue.actualSituation !== '' &&
    income.actualSituation
  ) {
    payload.actualSituation = income.actualSituation
  }

  if (formValue.protocolExtResponsible) {
    payload.protocolExtResponsible = formValue.protocolExtResponsible
  }

  if (formValue.protocolResponsible || income.protocolResponsible) {
    payload.protocolResponsible =
      formValue.protocolResponsible || income.protocolResponsible
  }
  if (formValue.assignedTo || income.assignedTo) {
    payload.assignedTo = formValue.assignedTo || income.assignedTo
  }
  if (formValue.contractSection || income.contractSection) {
    payload.contractSection =
      (formValue.contractSection && formValue.contractSection._id) ||
      (income.contractSection && income.contractSection._id)
  }

  if (
    !formValue.actualSituation &&
    income.attributes &&
    income.attributes.actualStatus
  ) {
    payload.actualSituation = income.attributes.actualStatus
  }

  if (
    !formValue.targetSituation &&
    income.attributes &&
    income.attributes.targetStatus
  ) {
    payload.targetSituation = income.attributes.targetStatus
  }
  if (!formValue.contractSection && income.contractSection) {
    payload.contractSection = income.contractSection._id
  }
  if (!formValue.project && income.project) {
    payload.project = income.project._id
  }
  if (!formValue.objectId && income.objectId) {
    payload.objectId = income.objectId._id
  }

  return RecordingsApi.createDefect(income._id, payload)
    .then(result => {
      return result
    })
    .catch(error => {
      return error
    })
}

export const submitPickedRecording = () => async (dispatch, getState) => {
  const recording = { ...getState().recordings.pickedRecording }
  let objectId = recording.objectId
  let error = false
  if (!objectId._id) {
    if (objectId.fields) {
      objectId = await ObjectsApi.create({
        fields: objectId.fields
      })
    } else {
      objectId = await ObjectsApi.create({
        name: objectId.name
      })
    }
  }

  const payloadProducts = []

  recording.products &&
    recording.products.forEach(item => {
      const payloadProduct = {
        product: item.product._id,
        quantity: item.quantity,
        extra_fields: item.extra_fields
      }
      payloadProducts.push(payloadProduct)
    })

  const params = {
    name:
      recording.name ||
      `Aufnahme vom ${moment(recording.createdAt).format('DD.MM.YYYY HH:ss')}`,
    project: recording.project || null,
    comment: recording.comment,
    status: recording.status || '',
    objectId: objectId._id,
    date: recording.date,
    attributes: recording.attributes,
    archived: recording.archived,
    isRejected: recording.isRejected,
    isInvalid: recording.isInvalid,
    products: payloadProducts
  }

  if (recording.contractSectionName) {
    params.contractSection = recording.contractSectionName
  }

  const savedRecording = await RecordingsApi.save(recording._id, params)
  if (savedRecording.error) {
    error = true
  }
  if (recording.images && recording.images.length > 0) {
    for (const image of recording.images) {
      const data = new FormData()

      if (image.comment) {
        data.append('comment', image.comment)
      }

      if (image.status) {
        data.append('status', image.status)
      }

      try {
        const savedImage = await RecordingsApi.updateImage(image._id, data)
        savedRecording.images = [
          ...savedRecording.images.filter(item => item._id !== savedImage._id),
          savedImage
        ]
      } catch (e) {
        if (e.error) {
          error = true
          notification.error({
            message: 'Images upload Error',
            description: e.error
          })
        }
      }
    }
  }

  return !error
}

export const handleRecordingPick = recording => (dispatch, getState) => {
  const readOnly =
    recording.status === 'approved' || recording.status === 'invalid'
  dispatch(setPickedRecording(recording))
  dispatch(setPickedReadOnly(readOnly))
}

export const fetchRecordings = params => (dispatch, getState) => {
  const pickedProject = getState().project.pickedProject._id
  if (pickedProject) {
    params.project = pickedProject
  }
  dispatch(setRecordingQueryParams(params))
  return RecordingsApi.listV2(params)
    .then(response => {
      dispatch(setRecordingsData(response))
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
    })
}

export const exportCSV = () => (dispatch, getState) => {
  const params = getState().recordings.queryParams
  const pickedProject = getState().project.pickedProject._id
  if (pickedProject) {
    params.project = pickedProject
  }
  axios({
    method: 'get',
    url: `${config.server.url}/recording-csv`,
    responseType: 'text/csv',
    headers: {
      authorization: localStorage.getItem('authToken')
    },
    params
  }).then(function (response) {
    handleDownloadResponse(response, 'text/csv', 'recordingsData.csv')
  })
}

export const downloadPDF = recording => (dispatch, getState) => {
  axios({
    method: 'get',
    url: `${config.server.url}/recording/${recording._id}/pdf`,
    responseType: 'arraybuffer',
    headers: {
      authorization: localStorage.getItem('authToken')
    }
  }).then(function (response) {
    handleDownloadResponse(response, 'application/pdf', `${recording.name}.pdf`)
  })
}

export const handleDownloadResponse = (response, type, fileName) => {
  const blob = new Blob([response.data], { type: type })

  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, fileName)
  } else {
    const dataURI = `data:text/csv;charset=utf-8,${response.data}`

    const URL = window.URL || window.webkitURL
    const downloadURI =
      typeof URL.createObjectURL === 'undefined'
        ? dataURI
        : URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.setAttribute('href', downloadURI)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export const archiveRecording = id => (dispatch, getState) => {
  return RecordingsApi.archive(id)
    .then(response => {
      return true
    })
    .catch(e => {
      return e
    })
}
export const unarchiveRecording = id => (dispatch, getState) => {
  return RecordingsApi.unarchive(id)
    .then(response => {
      return true
    })
    .catch(e => {
      return e
    })
}

export const exportProductCSV = () => (dispatch, getState) => {
  const params = getState().recordings.queryParams
  const pickedProject = getState().project.pickedProject._id
  if (pickedProject) {
    params.project = pickedProject
  }
  axios({
    method: 'get',
    url: `${config.server.url}/recording-products-csv`,
    responseType: 'text/csv',
    headers: {
      authorization: localStorage.getItem('authToken')
    },
    params
  }).then(function (response) {
    handleDownloadResponse(response, 'text/csv', 'products.csv')
  })
}

export default createReducer(
  {
    [setRecordingsData]: (state, recordingsData) => ({
      ...state,
      recordingsData
    }),
    [setRecordingQueryParams]: (state, queryParams) => ({
      ...state,
      queryParams
    }),
    [setPickedRecording]: (state, pickedRecording) => ({
      ...state,
      pickedRecording
    }),
    [setPickedReadOnly]: (state, pickedReadOnly) => ({
      ...state,
      pickedReadOnly
    }),
    [setRecordingDefectCreationData]: (state, recordingDefectCreationData) => ({
      ...state,
      recordingDefectCreationData
    })
  },
  initialState
)
