import React from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import { Collapse } from 'antd'

import './Form/styles.scss'
import Zoom from 'react-medium-image-zoom'

const { Panel } = Collapse

const RecordingPreview = props => {
  const { recording } = props

  return (
    <Collapse defaultActiveKey={['1']}>
      <Panel header={`${recording.name}`} key='1'>
        <div className='image-block-cover'>
          {recording.images &&
            recording.images.map((value, index) => (
              <div className='image-block' key={index}>
                <Zoom zoomMargin={40}>
                  <img
                    src={config.server.url + value.imageThumbUrl}
                    className='img'
                    style={{ width: 100 }}
                  />
                </Zoom>

                <p>{value.comment}</p>
              </div>
            ))}
        </div>
      </Panel>
    </Collapse>
  )
}

RecordingPreview.propTypes = {
  recording: PropTypes.object
}

export default RecordingPreview
