import { createReducer, createAction } from 'redux-act'

import { ObjectTypeAPI } from '../services'
import { notification } from 'antd'

export const REDUCER = 'object'
const NS = `@@${REDUCER}/`

export const setObjectTypeData = createAction(`${NS}SET_OBJECT_TYPE_DATA`)
export const setPickedObjectType = createAction(`${NS}SET_PICKED_OBJECT_TYPE`)
export const setSearchedObjects = createAction(`${NS}SET_SEARCHED_OBJECTS_LIST`)
export const setDrawerVisible = createAction(`${NS}SET_DRAWER_VISIBLE`)

const ObjectTypeApi = new ObjectTypeAPI()

export const initialState = {
  objectTypeData: {},
  searchedObjectTypeList: [],
  pickedObjectType: {},
  drawerVisible: false
}

export const fetchObjectTypeList = (params, search = false) => (
  dispatch,
  getState
) => {
  return ObjectTypeApi.listV2(params)
    .then(response => {
      if (search) {
        dispatch(setSearchedObjects(response.docs))
      } else {
        dispatch(setObjectTypeData(response))
      }
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return false
    })
}

export const submitPickedObjectType = () => (dispatch, getState) => {
  const data = getState().objectType.pickedObjectType
  return ObjectTypeApi.save(data)
    .then(response => {
      return response
    })
    .catch(error => {
      if (error.error.indexOf('to be unique') === -1) {
        notification.open({
          type: 'error',
          message: error.message
        })
      }
      return error
    })
}

export const deletePickedObjectType = () => (dispatch, getState) => {
  const data = getState().objectType.pickedObjectType
  return ObjectTypeApi.delete(data._id)
    .then(response => {
      return response
    })
    .catch(error => {
      return error
    })
}

export const updatePickedObjectType = data => (dispatch, getState) => {
  const currentState = { ...getState().objectType.pickedObjectType }
  dispatch(setPickedObjectType({ ...currentState, ...data }))
}

export default createReducer(
  {
    [setObjectTypeData]: (state, objectTypeData) => ({
      ...state,
      objectTypeData
    }),
    [setDrawerVisible]: (state, drawerVisible) => ({ ...state, drawerVisible }),
    [setPickedObjectType]: (state, pickedObjectType) => ({
      ...state,
      pickedObjectType
    }),
    [setSearchedObjects]: (state, searchedObjectTypeList) => ({
      ...state,
      searchedObjectTypeList
    })
  },
  initialState
)
