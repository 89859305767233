import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { TreeSelect } from 'antd'
import { fetchContacts } from '../../ducks/contacts'
import { useDispatch, useSelector } from 'react-redux'
import useDebounce from '../../services/debounce'

const ContactSelect = props => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [searchString, setSearchString] = useState('')
  const [stateValue, setStateValue] = useState('')
  const contacts = useSelector(state => state.contacts.searchedContacts)
  const { currentValue = {} } = props

  const debouncedSearchString = useDebounce(searchString, 300)

  const contactsNode = useMemo(() => {
    const unAssignedList = []
    const formedNodes = []
    contacts.forEach(item => {
      if (item.contact_type === 'company' || item.company) {
        formedNodes.push({
          title: item.company || item.name,
          value: item._id,
          key: item._id,
          data: { contact_sub: item },
          children: [
            ...(item.contacts_sub || []).map(subItem => ({
              title:
                subItem.contact_sub.contact_type === 'company' ||
                subItem.contact_sub.company
                  ? subItem.contact_sub.company || subItem.contact_sub.name
                  : `${subItem.contact_sub.firstName || ''} ${subItem
                      .contact_sub.lastName || ''}`,
              value: subItem.contact_sub._id + subItem.contact_sub._id,
              key: subItem.contact_sub._id,
              data: {
                contact_sub: subItem.contact_sub,
                contact_sup: item
              }
            }))
          ]
        })
      } else {
        if (
          !contacts.some(
            contact =>
              contact.contacts_sub &&
              contact.contacts_sub.some(
                sub => sub.contact_sub && sub.contact_sub._id === item._id
              )
          )
        ) {
          unAssignedList.push({
            title: `${item.firstName || ''} ${item.lastName || ''}`,
            value: '0-1' + item._id,
            key: item._id,
            data: { contact_sub: item }
          })
        }
      }
    })
    const unassignedNode = {
      title: `${intl.formatMessage({ id: 'unassigned persons' })} (${
        unAssignedList.length
      })`,
      value: '0-1',
      key: '2',
      selectable: false,
      children: unAssignedList
    }

    formedNodes.push(unassignedNode)
    return formedNodes
  }, [contacts])

  useEffect(() => {
    if (debouncedSearchString !== undefined) {
      fetchContactsOnSearch(searchString)
    }
  }, [debouncedSearchString])

  const fetchContactsOnSearch = async searchQueue => {
    dispatch(
      fetchContacts(
        {
          'company[]': searchQueue,
          limit: 1000,
          sortField: 'company',
          sortOrder: 'ascend'
        },
        true
      )
    )
  }

  const formContactString = contact => {
    let valueString = ''
    if (contact.contact_type === 'company' || contact.company) {
      valueString += contact.company
    } else {
      valueString += `${contact.firstName || ''}${
        contact.lastName ? ` ${contact.lastName}` : ''
      }`
    }
    return valueString
  }

  const onPick = pickedContact => {
    const { pickContact } = props
    pickContact(pickedContact)
    let valueString = ''
    if (pickedContact.contact_sup) {
      valueString += formContactString(pickedContact.contact_sup)
    }

    if (pickedContact.contact_sub) {
      const subValue = formContactString(pickedContact.contact_sub)
      if (subValue.length) {
        valueString += pickedContact.contact_sup
          ? ` (${subValue})`
          : `${subValue}`
      }
    }
    setStateValue(valueString)
  }
  let value = ''
  if (currentValue) {
    // eslint-disable-next-line  camelcase
    const { contact_sub, contact_sup } = currentValue
    // eslint-disable-next-line  camelcase
    if (contact_sup) {
      value += formContactString(contact_sup)
    }
    // eslint-disable-next-line  camelcase
    if (contact_sub) {
      const subValue = formContactString(contact_sub)
      if (subValue.length) {
        // eslint-disable-next-line  camelcase
        value += contact_sup ? ` (${subValue})` : `${subValue}`
      }
    }
  }

  return (
    <TreeSelect
      showSearch
      style={{ display: 'block', flex: 1 }}
      placeholder={intl.formatMessage({ id: 'select contact' })}
      // defaultValue={'company'}
      value={stateValue || value || undefined}
      filterTreeNode={false}
      treeData={contactsNode}
      optionFilterProp='children'
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      dropdownMatchSelectWidth
      onFocus={() => fetchContactsOnSearch('')}
      onSearch={setSearchString}
      onSelect={(value, e) => onPick(e.props.data || '')}
    />
  )
}

ContactSelect.propTypes = {
  currentValue: PropTypes.any,
  pickContact: PropTypes.func.isRequired
}

export default ContactSelect
