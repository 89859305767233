import axios from '../helpers/axios'
import config from 'config'

export default {
  async list (params = {}) {
    return axios.get('/location', params)
  },

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/location', params)
  },
  async save (location) {
    const params = {
      name: location.name,
      project: location.project || null,
      disabled: location.disabled
    }
    if (
      typeof params.project === 'object' &&
      Object.keys(params.project).length === 0
    ) {
      delete params.project
    }
    if (location._id) {
      return axios.put(`/location/${location._id}`, params)
    } else {
      return axios.post('/location', params)
    }
  },

  async remove (location) {
    return axios.delete(`/location/${location._id}`)
  }
}
