import React from 'react'
import PropTypes from 'prop-types'

import { Checkbox, Form, Input, Select } from 'antd'
import { updatePickedRecording } from '../../../ducks/recordings'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  className: 'formItem'
}

class ExtraFields extends React.Component {
  changeFields = (fieldID, value, choices) => {
    const { dispatch, recording } = this.props

    const attributes = recording.attributes || {}
    const extra_fields = attributes.extra_fields || [] // eslint-disable-line
    const field = extra_fields.filter(item => item.id === fieldID)[0] || {
      id: fieldID
    }
    if (field) {
      field.value = value
      field.choices = choices
      dispatch(
        updatePickedRecording({
          attributes: {
            ...attributes,
            extra_fields: [
              ...extra_fields.filter(item => item.id !== field.id),
              { ...field }
            ]
          }
        })
      )
    }
  }

  changeChoices = (fieldID, value, checked) => {
    const { recording } = this.props

    const attributes = recording.attributes || {}
    // eslint-disable-next-line  camelcase
    const extra_fields = attributes.extra_fields || []
    const field = extra_fields.filter(item => item.id === fieldID)[0]

    let choices = []
    if (field && field.choices) {
      choices = field.choices
    }

    choices = choices.filter(item => item !== value)
    if (checked) {
      choices = [...choices, value]
    }
    this.changeFields(fieldID, null, choices)
  }

  render () {
    const { form, readOnly, recording } = this.props
    const attributes = recording.attributes || {}
    // eslint-disable-next-line  camelcase
    const extra_fields = attributes.extra_fields || []
    return (
      <>
        <h3>{form.name}</h3>
        {form.fields.map(item => {
          // eslint-disable-next-line  camelcase
          const field = extra_fields.filter(
            // eslint-disable-next-line  camelcase
            extra_field => extra_field.id === item.id
          )[0] || {
            id: item.id
          }
          if (item.element === 'TextArea') {
            return (
              <Form.Item key={item.id} {...formItemLayout} label={item.label}>
                <Input.TextArea
                  disabled={readOnly}
                  value={field.value}
                  onChange={event =>
                    this.changeFields(item.id, event.target.value)
                  }
                />
              </Form.Item>
            )
          } else if (item.element === 'Checkboxes') {
            return (
              <Form.Item key={item.id} {...formItemLayout} label={item.label}>
                {(item.options || []).map(option => (
                  <p key={option.key} style={{ margin: 0 }}>
                    <Checkbox
                      disabled={readOnly}
                      checked={(field.choices || []).includes(option.value)}
                      onChange={event =>
                        this.changeChoices(
                          item.id,
                          option.value,
                          event.target.checked
                        )
                      }
                    />
                    {option.text}
                  </p>
                ))}
              </Form.Item>
            )
          } else if (item.element === 'Dropdown') {
            return (
              <Form.Item key={item.id} {...formItemLayout} label={item.label}>
                <Select
                  style={{ width: '100%' }}
                  disabled={readOnly}
                  allowClear
                  value={field.value}
                  onChange={value => this.changeFields(item.id, value)}
                >
                  {(item.options || []).map(option => (
                    <Select.Option key={option.key} value={option.value}>
                      {option.text}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )
          } else {
            return (
              <Form.Item key={item.id} {...formItemLayout} label={item.label}>
                <Input
                  disabled={readOnly}
                  value={field.value}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  onChange={event =>
                    this.changeFields(item.id, event.target.value)
                  }
                />
              </Form.Item>
            )
          }
        })}
      </>
    )
  }
}

ExtraFields.propTypes = {
  readOnly: PropTypes.bool,
  formItemLayout: PropTypes.object,
  settings: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  recording: PropTypes.object,
  form: PropTypes.object
}

const mapStateToProps = state => ({
  recording: state.recordings.pickedRecording,
  readOnly: state.recordings.pickedReadOnly,
  projects: state.project.searchedProjectsList,
  objects: state.objects.searchedObjectsList,
  settings: state.settings.data
})

export default injectIntl(connect(mapStateToProps, null)(ExtraFields))
