import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input, Button, Form, Icon, notification } from 'antd'

import passwordService from 'services/password'

const FormForgotPassword = props => {
  const intl = useIntl()
  const [email, setEmail] = useState('')
  const [processing, setProcessing] = useState(false)

  const handleSend = async () => {
    if (processing) {
      return
    }

    if (!email) {
      return notification.error({
        message: intl.formatMessage({ id: 'email cannot be empty' })
      })
    }

    setProcessing(true)

    try {
      await passwordService.sendToken(email)
      props.finishAction('success')
    } catch (error) {
      props.finishAction('error')
    }

    setProcessing(false)
  }

  return (
    <>
      <Form.Item>
        <span className='card-label'>
          <FormattedMessage id='email' />
        </span>

        <Input
          onChange={e => setEmail(e.target.value)}
          placeholder={intl.formatMessage({ id: 'email' })}
          value={email}
          prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
        />
      </Form.Item>

      <Form.Item>
        <Button
          htmlType='button'
          onClick={handleSend}
          type='primary'
          loading={processing}
          block
        >
          <FormattedMessage id='send' />
        </Button>
      </Form.Item>
    </>
  )
}

FormForgotPassword.propTypes = {
  finishAction: PropTypes.func.isRequired
}

export default Form.create({
  name: 'form_auth_forgot_pw',
  mapPropsToFields (props) {
    return {
      token: Form.createFormField({
        ...props.token,
        value: props.token
      })
    }
  }
})(FormForgotPassword)
