import axios from '../helpers/axios'
import config from 'config'
const axiosCustom = require('axios')

export default {
  async objectsList () {
    const response = await axios.get('/object')
    return response
  },

  async searchObjects (limit, offset, name) {
    const token = localStorage.getItem('authToken')
    const response = await axiosCustom.get(
      `${config.server.host}/1.1/object?limit=${limit}&offset=${offset}&name[]=${name}`,
      {
        headers: {
          authorization: token
        }
      }
    )
    return response.data
  },

  async list (params = {}) {
    return axios.get('/object', params)
  },

  async listV2 (params = {}, incomeProjectId) {
    if (incomeProjectId) {
      params.project = incomeProjectId
    }

    return axios.get(config.server.host + '/1.1/object', params)
  },

  async create (params = {}) {
    return axios.post('/object', params)
  },

  async save (object) {
    const params = {
      comment: object.comment,
      description: object.description,
      disabled: object.disabled,
      project: object.project ? object.project : {},
      isVerified: object.isVerified || false
    }
    if (object.location && object.location.name) {
      params.location = object.location.name
    }

    if (
      typeof params.project === 'object' &&
      Object.keys(params.project).length === 0
    ) {
      delete params.project
    }

    if (object.specificationFields && object.specificationFields.length) {
      params.specificationFields = object.specificationFields
    }

    if (object.objectType && object.objectType._id) {
      params.objectType = object.objectType._id
    }

    if (object._id) {
      // if (params.disabled) {
      //   params = {
      //     comment: '',
      //     description: 'Raumbezeichnungsschild',
      //     disabled: true
      //   }
      // }

      return axios.put(`/object/${object._id}`, params)
    } else {
      params.name = object.name
      return axios.post('/object', params)
    }
  },

  async uploadCSV (file, force = false) {
    const data = new FormData()
    data.append('file', file)

    let url = '/object/importcsv'
    if (force) {
      url += '?force=true'
    }

    return axios.post(url, data)
  }
}
