import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { notification, Form } from 'antd'

import ObjectTypeForm from '../ObjectTypeForm'
import Button from 'components/Global/Button'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'

import DrawerComponent from 'components/Drawer'

import {
  fetchObjectTypeList,
  setDrawerVisible,
  setPickedObjectType,
  submitPickedObjectType,
  deletePickedObjectType
} from '../../../../../ducks/objectTypes'
import { fetchFieldList } from '../../../../../ducks/fields'

export class ObjectTypeDrawer extends React.Component {
  state = {
    loading: false
  }

  handleHideDrawer = () => {
    const { dispatch } = this.props
    dispatch(setDrawerVisible(false))
    dispatch(setPickedObjectType({}))
  }

  handleSaveObjectType = async () => {
    const { dispatch, form, pickedObjectType, intl } = this.props

    form.validateFields(async err => {
      if (!err) {
        this.setState({
          loading: true
        })
        dispatch(submitPickedObjectType()).then(response => {
          if (!response.error) {
            dispatch(fetchObjectTypeList())
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: pickedObjectType._id
                  ? 'updated successfully'
                  : 'created successfully'
              })}`
            })
            setTimeout(() => this.setState({ loading: false }), 1500)
            this.handleHideDrawer()
          } else {
            if (response.error.indexOf('to be unique') > -1) {
              notification.error({
                message: intl.formatMessage({
                  id: 'name already exists'
                })
              })
            } else {
              notification.error({
                message: intl.formatMessage({
                  id: 'saving error'
                }),
                description: response.error
              })
            }

            this.setState({
              loading: false
            })
          }
        })
      }
    })
  }

  handleDeleteRecording = async () => {
    const { dispatch, intl } = this.props
    dispatch(deletePickedObjectType()).then(response => {
      if (!response.error) {
        notification.success({
          message: intl.formatMessage({
            id: 'delete successful'
          }),
          description: response.message
        })
      } else {
        notification.error({
          message: intl.formatMessage({
            id: 'deleting error'
          }),
          description: response.message
        })
      }
      dispatch(fetchObjectTypeList())
      this.handleHideDrawer()
    })
  }

  componentDidMount () {
    const { dispatch } = this.props
    dispatch(fetchFieldList({ 'label[]': '' }, true))
  }

  render () {
    const { intl, form, drawerVisible, pickedObjectType } = this.props
    const { loading } = this.state
    const footerDrawerButtons = [
      <React.Fragment key={1}>
        <Button
          onClick={this.handleHideDrawer}
          style={{ marginRight: 8 }}
          value={<FormattedMessage id='cancel' />}
        />

        <Button
          onClick={this.handleSaveObjectType}
          type='primary'
          disabled={loading}
          loading={loading}
          value={
            <FormattedMessage
              id={
                pickedObjectType._id ? 'update objectType' : 'create objectType'
              }
            />
          }
        />
      </React.Fragment>
    ]

    if (
      ['admin', 'superadmin'].includes(this.props.auth.authUser.scope[0]) &&
      pickedObjectType._id
    ) {
      footerDrawerButtons.unshift(
        <PopconfirmDelete
          key={2}
          onConfirmAction={this.handleDeleteRecording}
        />
      )
    }
    return (
      <DrawerComponent
        title={intl.formatMessage({
          id: pickedObjectType._id ? 'objectType details' : 'create objectType'
        })}
        onClose={this.handleHideDrawer}
        visible={drawerVisible}
        footerButtons={footerDrawerButtons}
      >
        <ObjectTypeForm form={form} />
      </DrawerComponent>
    )
  }
}

ObjectTypeDrawer.propTypes = {
  auth: PropTypes.object,
  drawerVisible: PropTypes.bool,
  pickedObjectType: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  pickedRecording: state.recordings.pickedRecording,
  drawerVisible: state.objectType.drawerVisible,
  pickedObjectType: state.objectType.pickedObjectType
})

export default injectIntl(
  Form.create({ name: 'objectType' })(
    connect(mapStateToProps, null)(ObjectTypeDrawer)
  )
)
