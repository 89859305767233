import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Collapse, Badge } from 'antd'
import { withRouter } from 'react-router-dom'
import { Scrollbar } from 'react-scrollbars-custom'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import {
  fetchTasks,
  setTasksData,
  setPickedTask
} from '../../../../../ducks/tasks'
import { TaskContent } from './TaskContent'
import CreateTaskDropDown from '../CreateTaskDropDown'
import queryString from 'query-string'

const { Panel } = Collapse

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0`,
  background: 'white',
  borderWidth: isDragging ? 2 : 1,
  borderColor: isDragging ? '#005591' : 'rgb(217, 217, 217)',
  borderStyle: !isDragging ? 'dashed' : 'solid',
  ...draggableStyle
})

const defaultParams = { noWorkorders: true, limit: 10000 }

const getListStyle = isDraggingOver => {
  return {
    background: 'white',
    borderColor: isDraggingOver ? '#005591' : 'transparent',
    borderStyle: 'solid',
    borderWidth: 2,
    padding: 4,
    width: '100%',
    minHeight: 250
  }
}

export class TasksList extends React.Component {
  getParams = () => {
    const { history } = this.props
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      return searchQuery
    } else {
      return {}
    }
  }

  componentDidMount () {
    const { dispatch } = this.props
    if (this.getParams().project) {
      defaultParams.project = this.getParams().project
    }
    dispatch(fetchTasks(defaultParams))
  }

  onTaskCreation = task => {
    const { dispatch, tasksData } = this.props
    dispatch(
      setTasksData({
        ...tasksData,
        docs: [...tasksData.docs, task]
      })
    )
  }

  componentDidUpdate (prevProps, prevState) {
    const { project, dispatch } = this.props
    if (project !== prevProps.project) {
      dispatch(fetchTasks(defaultParams))
    }
  }

  handleTaskPick = task => {
    const { dispatch } = this.props
    dispatch(setPickedTask(task))
  }

  render () {
    const { tasks = [], pickedTask, intl } = this.props
    return (
      <div className='tasks-list'>
        <div className='tasks-list-header'>
          <span>
            {intl.formatMessage({
              id: 'tasks not assigned'
            })}{' '}
          </span>
          <Badge
            count={tasks.length}
            style={{
              backgroundColor: '#fff',
              color: '#999',
              boxShadow: '0 0 0 1px #d9d9d9 inset'
            }}
          />
        </div>
        <Scrollbar style={{ width: '100%', flex: 1 }}>
          <Droppable droppableId='tasks'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {tasks.map((task, index) => (
                  <Draggable
                    key={task && task._id}
                    draggableId={task && task._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className={`task-item ${
                          pickedTask._id === task._id ? 'task-active' : ''
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        onClick={() => this.handleTaskPick(task)}
                      >
                        {task && (
                          <Collapse>
                            <Panel header={task.name} key='1'>
                              <TaskContent task={task} />
                            </Panel>
                          </Collapse>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                <CreateTaskDropDown handleTaskCreation={this.onTaskCreation} />
              </div>
            )}
          </Droppable>
        </Scrollbar>
      </div>
    )
  }
}

TasksList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  tasksData: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  pickedTask: PropTypes.object.isRequired,
  history: PropTypes.object,
  tasks: PropTypes.array
}

const mapStateToProps = state => {
  return {
    tasks: state.tasks.tasksData.docs,
    tasksData: state.tasks.tasksData,
    pickedTask: state.tasks.pickedTask,
    project: state.project.pickedProject
  }
}

export default withRouter(injectIntl(connect(mapStateToProps)(TasksList)))
