import axios from '../../helpers/axios'
import config from 'config'
import HttpClient from './index'

class FieldDefinitionAPI extends HttpClient {
  async fieldsList () {
    const response = await axios.get('/field-definition')
    return response
  }

  async fetchField (id) {
    return axios.get(`/field-definition/${id}`)
  }

  async list (params = {}) {
    return axios.get('/field-definition', params)
  }

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/field-definition', params)
  }

  async save (fieldDefinition) {
    const payload = {
      key: fieldDefinition.key,
      label: fieldDefinition.label,
      type: fieldDefinition.type
    }

    if (fieldDefinition._id) {
      return axios.put(`/field-definition/${fieldDefinition._id}`, {
        label: fieldDefinition.label
      })
    } else {
      return axios.post('/field-definition', payload)
    }
  }

  async delete (id) {
    return axios.delete(`/field-definition/${id}`)
  }
}

export default FieldDefinitionAPI
