import React from 'react'
import PropTypes from 'prop-types'
import { Card, Skeleton } from 'antd'

const CardLayout = ({ label, children, count, style, loading, noNumber }) => {
  return (
    <Card title={label} style={style} bordered={false} className='box'>
      {!noNumber && (
        <span className='bigNumber'>
          <Skeleton loading={loading} active paragraph={{ rows: 0 }}>
            {count}
          </Skeleton>
        </span>
      )}

      {children}
    </Card>
  )
}

CardLayout.propTypes = {
  label: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.any,
  style: PropTypes.object,
  noNumber: PropTypes.bool,
  loading: PropTypes.bool
}

export default CardLayout
