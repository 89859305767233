import types from './constants'

const actions = {
  authToken (token) {
    return {
      type: types.authToken,
      token
    }
  },

  authUser (user) {
    return {
      type: types.authUser,
      user
    }
  },

  authLogout () {
    return {
      type: types.AUTH_LOGOUT
    }
  },

  setSettings (settings) {
    return {
      type: types.SETTINGS,
      settings
    }
  },

  setProject (project) {
    return {
      type: types.PROJECT,
      project
    }
  },

  setProjects (projects) {
    return {
      type: types.PROJECTS,
      projects
    }
  },

  setObjects (objects) {
    return {
      type: types.OBJECTS,
      objects
    }
  },

  setProjectLastUpdated (lastUpdated) {
    return {
      type: types.PROJECT_LAST_UPDATED,
      lastUpdated
    }
  },

  setDefectData (defectData) {
    return {
      type: types.DEFECT_DATA,
      defectData
    }
  },

  setLang (lang) {
    return {
      type: types.LANG,
      lang
    }
  }
}

export default actions
