import React from 'react'
import { notification, Table } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'

import PageTitle from 'components/Global/PageTitle'
import groupService from 'services/group'
import PropTypes from 'prop-types'

export class GroupsPage extends React.Component {
  state = {
    groups: []
  }

  componentDidMount () {
    this.fetchGroups()
  }

  fetchGroups = async () => {
    try {
      const groups = await groupService.list()

      this.setState({
        groups
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'groups'
        })} - download error`
      })
    }
  }

  render () {
    const recordingColumns = [
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name'
      }
    ]

    return (
      <div>
        <FormattedMessage id='head.title.groups'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle title={this.props.intl.formatMessage({ id: 'groups' })} />

        <Table
          columns={recordingColumns}
          dataSource={this.state.groups}
          rowKey={record => record._id}
        />
      </div>
    )
  }
}

GroupsPage.propTypes = {
  intl: PropTypes.object.isRequired
}

export default injectIntl(GroupsPage)
