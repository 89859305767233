import axios from '../../helpers/axios'
import HttpClient from './index'
import config from 'config'

class StatsAPI extends HttpClient {
  async getList () {
    const response = await axios.get('/category')
    return response
  }

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/category', params)
  }
}
export default StatsAPI
