import axios from '../helpers/axios'
import config from 'config'
import pick from 'lodash/pick'

export default {
  async list (params = {}) {
    return axios.get('/contact', params)
  },

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/contact', params)
  },

  async save (contact) {
    const params = pick(contact, [
      'company',
      'website',
      'phone',
      'email',
      'firstName',
      'lastName',
      'street',
      'postcode',
      'city',
      'contact_type',
      'contacts_sub',
      'assignedToCompany'
    ])

    if (contact._id) {
      return axios.put(`/contact/${contact._id}`, params)
    } else {
      return axios.post('/contact', params)
    }
  },

  async remove (contact) {
    return axios.delete(`/contact/${contact._id}`)
  }
}
