import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Button, notification, Form } from 'antd'

import AcceptanceForm from './Form'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete'
import {
  setPickedAcceptance,
  submitPickedAcceptance,
  fetchAcceptance,
  deleteAcceptance
} from '../../../../ducks/acceptance'

export class AcceptancesDrawer extends React.Component {
  _isMounted = false

  handleHideDrawer = () => {
    const { dispatch } = this.props
    dispatch(setPickedAcceptance({}))
  }

  handleSaveAcceptance = async () => {
    const { dispatch } = this.props
    dispatch(submitPickedAcceptance()).then(response => {
      if (!response.error) {
        dispatch(fetchAcceptance())
        dispatch(setPickedAcceptance({}))
        notification.success({
          message: `${this.props.intl.formatMessage({
            id: 'updated successfully'
          })}`
        })
      } else {
        notification.error({
          message: this.props.intl.formatMessage({
            id: 'saving error'
          }),
          description: response.error
        })
      }
    })
  }

  handleDeleteAcceptance = async () => {
    const { dispatch } = this.props
    dispatch(deleteAcceptance()).then(response => {
      if (response.error) {
        notification.error({
          message: this.props.intl.formatMessage({
            id: 'deleting error'
          }),
          description: response.message
        })
      }
    })
  }

  render () {
    const { acceptance } = this.props

    const acceptanceDrawerButtons = [
      <Button key='cancel1' onClick={this.handleHideDrawer}>
        <FormattedMessage id='cancel' />
      </Button>,
      <Button
        key='save2'
        style={{ marginLeft: 8 }}
        type='primary'
        onClick={this.handleSaveAcceptance}
      >
        <FormattedMessage id='save' />
      </Button>
    ]
    if (['admin', 'superadmin'].includes(this.props.auth.authUser.scope[0])) {
      acceptanceDrawerButtons.unshift(
        <PopconfirmDelete
          key='pop3'
          onConfirmAction={this.handleDeleteAcceptance}
        />
      )
    }
    return (
      <div>
        <DrawerComponent
          key='drawer2'
          title={acceptance.name || ''}
          onClose={this.handleHideDrawer}
          visible={!!acceptance._id}
          footerButtons={acceptanceDrawerButtons}
        >
          <AcceptanceForm />
        </DrawerComponent>
      </div>
    )
  }
}

AcceptancesDrawer.propTypes = {
  auth: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  acceptance: PropTypes.object
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    settings: state.settingsReducer,
    objects: state.objectsReducer,
    projects: state.project.projectList,
    acceptance: state.acceptance.pickedAcceptance
  }
}

export default injectIntl(
  Form.create({ name: 'acceptance_form' })(
    connect(mapStateToProps)(AcceptancesDrawer)
  )
)
