import React from 'react'

import moment from 'moment'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Table, Icon, Button, notification, Form, Input } from 'antd'

import UserForm from 'components/User/Form'
import PageTitle from 'components/Global/PageTitle'
import userService from 'services/user'
import DrawerComponent from 'components/Drawer'
import PopconfirmDelete from 'components/Global/PopconfirmDelete/index'
import queryString from 'query-string'

export class UsersPage extends React.Component {
  state = {
    users: [],
    visible: false,
    loading: false,
    pagination: {
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '30', '50', '100']
    },
    user: {},
    new: false,
    params: {},
    submitting: false
  }

  getParams = () => {
    const { history } = this.props
    this._isMounted = true
    const searchString = history.location.search
    let searchQuery
    if (searchString.length) {
      searchQuery = queryString.parse(searchString, { arrayFormat: 'bracket' })
      if (searchQuery.page) {
        searchQuery.offset = searchQuery.page
        delete searchQuery.page
      }
      if (!searchQuery.limit) {
        searchQuery.limit = 10
      }
      if (!searchQuery.sortField && !searchQuery.sortOrder) {
        searchQuery.sortField = 'fullName'
        searchQuery.sortOrder = 'descend'
      }

      return searchQuery
    }
  }

  setParams = paramsObject => {
    const { path, history, project } = this.props
    if (project && project.project) {
      paramsObject.project = project.project._id
    }
    if (paramsObject.offset) {
      paramsObject.page = paramsObject.offset
      delete paramsObject.offset
    }

    if (paramsObject.limit === 10) {
      delete paramsObject.limit
    }
    if (
      (paramsObject.sortField === 'fullName' &&
        paramsObject.sortOrder === 'descend') ||
      !paramsObject.sortOrder
    ) {
      delete paramsObject.sortField
      delete paramsObject.sortOrder
    }

    this.setState({ params: paramsObject })
    history.push({
      pathname: path,
      search: queryString.stringify(paramsObject, { arrayFormat: 'bracket' })
    })
  }

  componentDidMount () {
    this.fetchUsers()
  }

  componentDidUpdate (prevProps, prevState) {
    const {
      location: { search: prevSearch }
    } = prevProps
    const {
      location: { search: nextSearch }
    } = this.props
    if (this.props.project !== prevProps.project) {
      const params = { ...this.getParams() }
      if (this.props.project && this.props.project.project) {
        this.setParams({ ...params, project: this.props.project.project._id })
      } else {
        delete params.project
        this.setParams(params)
      }
    }
    if (prevSearch !== nextSearch || this.props.project !== prevProps.project) {
      this.fetchUsers()
    }
  }

  fetchUsers = async () => {
    const params = this.getParams()
    if (params) {
      delete params.project
    }
    this.setState({
      loading: true
    })
    try {
      const users = await userService.listV2({
        ...params
      })

      const pagination = {
        ...this.state.pagination
      }

      pagination.total = users.totalDocs

      this.setState({
        users: users.docs,
        loading: false,
        pagination: {
          ...pagination,
          pageSize: users.limit,
          current: users.page
        },
        params: { ...params }
      })
    } catch (error) {
      notification.error({
        message: `${this.props.intl.formatMessage({
          id: 'users'
        })} - download error`
      })

      this.setState({
        users: [],
        visible: false,
        loading: false,
        user: {}
      })
    }
  }

  showDrawer = (user, newUser) => {
    delete user.password

    if (newUser) {
      this.setState({
        visible: true,
        new: newUser,
        user: {}
      })
    } else {
      this.setState({
        visible: true,
        new: newUser,
        user: {
          ...user,
          scope: user.scope ? user.scope[0] : null
        }
      })
    }
  }

  handleHideDrawer = () => {
    this.props.form.resetFields()
    this.setState({
      visible: false,
      user: {}
    })
  }

  handleSaveUser = async () => {
    this.props.form.validateFields(async err => {
      if (!err) {
        this.setState({
          submitting: true
        })
        try {
          if (this.state.user.password) {
            if (this.state.user.password !== this.state.user.passwordRetype) {
              this.setState({
                submitting: false
              })
              return notification.error({
                message: this.props.intl.formatMessage({
                  id: 'passwords arent identical'
                })
              })
            }
          }

          if (!this.state.user._id) {
            if (!this.state.user.password) {
              this.setState({
                submitting: false
              })
              return notification.error({
                message: `${this.props.intl.formatMessage({
                  id: 'password'
                })} ${this.props.intl.formatMessage({
                  id: 'is required'
                })}`
              })
            }
          }

          const savedUser = await userService.save(this.state.user)

          this.fetchUsers()
          if (this.state.user._id) {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'updated successfully'
              })}`
            })
          } else {
            notification.success({
              message: `${this.props.intl.formatMessage({
                id: 'created successfully'
              })}`
            })
          }

          this.setState({
            visible: false,
            user: {},
            users: [
              ...this.state.users.filter(user => user._id !== savedUser._id),
              savedUser
            ]
          })
          setTimeout(() => this.setState({ submitting: false }), 1500)

          this.props.form.resetFields()
        } catch (error) {
          this.setState({
            submitting: false
          })
          notification.error({
            message: this.props.intl.formatMessage({
              id: 'saving error'
            }),
            description: error.error
          })
        }
      }
    })
  }

  removeUser = async () => {
    try {
      await userService.remove(this.state.user)

      this.setState({
        visible: false,
        user: {},
        users: [
          ...this.state.users.filter(item => item._id !== this.state.user._id)
        ]
      })
    } catch (error) {
      notification.error({
        message: this.props.intl.formatMessage({
          id: 'deleting error'
        }),
        description: error.error
      })
    }
  }

  updateUser = (field, value) => {
    this.setState(state => ({
      user: {
        ...state.user,
        [field]: value
      }
    }))
  }

  setSelectedKeys = (dataIndex, value, setSelectedKeys) => {
    setSelectedKeys(value ? [value] : [])
    this.setState({
      [dataIndex + 'Search']: value
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this[`searchInput${dataIndex}`] = node
          }}
          placeholder={this.props.intl.formatMessage({ id: 'search' })}
          value={
            this.state[dataIndex + 'Search'] !== undefined
              ? this.state[dataIndex + 'Search']
              : this.state.params[dataIndex]
          }
          onChange={e =>
            this.setSelectedKeys(dataIndex, e.target.value, setSelectedKeys)
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, dataIndex, confirm)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type='primary'
          onClick={() => this.handleSearch(selectedKeys, dataIndex, confirm)}
          icon='search'
          size='small'
          disabled={!this.state[dataIndex + 'Search']}
          style={{ width: 90, marginRight: 8 }}
        >
          Suchen
        </Button>
        <Button
          onClick={() => this.handleReset(dataIndex, clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon
        type='search'
        style={{
          color:
            this.state.params[dataIndex] || filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this[`searchInput${dataIndex}`].select())
      }
    }
  })

  handleSearch = (selectedKeys, field, confirm) => {
    confirm()
    this.setState({
      search: {
        ...this.state.search,
        [field]: selectedKeys[0]
      }
    })
  }

  handleReset = (field, clearFilters) => {
    clearFilters()
    const { params } = this.state
    const outputObject = { ...params }
    delete outputObject[field]
    this.setParams(outputObject)
    this.setState({
      [field + 'Search']: undefined
    })
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.offset = pagination.current
    const params = {
      limit: pagination.pageSize,
      offset: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    }
    this.setParams(params)
  }

  render () {
    const roles = {
      user: 'Standard User',
      admin: 'Administrator',
      superadmin: 'Superadmin'
    }

    const { submitting } = this.state

    const columns = [
      {
        title: 'Name',
        key: 'fullName',
        dataIndex: 'fullName',
        sorter: true,
        ...this.getColumnSearchProps('fullName'),
        defaultSortOrder:
          this.state.params.sortField === 'fullName'
            ? this.state.params.sortOrder
            : 'descend'
      },
      {
        title: 'E-Mail',
        key: 'email',
        dataIndex: 'email',
        ...this.getColumnSearchProps('email')
      },
      {
        title: this.props.intl.formatMessage({ id: 'function' }),
        key: 'function',
        dataIndex: 'function'
      },
      {
        title: this.props.intl.formatMessage({ id: 'role' }),
        key: 'scope',
        render: (text, record) =>
          record.scope.map(item => roles[item] || item).join(', ')
      },
      {
        title: this.props.intl.formatMessage({ id: 'created at' }),
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: true,
        render: createdAt => moment(createdAt).format('DD.MM.YYYY, HH:mm')
      },
      {
        title: this.props.intl.formatMessage({ id: 'last login' }),
        key: 'lastLogin',
        dataIndex: 'lastLogin',
        sorter: true,
        render: lastLogin =>
          lastLogin ? moment(lastLogin).format('DD.MM.YYYY, HH:mm') : '-'
      },
      {
        title: this.props.intl.formatMessage({ id: 'activated' }),
        key: 'activated',
        dataIndex: 'activated',
        align: 'center',
        sorter: true,
        render: (text, record) =>
          record.activated ? (
            <Icon type='check' style={{ color: '#005591' }} />
          ) : (
            <Icon type='close' style={{ color: 'red' }} />
          )
      },
      {
        key: 'actions',
        render: (text, record) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'flex-end'
            }}
          >
            <Button onClick={() => this.showDrawer(record, false)}>
              <Icon type='edit' />
            </Button>
          </div>
        )
      }
    ]

    const headerButtons = [
      <Button
        key={89456547848}
        type='primary'
        onClick={() => this.showDrawer({}, true)}
      >
        <FormattedMessage id='create user' />
      </Button>
    ]

    const footerDrawerButtons = [
      <Button
        key={0}
        onClick={this.handleHideDrawer}
        style={{ marginRight: 8 }}
      >
        <FormattedMessage id='cancel' />
      </Button>
    ]

    if (this.state.new) {
      footerDrawerButtons.push(
        <Button
          loading={submitting}
          disabled={submitting}
          key={1}
          onClick={this.handleSaveUser}
          type='primary'
        >
          <FormattedMessage id='create account' />
        </Button>
      )
    } else {
      footerDrawerButtons.push(
        <Button
          key={2}
          onClick={this.handleSaveUser}
          type='primary'
          loading={submitting}
          disabled={submitting}
        >
          <FormattedMessage id='update account' />
        </Button>
      )
    }

    if (
      ['superadmin'].includes(this.props.auth.authUser.scope[0]) &&
      this.state.user &&
      !this.state.new
    ) {
      footerDrawerButtons.unshift(
        <PopconfirmDelete key={3} onConfirmAction={() => this.removeUser()} />
      )
    }

    return (
      <div>
        <FormattedMessage id='head.title.users'>
          {title => (
            <Helmet>
              <title>{title}</title>
            </Helmet>
          )}
        </FormattedMessage>

        <PageTitle
          title={this.props.intl.formatMessage({ id: 'users' })}
          buttons={headerButtons}
        />

        <Table
          columns={columns}
          dataSource={this.state.users}
          rowKey={record => record._id}
          loading={this.state.loading}
          onRow={record => ({
            onDoubleClick: () => {
              this.showDrawer(record)
            }
          })}
          onChange={this.handleTableChange}
          defaultPageSize={this.state.pagination.defaultPageSize}
          pagination={this.state.pagination}
        />

        <DrawerComponent
          title={
            this.state.new === true
              ? this.props.intl.formatMessage({
                  id: 'create a new account'
                })
              : this.props.intl.formatMessage({
                  id: 'update account'
                })
          }
          onClose={this.handleHideDrawer}
          visible={this.state.visible}
          footerButtons={footerDrawerButtons}
        >
          <UserForm
            user={this.state.user}
            updateUser={this.updateUser}
            roles={roles}
            form={this.props.form}
          />
        </DrawerComponent>
      </div>
    )
  }
}

UsersPage.propTypes = {
  auth: PropTypes.object,
  project: PropTypes.object,
  history: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  project: state.project
})

export default injectIntl(
  Form.create({ name: 'users_form' })(connect(mapStateToProps)(UsersPage))
)
