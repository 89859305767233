import React from 'react'
import { injectIntl } from 'react-intl'

import { connect } from 'react-redux'
import { Form, Select, AutoComplete, Spin } from 'antd'
import PropTypes from 'prop-types'

import { updatePickedRecording } from '../../../../../ducks/recordings'
import { fetchContractSectionList } from '../../../../../ducks/contractSections'
import debounce from 'lodash/debounce'
const { Option } = Select

export class AcceptanceContractSectionField extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      defectPage: false
    }
    this.handleInputThrottled = debounce(this.fetchContractSections, 300)
  }

  handleFieldChange = (key, value) => {
    const { dispatch } = this.props
    dispatch(updatePickedRecording({ [key]: value }))
  }

  fetchContractSections = searchQueue => {
    const { dispatch } = this.props
    this.setState({
      loading: true
    })
    dispatch(fetchContractSectionList({ 'name[]': searchQueue }, true)).then(
      response => {
        this.setState({
          loading: false
        })
      }
    )
  }

  render () {
    const {
      readOnly,
      formItemLayout,
      recording,
      intl,
      contractSections
    } = this.props
    const { loading } = this.state

    return (
      <Form.Item
        {...formItemLayout}
        label={intl.formatMessage({
          id: 'contract section'
        })}
      >
        <div className='certain-category-search-wrapper'>
          <AutoComplete
            allowClear
            className='certain-category-search'
            dropdownClassName='certain-category-search-dropdown'
            dropdownMatchSelectWidth={false}
            dropdownStyle={{ width: 300 }}
            style={{ width: '100%' }}
            optionLabelProp='value'
            notFoundContent={
              loading ? (
                <div className='recordings-spinner-container'>
                  <Spin size='small' />
                </div>
              ) : null
            }
            filterOption={(inputValue, option) =>
              option.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
            defaultValue={
              recording.contractSection && recording.contractSection.name
            }
            onFocus={() => this.fetchContractSections('')}
            onSearch={value => this.handleInputThrottled(value)}
            onChange={(value, event) =>
              this.handleFieldChange('contractSection', event.props.data)
            }
            disabled={readOnly}
          >
            {contractSections.map(object => (
              <Option key={object._id} data={object} value={object.name || ''}>
                {object.name}
              </Option>
            ))}
          </AutoComplete>
        </div>
      </Form.Item>
    )
  }
}

AcceptanceContractSectionField.propTypes = {
  recording: PropTypes.object,
  intl: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  formItemLayout: PropTypes.object,
  contractSections: PropTypes.array.isRequired,
  readOnly: PropTypes.bool
}

const mapStateToProps = state => ({
  recording: state.recordings.pickedRecording,
  projects: state.project.searchedProjectsList,
  contractSections: state.contractSections.searchedContractSections
})

export default injectIntl(
  connect(mapStateToProps, null)(AcceptanceContractSectionField)
)
