import React from 'react'
import BodyClassName from 'react-body-classname'
import Iframe from 'react-iframe'
import styled from 'styled-components'

const StyledBodyClassName = styled(BodyClassName)`
  .ant-layout-header {
    display: none;
  }
  .ant-layout-content > div {
    height: 100vh;
  }

  main > div {
    padding: 0 !important;
    margin: 0 !important;
  }

  .iframe {
    border: 0;
  }
`

export class DOCLinkPage extends React.Component {
  render () {
    return (
      <StyledBodyClassName className='module--doclink'>
        <Iframe
          url='https://doclink.gabo-idm.de'
          width='100%'
          height='100%'
          id='myId'
          className='iframe'
          display='initial'
          position='relative'
        />
      </StyledBodyClassName>
    )
  }
}

export default DOCLinkPage
