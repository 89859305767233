import axios from '../../helpers/axios'
import HttpClient from './index'

class StatsAPI extends HttpClient {
  create (data) {
    return axios.post('/file', data)
  }

  delete (id) {
    return axios.delete(`/file/${id}`)
  }

  update (id, data) {
    return axios.put(`/file/${id}`, data)
  }
}
export default StatsAPI
