import React from 'react'
import PropTypes from 'prop-types'
import { Form, Checkbox, Select, Input, AutoComplete } from 'antd'
import { injectIntl } from 'react-intl'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'

import { fetchProjectList } from '../../../../ducks/project'
import { fetchLocations } from '../../../../ducks/locations'
import { updatePickedAcceptance } from '../../../../ducks/acceptance'

const { Option } = Select

class AcceptanceForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
    this.handleInputThrottled = debounce(this.fetchDataOnSearch, 300)
  }

  handleFieldChange = (key, value) => {
    const { dispatch } = this.props
    if (key === 'location') {
      dispatch(
        updatePickedAcceptance({ [key]: value, locationName: undefined })
      )
    } else {
      dispatch(updatePickedAcceptance({ [key]: value }))
    }
  }

  fetchDataOnSearch = (field, searchQueue) => {
    const { dispatch } = this.props
    this.setState({
      loading: true
    })
    if (field === 'project') {
      dispatch(fetchProjectList({ 'projectName[]': searchQueue }, true)).then(
        () => {
          this.setState({
            loading: false
          })
        }
      )
    } else {
      dispatch(fetchLocations({ 'name[]': searchQueue }, true)).then(() => {
        this.setState({
          loading: false
        })
      })
      dispatch(updatePickedAcceptance({ locationName: searchQueue }))
    }
  }

  render () {
    const { acceptance, locations, projects, intl } = this.props
    const locationName = acceptance.location ? acceptance.location.name : ''

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      className: 'formItem'
    }

    return (
      <>
        <Form.Item {...formItemLayout} label='Name'>
          <Input
            value={acceptance.name}
            onChange={event =>
              this.handleFieldChange('name', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'project'
          })}
        >
          <Select
            showSearch
            allowClear
            style={{ width: '100%' }}
            optionFilterProp='children'
            onFocus={() => this.fetchDataOnSearch('project', '')}
            onSearch={value => this.handleInputThrottled('project', value)}
            onChange={(projectId, event) =>
              this.handleFieldChange('project', event.props.data)
            }
            defaultValue={
              acceptance.project ? acceptance.project.projectName : ''
            }
          >
            {projects.map(project => (
              <Select.Option
                data={project}
                key={project.projectName + project._id}
                value={project._id}
              >
                {project.projectName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={this.props.intl.formatMessage({
            id: 'participant'
          })}
        >
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            value={this.props.acceptance.participant}
            onChange={event =>
              this.handleFieldChange('participant', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={this.props.intl.formatMessage({
            id: 'location'
          })}
        >
          <div className='certain-category-search-wrapper'>
            <AutoComplete
              showSearch
              allowClear
              className='certain-category-search'
              dropdownClassName='certain-category-search-dropdown'
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ width: 300 }}
              style={{ width: '100%' }}
              // dataSource={locations}
              optionLabelProp='value'
              // filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              defaultValue={locationName}
              onFocus={() => this.fetchDataOnSearch('location', '')}
              onSearch={value => this.handleInputThrottled('location', value)}
              onChange={(value, event) =>
                this.handleFieldChange(
                  'location',
                  (event && event.props.data) || ''
                )
              }
            >
              {locations.map((location, i) => (
                <Option
                  key={i + location._id}
                  data={location}
                  value={location.name || ''}
                >
                  {location.name}
                </Option>
              ))}
            </AutoComplete>
          </div>
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'archived'
          })}
        >
          <Checkbox
            checked={acceptance.archived}
            onChange={event =>
              this.handleFieldChange('archived', event.target.checked)
            }
          />
        </Form.Item>
      </>
    )
  }
}

AcceptanceForm.propTypes = {
  acceptance: PropTypes.object,
  intl: PropTypes.object.isRequired,
  formLoading: PropTypes.bool,
  locations: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  acceptance: state.acceptance.pickedAcceptance,
  projects: state.project.searchedProjectsList,
  locations: state.locations.searchedLocationsList
})

export default injectIntl(connect(mapStateToProps, null)(AcceptanceForm))
