import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

const PublicRoute = ({ component: Component, layout: Layout }) => (
  <Route
    render={props => {
      if (!Layout) {
        return <Component {...props} />
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

PublicRoute.propTypes = {
  layout: PropTypes.object,
  component: PropTypes.func
}

export default PublicRoute
