import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Form, Input, Button } from 'antd'
import PropTypes from 'prop-types'

const { TextArea } = Input

const Editor = ({
  onChange,
  onSubmit,
  onCancel,
  submitting,
  disabled,
  value,
  edit,
  intl
}) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        disabled={disabled}
        htmlType='submit'
        loading={submitting}
        onClick={onSubmit}
        type='primary'
      >
        {edit
          ? intl.formatMessage({ id: 'edit comment' })
          : intl.formatMessage({ id: 'add comment' })}
      </Button>
      {edit && (
        <Button
          style={{ marginLeft: 8 }}
          loading={submitting}
          onClick={onCancel}
        >
          {intl.formatMessage({ id: 'cancel' })}
        </Button>
      )}
    </Form.Item>
  </div>
)

Editor.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  edit: PropTypes.bool,
  submitting: PropTypes.bool,
  intl: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default injectIntl(connect(mapStateToProps)(Editor))
