import HttpClient from './index'
import config from 'config'

class WorkorderAPI extends HttpClient {
  async list (params = {}) {
    return this.get('/workorder', params)
  }

  async listV2 (params = {}) {
    return this.get(config.server.host + '/1.1/workorder', params)
  }

  async addWorkOrder (data) {
    return this.post('/workorder', data)
  }

  async updateWorkOrder (id, data) {
    return this.put(`/workorder/${id}`, data)
  }

  async deleteWorkOrder (id) {
    return this.delete(`/workorder/${id}`)
  }

  async addImage (id, data) {
    return this.post(`/workorder/${id}/image`, data)
  }

  async addFile (id, data) {
    return this.post(`/workorder/${id}/file`, data)
  }

  async addComment (id, data) {
    return this.post(`/workorder/${id}/comment`, data)
  }

  async addTask (id, task) {
    return this.post(`/workorder/${id}/task-add`, { task })
  }

  async moveTask (data) {
    return this.post(`/workorder/task-move`, data)
  }

  async deleteTask (id, task) {
    return this.post(`/workorder/${id}/task-delete`, { task })
  }
}
export default WorkorderAPI
