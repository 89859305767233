import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Form, Button, PageHeader } from 'antd'
import SortableList from '../../SortableHOC/SortableHOC'
import PropTypes from 'prop-types'

class TaskEdit extends React.Component {
  handleAddTask = () => {
    this.props.addShareholder()
  }

  render () {
    const {
      scheduled,
      action,
      intl,
      form,
      auth,
      recordingIdSearchResult,
      formLoading,
      dataXEnabled,
      extraFieldsForm,
      objectName,
      shareholders,
      objects,
      recording,
      handleRecordingSelect,
      objectIdSearchResult,
      removeTagHandler,
      clearTagHandler,
      taskIdChange,
      handleTaskIdSelectChange,
      handleTaskIdChangeAutocomplete,
      handleSearchObjectId,
      handleSearchRecordingId,
      removeTaskholder,
      onSortTasksEnd,
      markAsDoneTask,
      showModalComment,
      hideModalComment,
      recordingDrawerVisible,
      recordingDrawerShow,
      recordingDrawerHide,
      modalCommentActive
    } = this.props

    return (
      <>
        <Form className='workorder-task-edit-mode'>
          <PageHeader
            onBack={action}
            title={intl.formatMessage({ id: 'Edit tasks' })}
          />

          <SortableList
            items={shareholders}
            objects={objects}
            recording={recording}
            formLoading={formLoading}
            form={form}
            auth={auth}
            objectName={objectName}
            dataXEnabled={dataXEnabled}
            extraFieldsForm={extraFieldsForm}
            recordingIdSearchResult={recordingIdSearchResult}
            handleRecordingSelect={handleRecordingSelect}
            objectIdSearchResult={objectIdSearchResult}
            removeTagHandler={removeTagHandler}
            clearTagHandler={clearTagHandler}
            taskIdChange={taskIdChange}
            handleTaskIdSelectChange={handleTaskIdSelectChange}
            handleTaskIdChangeAutocomplete={handleTaskIdChangeAutocomplete}
            handleSearchObjectId={handleSearchObjectId}
            handleSearchRecordingId={handleSearchRecordingId}
            removeTaskholder={removeTaskholder}
            onSortEnd={onSortTasksEnd}
            markAsDoneTask={markAsDoneTask}
            showModalComment={showModalComment}
            hideModalComment={hideModalComment}
            recordingDrawerVisible={recordingDrawerVisible}
            recordingDrawerShow={recordingDrawerShow}
            recordingDrawerHide={recordingDrawerHide}
            modalCommentActive={modalCommentActive}
            helperClass='task-ghost-helper'
            transitionDuration={5}
            useDragHandle
            scheduled={scheduled}
          />

          <div className='add-worker-order-task-wrap'>
            <Button type='primary' onClick={this.handleAddTask}>
              <FormattedMessage id='Add task' />
            </Button>
          </div>
        </Form>
      </>
    )
  }
}

TaskEdit.propTypes = {
  scheduled: PropTypes.bool,
  action: PropTypes.func,
  intl: PropTypes.object.isRequired,
  form: PropTypes.object,
  auth: PropTypes.object,
  recordingIdSearchResult: PropTypes.array,
  formLoading: PropTypes.bool,
  dataXEnabled: PropTypes.bool,
  extraFieldsForm: PropTypes.object,
  objectName: PropTypes.string,
  shareholders: PropTypes.array,
  objects: PropTypes.array,
  recording: PropTypes.object,
  handleRecordingSelect: PropTypes.func.isRequired,
  objectIdSearchResult: PropTypes.array,
  removeTagHandler: PropTypes.func,
  clearTagHandler: PropTypes.func,
  taskIdChange: PropTypes.func.isRequired,
  handleTaskIdSelectChange: PropTypes.func.isRequired,
  handleTaskIdChangeAutocomplete: PropTypes.func.isRequired,
  handleSearchObjectId: PropTypes.func.isRequired,
  handleSearchRecordingId: PropTypes.func.isRequired,
  removeTaskholder: PropTypes.func.isRequired,
  onSortTasksEnd: PropTypes.func.isRequired,
  markAsDoneTask: PropTypes.func.isRequired,
  showModalComment: PropTypes.func.isRequired,
  hideModalComment: PropTypes.func.isRequired,
  recordingDrawerVisible: PropTypes.bool,
  recordingDrawerShow: PropTypes.func,
  recordingDrawerHide: PropTypes.func,
  addShareholder: PropTypes.func.isRequired,
  modalCommentActive: PropTypes.string
}

export default injectIntl(connect()(TaskEdit))
