import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import objectService from 'services/object'
import contractSectionService from 'services/contractSection'
import { Form, Icon, Input, InputNumber, Select, Tag, Tooltip } from 'antd'

import { connect } from 'react-redux'
import ContactSelect from '../../commonComponents/ContactSelect'

const { Option } = Select

const statusOptions = ['open', 'in progress', 'completed']

const groupData = [
  'Kabel',
  'Leittechnik',
  'Materialschaden / falsches Material',
  'falsche Montage',
  'Beschriftung',
  'Brandschottung',
  'Doppelboden',
  'Reinigung',
  'Dokumentation',
  'Schema',
  'Schäden',
  'No-Break',
  'Andere',
  'Zu klären / fertigstellen',
  'Altlasten',
  'Baufehler'
]

class DefectForm extends React.Component {
  state = {
    objects: [],
    projects: [],
    contractSections: [],
    contacts: []
  }

  fetchData = async (value, key) => {
    this.setState({
      formLoading: true
    })
    const { projects } = this.props

    const timer = setTimeout(async () => {
      try {
        let data
        if (key === 'projects') {
          data = projects.filter(
            item =>
              item.projectName
                .toLowerCase()
                .indexOf(value.toString().toLowerCase()) > -1
          )
        } else if (key === 'objects') {
          data = await objectService.listV2({
            name: [value],
            limit: 100
          })
        } else if (key === 'contractSections') {
          data = await contractSectionService.listV2({
            name: [value],
            limit: 100
          })
        }
        this.setState({
          [key]: data.docs || data,
          formLoading: false
        })

        clearTimeout(timer)
      } catch (error) {
        this.setState({
          [key]: [],
          formLoading: false
        })
        clearTimeout(timer)
      }
    })
  }

  render () {
    const {
      form: { getFieldDecorator },
      defect,
      intl,
      updateItem,
      readOnlyNewProject,
      handleRefClick,
      objectName,
      defect: { assignedTo }
    } = this.props

    const { objects, projects, contractSections } = this.state

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    }
    return (
      <Form {...formItemLayout}>
        <Form.Item label='Name'>
          {getFieldDecorator('name', {
            initialValue: defect.name,
            rules: [
              {
                required: true,
                message: this.props.intl.formatMessage({
                  id: 'field is required'
                })
              }
            ]
          })(
            <Input
              placeholder={intl.formatMessage({ id: 'name' })}
              onChange={event => updateItem('name', event.target.value)}
            />
          )}
        </Form.Item>

        <Form.Item label={objectName}>
          {getFieldDecorator('objectNumber', {
            initialValue: defect.objectId
              ? defect.objectId.projectName || defect.objectId.name
              : undefined,
            rules: [
              {
                required: !defect._id,
                message: this.props.intl.formatMessage({
                  id: 'field is required'
                })
              }
            ]
          })(
            <Select
              showSearch
              allowClear
              placeholder={intl.formatMessage({
                id: 'choose an objectnumber'
              })}
              style={{ width: '100%' }}
              optionFilterProp='children'
              onFocus={() => this.fetchData('', 'objects')}
              onChange={objectId => updateItem('objectId', objectId)}
              onSearch={value => this.fetchData(value, 'objects')}
            >
              {objects.map(object => (
                <Option key={object._id} value={object._id}>
                  {object.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={intl.formatMessage({ id: 'project' })}>
          <Select
            showSearch
            allowClear
            placeholder={intl.formatMessage({ id: 'choose a project' })}
            style={{ width: '100%' }}
            optionFilterProp='children'
            onFocus={() => this.fetchData('', 'projects')}
            onSearch={value => this.fetchData(value, 'objects')}
            onChange={projectId => updateItem('project', projectId || null)}
            defaultValue={defect.project ? defect.project.projectName : ''}
            disabled={readOnlyNewProject}
          >
            {projects.map(project => (
              <Option key={project._id} value={project._id}>
                {project.projectName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={intl.formatMessage({ id: 'contract section' })}>
          <Select
            showSearch
            allowClear
            placeholder={intl.formatMessage({
              id: 'choose a contract section'
            })}
            style={{ width: '100%' }}
            optionFilterProp='children'
            onFocus={() => this.fetchData('', 'contractSections')}
            onSearch={value => this.fetchData(value, 'contractSections')}
            defaultValue={defect.contractSection && defect.contractSection.name}
            onChange={contractSectionId =>
              updateItem('contractSection', contractSectionId)
            }
          >
            {contractSections.map(contractSection => (
              <Option key={contractSection._id} value={contractSection._id}>
                {contractSection.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'assignedTo'
          })}
        >
          {getFieldDecorator('assignedTo')(
            <ContactSelect
              pickContact={contact => updateItem('assignedTo', contact)}
              currentValue={assignedTo}
            />
          )}
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'group'
          })}
        >
          {getFieldDecorator('group', {
            initialValue: defect.group ? defect.group : undefined
          })(
            <Select
              placeholder={intl.formatMessage({ id: 'choose a group' })}
              onChange={group => updateItem('group', group)}
            >
              {groupData.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={intl.formatMessage({ id: 'actual status' })}>
          {getFieldDecorator('actualSituation', {
            initialValue: defect.actualSituation
              ? defect.actualSituation
              : undefined
          })(
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 6 }}
              placeholder={intl.formatMessage({ id: 'actualSituation' })}
              value={defect.actualSituation}
              onChange={event =>
                updateItem('actualSituation', event.target.value)
              }
            />
          )}
        </Form.Item>

        <Form.Item label={intl.formatMessage({ id: 'target status' })}>
          {getFieldDecorator('targetSituation', {
            initialValue: defect.targetSituation ? defect.targetSituation : ''
          })(
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 6 }}
              placeholder={intl.formatMessage({ id: 'targetSituation' })}
              value={defect.targetSituation}
              onChange={event =>
                updateItem('targetSituation', event.target.value)
              }
            />
          )}
        </Form.Item>

        <Form.Item label='Status'>
          {getFieldDecorator('status', {
            initialValue: defect.status ? defect.status : undefined
          })(
            <Select
              style={{
                width: '100%'
              }}
              placeholder={intl.formatMessage({ id: 'choose a status' })}
              allowClear
              onChange={status => updateItem('status', status)}
            >
              {statusOptions.map((item, index) => (
                <Option key={index} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: 'estimated_cost'
          })}
        >
          {getFieldDecorator('estimated_cost', {
            initialValue: defect.estimated_cost || ''
          })(
            <InputNumber
              onChange={value => updateItem('estimated_cost', value)}
              value={defect.estimated_cost}
              style={{ width: '100%' }}
              formatter={value =>
                `CHF ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '')
              }
              // eslint-disable-next-line
              parser={value => value.replace(' ', '').match(/[0-9 , \.]+/g)}
            />
          )}
        </Form.Item>

        {defect._id && (
          <Form.Item
            label={intl.formatMessage({
              id: 'reference'
            })}
          >
            {defect.recording && defect.recording._id && (
              <div>
                {defect.recording && defect.recording.docNumber && (
                  <Tag
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleRefClick(defect)}
                    color='blue'
                  >
                    <Icon type='check' />
                    &nbsp;
                    {defect.recording && defect.recording.docNumber}
                  </Tag>
                )}
              </div>
            )}
          </Form.Item>
        )}
        <Form.Item
          label={
            <span>
              {intl.formatMessage({ id: 'attached document' })} &nbsp;
              <Tooltip
                placement='top'
                title={<FormattedMessage id='will be attached' />}
              >
                <Icon type='info-circle' />
              </Tooltip>
            </span>
          }
        >
          <Select
            style={{
              width: '100%'
            }}
            placeholder={intl.formatMessage({ id: 'choose document' })}
            allowClear
            value={
              defect.attachedDocument
                ? defect.attachedDocument.filename
                : undefined
            }
            onChange={(status, event) =>
              updateItem('attachedDocument', event ? event.props.data : null)
            }
          >
            {(defect.files || [])
              .filter(item => item.type && item.type.indexOf('pdf') > -1)
              .map((item, index) => (
                <Option key={index} data={item} value={item._id}>
                  {item.filename}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    )
  }
}

DefectForm.propTypes = {
  updateItem: PropTypes.func.isRequired,
  objectName: PropTypes.string,
  project: PropTypes.object,
  projects: PropTypes.array,
  defect: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  readOnlyNewProject: PropTypes.bool,
  handleRefClick: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    objectName:
      (state.settings.data.buildx && state.settings.data.buildx.objectName) ||
      ''
  }
}

export default injectIntl(connect(mapStateToProps)(DefectForm))
