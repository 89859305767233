import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ConfigProvider } from 'antd'
import antDE from 'antd/lib/locale-provider/de_DE'
import antEN from 'antd/lib/locale-provider/en_US'
import { IntlProvider } from 'react-intl'
import messages from './translations'
import { fetchSettings } from './ducks/settings'
import { fetchUser } from './ducks/login'
import Routes from './routes'
import 'react-medium-image-zoom/dist/styles.css'

const translations = {
  de: {
    messages: messages.de,
    ant: antDE
  },
  en: {
    messages: messages.en,
    ant: antEN
  }
}

const App = props => {
  const { dispatch, settings, auth } = props

  useEffect(() => {
    const token = localStorage.getItem('authToken')
    if (token) {
      dispatch(fetchSettings())
      dispatch(fetchUser())
    }
  }, [])

  let lang = 'de'
  if (settings && settings.lang === 'en') {
    lang = 'en'
  }
  const project =
    props.project && props.project.project
      ? props.project.project._id
      : undefined

  return (
    <IntlProvider locale={lang} messages={translations[lang].messages}>
      <ConfigProvider locale={translations[lang].ant}>
        <Routes auth={auth} component={App} project={project} />
      </ConfigProvider>
    </IntlProvider>
  )
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  auth: PropTypes.object,
  project: PropTypes.object,
  settings: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    project: state.project,
    settings: state.settings
  }
}

export default connect(mapStateToProps)(App)
