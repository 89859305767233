import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Icon, Upload } from 'antd'
const { Dragger } = Upload

class DropzoneComponent extends Component {
  customRequest = async () => {
    const { handleUpload } = this.props
    await new Promise(resolve => this.waitUntilImageLoaded(resolve)) // in the next section
    const { image } = this.state
    handleUpload([image])
    this.setState({
      image: undefined
    })
  }

  waitUntilImageLoaded = resolve => {
    setTimeout(() => {
      this.state.image
        ? resolve() // from onChange method
        : this.waitUntilImageLoaded(resolve)
    }, 10)
  }

  render () {
    const { intl } = this.props
    const draggerProps = {
      listType: 'picture',
      beforeUpload: file =>
        this.setState(state => ({
          image: file
        })),
      fileList: [],
      accept: '.png, .jpg, .jpeg'
    }
    return (
      <div className='images-dragger-cover'>
        <Dragger {...draggerProps} customRequest={this.customRequest}>
          <p className='ant-upload-drag-icon'>
            <Icon type='inbox' />
          </p>
          <p className='ant-upload-text'>
            {intl.formatMessage({ id: 'clickToUpload' })}
          </p>
          <p className='ant-upload-hint'>
            {intl.formatMessage({ id: 'fileSupportInfo' })}
          </p>
        </Dragger>
      </div>
    )
  }
}

DropzoneComponent.propTypes = {
  handleUpload: PropTypes.func,
  intl: PropTypes.object.isRequired
}

export default injectIntl(DropzoneComponent)
