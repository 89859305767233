import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Form, Input, Select } from 'antd'

import useApi from '../../../hooks/useApi'
import projectService from '../../../services/project'

const { Item } = Form
const { Option } = Select

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0.6rem;
  }
`

const FormManufacturer = ({ form, manufacturer }) => {
  const intl = useIntl()
  const { getFieldDecorator } = form
  const [loadingProjects, fetchProjects] = useApi(projectService.listV2)

  const {
    _id,
    name,
    city,
    email,
    phone,
    street,
    website,
    project,
    country,
    postcode
  } = manufacturer || {}

  _id && getFieldDecorator('_id', { initialValue: _id })

  const [projects, setProjects] = useState([])
  const selectedProject = useSelector(
    ({ project: { pickedProject } }) => pickedProject
  )

  const loadProjects = async (value = '') => {
    if (!!selectedProject._id && !project) {
      return setProjects([selectedProject])
    }

    const { docs = [] } = await fetchProjects({
      projectName: [value],
      limit: 100
    })

    if (project && !docs.some(({ _id }) => _id === project._id)) {
      docs.push(project)
    }

    setProjects(docs)
  }

  useEffect(() => {
    loadProjects()
  }, [])

  return (
    <StyledForm {...formLayout}>
      <Item label={intl.formatMessage({ id: 'name' })}>
        {getFieldDecorator('name', {
          initialValue: name,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'name is required'
              })
            }
          ]
        })(
          <Input
            placeholder={intl.formatMessage({
              id: 'set a name'
            })}
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'project' })}>
        {getFieldDecorator('project', {
          initialValue: project ? project._id : selectedProject._id,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'project is required'
              })
            }
          ]
        })(
          <Select
            showSearch
            allowClear
            filterOption={false}
            loading={loadingProjects}
            style={{ width: '100%' }}
            placeholder={intl.formatMessage({
              id: 'choose a project'
            })}
            onSearch={debounce(loadProjects, 500)}
            disabled={!!selectedProject._id && !project}
          >
            {projects.map(({ _id, projectName }) => (
              <Option key={_id}>{projectName}</Option>
            ))}
          </Select>
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'street' })}>
        {getFieldDecorator('street', { initialValue: street })(
          <Input
            placeholder={intl.formatMessage({
              id: 'set a street'
            })}
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'postcode' })}>
        {getFieldDecorator('postcode', { initialValue: postcode })(
          <Input
            placeholder={intl.formatMessage({
              id: 'set a postcode'
            })}
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'city' })}>
        {getFieldDecorator('city', { initialValue: city })(
          <Input
            placeholder={intl.formatMessage({
              id: 'set a city'
            })}
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'country' })}>
        {getFieldDecorator('country', { initialValue: country })(
          <Input
            placeholder={intl.formatMessage({
              id: 'set a country'
            })}
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'website' })}>
        {getFieldDecorator('website', {
          initialValue: website,
          rules: [
            {
              type: 'url'
            }
          ]
        })(
          <Input
            placeholder={intl.formatMessage({
              id: 'set a website'
            })}
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'email' })}>
        {getFieldDecorator('email', {
          initialValue: email,
          rules: [
            {
              type: 'email',
              required: true,
              message: intl.formatMessage({
                id: 'email is required'
              })
            }
          ]
        })(
          <Input
            placeholder={intl.formatMessage({
              id: 'set email'
            })}
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'phone' })}>
        {getFieldDecorator('phone', { initialValue: phone })(
          <Input
            placeholder={intl.formatMessage({
              id: 'set phone'
            })}
          />
        )}
      </Item>
    </StyledForm>
  )
}

FormManufacturer.propTypes = {
  manufacturer: PropTypes.object,
  form: PropTypes.object.isRequired
}

export default Form.create({ name: 'form_manufacturer' })(FormManufacturer)
