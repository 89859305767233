import config from 'config'
import axios from '../helpers/axios'

export default {
  async getList () {
    const response = await axios.get('/category')
    return response
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/category', params)
  },
  async addCategory (data) {
    const response = await axios.post('/category', data)
    return response
  },
  async deleteCategory (id) {
    const response = await axios.delete(`/category/${id}`)
    return response
  },
  async updateCategory (id, data) {
    const response = await axios.put(`/category/${id}`, data)
    return response
  }
}
