import React, { useState } from 'react'
import { Result } from 'antd'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import FormForgotPassword from './Form/FormForgotPassword'

const ForgotPasswordPage = () => {
  const intl = useIntl()
  const [showResult, setShowResult] = useState(null)

  const handleFinishAction = status => {
    setShowResult(status)
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>

      {!showResult && (
        <h4>
          <FormattedMessage id='forgot password' />
        </h4>
      )}

      {!showResult && <FormForgotPassword finishAction={handleFinishAction} />}

      {showResult === 'success' && (
        <Result
          status='success'
          title={intl.formatMessage({ id: 'email sent' })}
          subTitle={intl.formatMessage({
            id:
              'if the user was found in our database, we have sent an email to the provided address.'
          })}
        />
      )}

      {showResult === 'error' && (
        <Result
          status='error'
          title={intl.formatMessage({ id: 'something went wrong.' })}
          subTitle={intl.formatMessage({
            id: 'something went wrong. please contact DDC Schweiz AG'
          })}
        />
      )}

      <Link to='/login'>
        <FormattedMessage id='login' />
      </Link>
    </>
  )
}

export default connect()(ForgotPasswordPage)
