import axios from '../helpers/axios'
import config from 'config'

export default {
  async getList () {
    const response = await axios.get('/request')
    return response
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/request', params)
  },
  async addRequest (data) {
    const response = await axios.post('/request', data)
    return response
  },
  async updateRequest (id, data) {
    const response = await axios.put(`/request/${id}`, data)
    return response
  }
}
