import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'

import moment from 'moment'
import { Icon, Comment, Avatar, Tooltip } from 'antd'
import commentService from 'services/comment'
import Editor from './Editor'
import PopconfirmDelete from '../Global/PopconfirmDelete'
import PropTypes from 'prop-types'

class UpdatesTab extends React.Component {
  state = {
    addCommentValue: '',
    submitting: false,
    editingId: '',
    editValue: undefined
  }

  handleChange = (value, key) => {
    this.setState({
      [key]: value
    })
  }

  handleSubmit = () => {
    const { data, updateItem, service, addComment } = this.props
    const { addCommentValue } = this.state
    this.setState({
      submitting: true
    })

    if (!service) {
      addComment(data._id, { text: addCommentValue })
        .then(response => {
          // updateItem('comments', [response, ...data.comments])
          this.setState({
            submitting: false,
            addCommentValue: ''
          })
        })
        .catch(error => {
          console.log(error)
          this.setState({
            submitting: false
          })
        })
      return
    }
    service
      .addComment(data._id, { text: addCommentValue })
      .then(response => {
        updateItem('comments', [response, ...data.comments])
        this.setState({
          submitting: false,
          addCommentValue: ''
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({
          submitting: false
        })
      })
  }

  handleCommentEdit = () => {
    const { data, updateItem } = this.props
    const { editValue, editingId } = this.state
    this.setState({
      submitting: true
    })
    commentService
      .update(editingId, { text: editValue })
      .then(response => {
        updateItem('comments', [
          ...data.comments.map(item =>
            item._id === response._id ? response : item
          )
        ])
        this.setState({
          editValue: undefined,
          editingId: '',
          submitting: false
        })
      })
      .catch(error => {
        console.log(error)
        this.setState({
          submitting: false
        })
      })
  }

  handleCommentDelete = deleteId => {
    const { data, updateItem } = this.props
    commentService
      .delete(deleteId)
      .then(() => {
        updateItem('comments', [
          ...data.comments.filter(item => item._id !== deleteId)
        ])
      })
      .catch(error => {
        console.log(error)
        this.setState({
          submitting: false
        })
      })
  }

  render () {
    const {
      data,
      auth: { authUser },
      intl,
      disabled
    } = this.props
    const { editingId, editValue, submitting, addCommentValue } = this.state

    return (
      <>
        <div>
          <Comment
            avatar={<Avatar icon='user' />}
            content={
              <Editor
                onChange={event =>
                  this.handleChange(event.target.value, 'addCommentValue')
                }
                onSubmit={this.handleSubmit}
                submitting={submitting}
                value={addCommentValue}
                disabled={
                  !addCommentValue || addCommentValue.length === 0 || disabled
                }
                intl={intl}
              />
            }
          />
        </div>

        {(data.comments || []).map(comment => {
          if (comment._id !== editingId) {
            return (
              <Comment
                key={comment._id + 1}
                actions={
                  authUser.id === comment.createdBy.id && [
                    <span key={comment._id + 2}>
                      <Tooltip
                        title={intl.formatMessage({ id: 'edit comment' })}
                      >
                        <Icon
                          type='edit'
                          style={{
                            fontSize: 16,
                            marginTop: 'auto',
                            marginBottom: 'auto'
                          }}
                          onClick={() =>
                            !disabled &&
                            this.setState({ editingId: comment._id })
                          }
                        />
                      </Tooltip>
                    </span>,
                    <span key={comment._id + 3}>
                      <PopconfirmDelete
                        readOnly={disabled}
                        value={
                          <Icon
                            type='delete'
                            style={{ color: 'red', fontSize: 16 }}
                          />
                        }
                        style={{
                          backgroundColor: 'transparent',
                          padding: 0,
                          margin: 0,
                          border: 0,
                          height: 18
                        }}
                        onConfirmAction={() =>
                          this.handleCommentDelete(comment._id)
                        }
                      />
                    </span>
                  ]
                }
                author={comment.createdBy.fullName}
                avatar={<Avatar icon='user' />}
                content={<p>{comment.text}</p>}
                datetime={
                  <span>
                    {moment(comment.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                  </span>
                }
              />
            )
          } else {
            return (
              <div key={comment._id + 4}>
                <Comment
                  avatar={<Avatar icon='user' />}
                  content={
                    <Editor
                      onChange={event =>
                        this.handleChange(event.target.value, 'editValue')
                      }
                      onSubmit={this.handleCommentEdit}
                      submitting={submitting}
                      value={editValue !== undefined ? editValue : comment.text}
                      edit
                      onCancel={() =>
                        this.setState({ editingId: '', editValue: undefined })
                      }
                      disabled={
                        !editValue || editValue.length === 0 || disabled
                      }
                      intl={intl}
                    />
                  }
                />
              </div>
            )
          }
        })}
        <Comment
          style={{ paddingBottom: 30 }}
          author={data.createdBy ? data.createdBy.fullName : ''}
          avatar={<Avatar icon='user' />}
          content={
            <p>
              {data.docNumber &&
                data.docNumber.indexOf('WO') > -1 &&
                'Workorder '}
              {data.docNumber && data.docNumber.indexOf('M-') > -1 && 'Defect '}
              created
            </p>
          }
          datetime={
            <Tooltip
              title={moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            >
              <span>
                {moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </span>
            </Tooltip>
          }
        />
      </>
    )
  }
}

UpdatesTab.propTypes = {
  auth: PropTypes.object,
  intl: PropTypes.object.isRequired,
  data: PropTypes.object,
  updateItem: PropTypes.func,
  addComment: PropTypes.func,
  service: PropTypes.object,
  disabled: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    auth: state.auth
  }
}

export default injectIntl(connect(mapStateToProps)(UpdatesTab))
