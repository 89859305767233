import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { WorkorderAPI, FileAPI } from '../services'
import pick from 'lodash/pick'

export const REDUCER = 'workorders'
const NS = `@@${REDUCER}/`

export const setWorkorderData = createAction(`${NS}SET_WORKORDER_DATA`)
export const setWorkorderQueryParams = createAction(
  `${NS}SET_WORKORDER_QUERY_PARAMS`
)
export const setPickedWorkorder = createAction(`${NS}SET_PICKED_WORKORDER`)
export const setWorkorderShowArchived = createAction(`${NS}SET_SHOW_ARCHIVED`)

const WorkorderApi = new WorkorderAPI()
const FileApi = new FileAPI()

export const initialState = {
  workorderData: {},
  queryParams: {},
  pickedWorkorder: {},
  showArchived: false
}

export const fetchWorkorder = (params = {}) => (dispatch, getState) => {
  const pickedProject = getState().project.pickedProject._id
  const archivedState = getState().workorder.showArchived
  if (pickedProject) {
    params.project = pickedProject
  }
  dispatch(setWorkorderQueryParams(params))

  if (archivedState) {
    params['archived[]'] = 'showArchived'
  }

  return WorkorderApi.listV2({
    ...params
  })
    .then(response => {
      dispatch(setWorkorderData(response))
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return error
    })
}

export const deleteWorkOrder = (params = {}) => (dispatch, getState) => {
  const workorderData = getState().workorder.workorderData
  const pickedWorkorder = getState().workorder.pickedWorkorder
  return WorkorderApi.deleteWorkOrder(pickedWorkorder.income._id)
    .then(response => {
      if (!response.error) {
        dispatch(
          setWorkorderData({
            ...workorderData,
            docs: [
              ...workorderData.docs.filter(
                item => item._id !== pickedWorkorder.income._id
              )
            ]
          })
        )
        dispatch(setPickedWorkorder({}))
        return response
      } else {
        return response
      }
    })
    .catch(error => {
      return error
    })
}

export const addFile = (id, data) => (dispatch, getState) => {
  const currentState = getState().workorder.pickedWorkorder
  currentState.value.files = currentState.income.files

  return WorkorderApi.addFile(id, data)
    .then(response => {
      dispatch(
        setPickedWorkorder({
          ...currentState,
          value: {
            ...currentState.value,
            files: [response, ...currentState.value.files]
          },
          income: {
            ...currentState.income,
            files: [response, ...currentState.income.files]
          }
        })
      )
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return error
    })
}

export const addComment = (id, data) => (dispatch, getState) => {
  const currentState = getState().workorder.pickedWorkorder
  currentState.value.comments = currentState.income.comments

  return WorkorderApi.addComment(currentState.income._id, data)
    .then(response => {
      dispatch(
        setPickedWorkorder({
          ...currentState,
          value: {
            ...currentState.value,
            comments: [response, ...currentState.value.comments]
          },
          income: {
            ...currentState.income,
            comments: [response, ...currentState.income.comments]
          }
        })
      )
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return error
    })
}

export const deleteFile = (id, data) => (dispatch, getState) => {
  const currentState = getState().workorder.pickedWorkorder
  currentState.value.files = currentState.income.files

  return FileApi.delete(id)
    .then(response => {
      dispatch(
        setPickedWorkorder({
          ...currentState,
          value: {
            ...currentState.value,
            files: currentState.value.files.filter(item => item._id !== id)
          },
          income: {
            ...currentState.income,
            files: (currentState.income.files || []).filter(
              item => item._id !== id
            )
          }
        })
      )
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return error
    })
}

export const submitPickedWorkorder = () => (dispatch, getState) => {
  const pickedWorkorder = getState().workorder.pickedWorkorder
  const wokrorderDataState = getState().workorder.workorderData
  const showArchived = getState().workorder.showArchived
  const outputData = {
    ...pickedWorkorder.income,
    ...pickedWorkorder.value
  }

  if (outputData.images && outputData.images.length > 0) {
    outputData.images = outputData.images.map(item => item._id)
  }

  if (outputData.files && outputData.files.length > 0) {
    outputData.files = outputData.files.map(item => item._id)
  }

  if (outputData.comments && outputData.comments.length > 0) {
    outputData.comments = outputData.comments.map(item => item._id)
  }

  if (outputData.executedBy) {
    outputData.executedBy = outputData.executedBy._id
  }

  const params = pick(outputData, [
    'status',
    'name',
    'description',
    'executedBy',
    'tasks',
    'specialWork',
    'priority',
    'date',
    'archived',
    'signature',
    'deliveryDate',
    'signatureRequired',
    'specialWorkRequired',
    'archived'
  ])

  if (outputData._id) {
    return WorkorderApi.updateWorkOrder(outputData._id, params).then(
      response => {
        if (!response.error && showArchived !== response.archived) {
          dispatch(
            setWorkorderData({
              ...wokrorderDataState,
              docs: wokrorderDataState.docs.filter(
                item => item._id !== response._id
              )
            })
          )
        } else if (!response.error) {
          dispatch(
            setWorkorderData({
              ...wokrorderDataState,
              docs: wokrorderDataState.docs.map(item =>
                item._id === response._id ? response : item
              )
            })
          )
        }

        return response
      }
    )
  } else {
    return WorkorderApi.addWorkOrder(params).then(response => {
      if (!response.error) {
        dispatch(
          setWorkorderData({
            ...wokrorderDataState,
            docs: [response, ...wokrorderDataState.docs]
          })
        )
      }
      return response
    })
  }
}

export const updateWorkorderField = data => (dispatch, getState) => {
  const currentState = getState().workorder.pickedWorkorder
  delete currentState.errors
  dispatch(
    setPickedWorkorder({
      ...currentState,
      value: {
        ...currentState.value,
        ...data
      }
    })
  )
}

export const wokrorderTaskAdd = (workorderId, taskId) => (
  dispatch,
  getState
) => {
  return WorkorderApi.addTask(workorderId, taskId)
    .then(response => {
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return error
    })
}

export const workorderTaskDelete = (workorderId, taskId) => (
  dispatch,
  getState
) => {
  return WorkorderApi.deleteTask(workorderId, taskId)
    .then(response => {
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return error
    })
}

export const workorderMoveTask = data => (dispatch, getState) => {
  return WorkorderApi.moveTask(data)
    .then(response => {
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message || error.error
      })
      return error
    })
}

export const reorderWorkorderTasks = (id, data) => (dispatch, getState) => {
  return WorkorderApi.updateWorkOrder(id, data)
    .then(response => {
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message || error.error
      })
      return error
    })
}

export default createReducer(
  {
    [setWorkorderData]: (state, workorderData) => ({ ...state, workorderData }),
    [setWorkorderShowArchived]: (state, showArchived) => ({
      ...state,
      showArchived
    }),
    [setPickedWorkorder]: (state, pickedWorkorder) => ({
      ...state,
      pickedWorkorder
    }),
    [setWorkorderQueryParams]: (state, queryParams) => ({
      ...state,
      queryParams
    })
  },
  initialState
)
