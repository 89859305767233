import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Tag } from 'antd'
import styled from 'styled-components'

const StyledTask = styled.div`
  .task-content {
    text-align: justify;
    margin: 0;

    span {
      display: inline-block;
      width: 100%;
    }
  }
`

export const TaskContent = ({ task }) => {
  const intl = useIntl()

  return (
    <StyledTask>
      <div>{task.docNumber}</div>
      <Tag color='blue'>{intl.formatMessage({ id: task.status })}</Tag>
      <div>{task.objectId && task.objectId.name}</div>
    </StyledTask>
  )
}

TaskContent.propTypes = {
  task: PropTypes.object.isRequired
}
