import React from 'react'
import { injectIntl } from 'react-intl'
import { Card } from 'antd'
import PropTypes from 'prop-types'

const { Meta } = Card

const AddButton = ({ action: handleAction, avatar, title, description }) => (
  <>
    <Card
      style={{ marginTop: 16 }}
      className='workorder-add-button'
      onClick={handleAction}
    >
      <Meta avatar={avatar} title={title} description={description} />
    </Card>
  </>
)

AddButton.propTypes = {
  action: PropTypes.func,
  avatar: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string
}

export default injectIntl(AddButton)
