import axios from '../../helpers/axios'
import HttpClient from './index'
import _ from 'lodash'

class SettingsAPI extends HttpClient {
  async get () {
    return axios.get('/config')
  }

  async save (config) {
    config = _.omit(config, 'buildx.product_sections')
    return axios.put('/config', config)
  }

  async uploadLogo (file) {
    const data = new FormData()
    data.append('file', file)

    return axios.post('/config/logo', data)
  }
}
export default SettingsAPI
