import config from 'config'
import axios from '../helpers/axios'

export default {
  async get () {
    const response = await axios.get('/specialwork')
    return response
  },
  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/specialwork', params)
  },
  async add (data) {
    const response = await axios.post('/specialwork', data)
    return response
  },
  async update (id, data) {
    const response = await axios.put(`/specialwork/${id}`, data)
    return response
  },
  async delete (id) {
    const response = await axios.delete(`/specialwork/${id}`)
    return response
  }
}
