import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Button, Icon } from 'antd'
import { setPickedTask } from '../../../../../ducks/tasks'
import FieldEdit from './FieldEdit'

const TaskDetails = props => {
  const intl = useIntl()
  const [editingField, setEditingField] = useState(null)
  const { pickedTask, objectIdName } = props

  const handleCancel = () => {
    const { dispatch } = props
    dispatch(setPickedTask({}))
  }

  const handleCancelEdit = () => {
    setEditingField(null)
  }

  return (
    <div className='task-details'>
      <p className='task-title'>
        <b>{intl.formatMessage({ id: 'task details' })}</b>
        <Button htmlType='button' onClick={handleCancel}>
          <Icon type='close' />
        </Button>
      </p>
      <div className='task-content-block'>
        <FieldEdit
          editingField={editingField}
          editing={editingField === 'name'}
          handleCancel={handleCancelEdit}
        >
          <p className='task-name' onClick={() => setEditingField('name')}>
            <b>{pickedTask.name}</b>
          </p>
        </FieldEdit>

        <FieldEdit
          editingField={editingField}
          editing={editingField === 'description'}
          handleCancel={handleCancelEdit}
        >
          <strong onClick={() => setEditingField('description')}>
            {' '}
            {intl.formatMessage({ id: 'description' })}
          </strong>
          <span
            className='task-text'
            onClick={() => setEditingField('description')}
          >
            {pickedTask.description}
          </span>
        </FieldEdit>
        <strong>{objectIdName}</strong>
        <FieldEdit
          editingField={editingField}
          editing={editingField === 'objectId'}
          handleCancel={handleCancelEdit}
        >
          <span
            className='task-text'
            onClick={() => setEditingField('objectId')}
          >
            {pickedTask.objectId && pickedTask.objectId.name}
          </span>
        </FieldEdit>
        <strong>ID</strong>
        <p className='task-text'>{pickedTask.docNumber}</p>
        <strong>{intl.formatMessage({ id: 'status' })}</strong>
        <FieldEdit
          editingField={editingField}
          editing={editingField === 'status'}
          handleCancel={handleCancelEdit}
        >
          <span className='task-text' onClick={() => setEditingField('status')}>
            {intl.formatMessage({ id: pickedTask.status })}
          </span>
        </FieldEdit>
      </div>
    </div>
  )
}

TaskDetails.propTypes = {
  dispatch: PropTypes.func.isRequired,
  pickedTask: PropTypes.object,
  objectIdName: PropTypes.string
}

const mapStateToProps = state => {
  return {
    pickedTask: state.tasks.pickedTask,
    objectIdName: state.settings.data.buildx.objectName
  }
}

export default connect(mapStateToProps)(TaskDetails)
