import React from 'react'
import PropTypes from 'prop-types'
import { Button as AntButton, Icon } from 'antd'

const Button = props => {
  return (
    <AntButton
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      style={props.style}
      size={props.size}
      loading={props.loading}
    >
      {props.icon && !props.iconRight && (
        <>
          <Icon type={props.icon} />{' '}
        </>
      )}
      {props.value} {props.children}
      {props.icon && props.iconRight && <Icon type={props.icon} />}
    </AntButton>
  )
}

Button.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  iconRight: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any
}

Button.defaultProps = {
  iconRight: false
}

export default Button
