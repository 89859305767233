import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { FormsAPI } from '../services'

export const REDUCER = 'forms'
const NS = `@@${REDUCER}/`

export const setFormData = createAction(`${NS}SET_FORM_DATA`)
export const setSearchedFormList = createAction(`${NS}SET_SEARCHED_FORM_LIST`)

const FormsApi = new FormsAPI()

export const initialState = {
  formData: {},
  searchedFormList: []
}

export const fetchSingleForm = id => (dispatch, getState) => {
  return FormsApi.get(id)
    .then(response => {
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
    })
}

export const fetchForms = (params, search) => (dispatch, getState) => {
  return FormsApi.listV2(params)
    .then(response => {
      if (!response.error) {
        if (search) {
          dispatch(setSearchedFormList(response.docs))
        } else {
          dispatch(setFormData(response))
        }
      }
      return true
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
    })
}

export default createReducer(
  {
    [setFormData]: (state, formData) => ({ ...state, formData }),
    [setSearchedFormList]: (state, searchedFormList) => ({
      ...state,
      searchedFormList
    })
  },
  initialState
)
