import React from 'react'
import RecordingsTable from './components/RecordingsTable'
import PageHeader from './components/PageHeader'
import RecordingsDrawer from '../../../components/RecordingsDrawer'
import ModalDefectCreation from '../../../components/ModalDefectCreation'

const RecordingsPage = () => {
  return (
    <>
      <PageHeader />
      <RecordingsTable />
      <RecordingsDrawer />
      <ModalDefectCreation />
    </>
  )
}

export default RecordingsPage
