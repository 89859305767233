import React from 'react'
import PropTypes from 'prop-types'

import config from 'config'
import { Form, Input } from 'antd'
import { injectIntl } from 'react-intl'
import PopconfirmDelete from '../../../../components/Global/PopconfirmDelete'
import debounce from 'lodash/debounce'
import '../../styles.scss'
import Zoom from 'react-medium-image-zoom'

const formItemLayout = {
  className: 'form-item-cover'
}

export class ListItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      comment: ''
    }
    this.handleInputThrottled = debounce(this.descriptionUpdate, 100)
  }

  descriptionUpdate = comment => {
    const { handleUpdate, fileData } = this.props
    handleUpdate({ ...fileData, comment }, 'update')
  }

  render () {
    const { handleDelete, fileData, intl } = this.props

    const imageSrc = config.server.url + (fileData.imageUrl || fileData.fileUrl)
    return (
      <div className='upload-list-item'>
        <div
          // onClick={this.handleDownload}
          className='uploader-image-cover'
        >
          <div
            onClick={event => event.stopPropagation()}
            className='confirm-delete-cover'
          >
            <PopconfirmDelete
              value='X'
              onConfirmAction={() => handleDelete(fileData)}
            />
          </div>
          <Zoom zoomMargin={40}>
            <img
              src={imageSrc}
              className='img'
              style={{
                maxWidth: '100%',
                maxHeight: 200,
                objectFit: 'contain',
                className: 'image-small'
              }}
            />
          </Zoom>
        </div>
        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <Input.TextArea
            style={{ height: 80, resize: 'none' }}
            defaultValue={fileData.description || fileData.comment || ''}
            onChange={event => this.handleInputThrottled(event.target.value)}
          />
        </Form.Item>
      </div>
    )
  }
}

ListItem.propTypes = {
  files: PropTypes.array,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  fileData: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired
}

export default injectIntl(ListItem)
