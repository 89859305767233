import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { ProductsAPI } from '../services'

export const REDUCER = 'products'
const NS = `@@${REDUCER}/`

export const setProductsList = createAction(`${NS}SET_PRODUCTS_LIST`)

const ProductsApi = new ProductsAPI()

export const initialState = {
  productsList: {}
}

export const fetchProducts = () => (dispatch, getState) => {
  ProductsApi.getList()
    .then(response => {
      dispatch(setProductsList(response))
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
    })
}

export default createReducer(
  {
    [setProductsList]: (state, productsList) => ({ ...state, productsList })
  },
  initialState
)
