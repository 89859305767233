import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, Select, Spin } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { updatePickedObjectType } from '../../../../../ducks/objectTypes'
import { fetchFieldList } from '../../../../../ducks/fields'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'

const ObjectTypeForm = props => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const intl = useIntl()
  const { getFieldDecorator } = props.form
  const { pickedObjectType, formFields } = props

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
    className: 'formItem'
  }

  const fetchFormFieldsOnSearch = (value = '') => {
    setLoading(true)
    dispatch(fetchFieldList({ 'label[]': value, limit: 100 }, true)).then(
      () => {
        setLoading(false)
      }
    )
  }

  const handlePick = (value, e) => {
    const outputArray = e.map((item, index) => {
      if (item.props.data) {
        return item.props.data
      } else {
        let field = formFields.find(field => field.label === value[index])
        if (!field) {
          field = (pickedObjectType.fields || []).find(
            field => field.label === value[index]
          )
        }
        return field
      }
    })
    dispatch(updatePickedObjectType({ fields: outputArray }))
  }

  const fields = (formFields || []).filter(item => {
    return !(pickedObjectType.fields || []).some(
      field => field.key === item.key
    )
  })

  return (
    <Form onSubmit={() => {}}>
      <Form.Item {...formItemLayout} label='Name' required={false}>
        {getFieldDecorator('name', {
          initialValue: pickedObjectType.name,
          rules: [
            {
              required: true,
              message: `Name ${intl.formatMessage({
                id: 'is required'
              })}`
            }
          ]
        })(
          <Input
            // disabled={project.archived}
            onChange={event =>
              dispatch(updatePickedObjectType({ name: event.target.value }))
            }
          />
        )}
      </Form.Item>

      <Form.Item
        {...formItemLayout}
        required={false}
        label={intl.formatMessage({ id: 'form fields' })}
      >
        {getFieldDecorator('fields', {
          initialValue:
            (pickedObjectType.fields &&
              pickedObjectType.fields.length &&
              pickedObjectType.fields.map(item => item.label)) ||
            undefined,
          rules: [
            {
              required: true,
              message: intl.formatMessage({
                id: 'is required'
              })
            }
          ]
        })(
          <Select
            mode='multiple'
            showSearch
            allowClear
            style={{ width: '100%' }}
            notFoundContent={
              loading && (
                <div className='project-spinner-container'>
                  <Spin size='small' />
                </div>
              )
            }
            placeholder={intl.formatMessage({ id: 'select field' })}
            optionFilterProp='children'
            onFocus={fetchFormFieldsOnSearch}
            onSearch={debounce(fetchFormFieldsOnSearch, 300)}
            onChange={(data, event) => handlePick(data, event)}
          >
            {(fields || []).map(field => {
              return (
                <Select.Option
                  key={field._id}
                  data={{ ...field }}
                  value={field.label}
                >
                  {field.label}
                </Select.Option>
              )
            })}
          </Select>
        )}
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
  drawerVisible: state.objectType.drawerVisible,
  pickedObjectType: state.objectType.pickedObjectType,
  formFields: state.fields.searchedFieldsList
})

ObjectTypeForm.propTypes = {
  form: PropTypes.object.isRequired,
  pickedObjectType: PropTypes.object,
  formFields: PropTypes.array
}

export default Form.create({ name: 'objectType' })(
  connect(mapStateToProps, null)(ObjectTypeForm)
)
