import React from 'react'
import { connect } from 'react-redux'

import { injectIntl } from 'react-intl'
import { Form, Tabs } from 'antd'
import fileService from 'services/file'
import workOrdersService from 'services/workOrders'

import InformationsTab from './Tabs/Informations'
import RecurringTab from './Tabs/Recurring'
import Updates from '../../CommentsTab'
import TaskEdit from './Tabs/TaskEdit'
import FileUploadTab from '../../FileUploadTab'

import './styles.scss'
import PropTypes from 'prop-types'

const { TabPane } = Tabs

class WorkOrderForm extends React.Component {
  onDelete = file => {
    const {
      updateStateWorkOrder,
      workOrder: { files = [] }
    } = this.props
    return fileService
      .delete(file._id)
      .then(() => {
        updateStateWorkOrder(
          'files',
          files.filter(item => item._id !== file._id)
        )
        return 'success'
      })
      .catch(() => {
        return 'error'
      })
  }

  onAdd = async image => {
    const { workOrder, updateStateWorkOrder } = this.props
    const formData = new FormData()
    formData.append('file', image)
    return workOrdersService
      .addFile(workOrder._id, formData)
      .then(response => {
        updateStateWorkOrder('files', [response, ...workOrder.files])
        return 'success'
      })
      .catch(error => {
        console.log(error)
        return 'error'
      })
  }

  render () {
    const {
      scheduled,
      taskEditMode,
      fileUploadMode,
      intl,
      action,
      workOrder,
      updateStateWorkOrder,
      handleRecordingSelect: onRecordingSelect,
      handleTaskIdSelectChange: onTaskIdSelectChange,
      handleTaskIdChangeAutocomplete: onTaskIdChangeAutocomplete,
      handleSearchObjectId: onSearchObjectId,
      handleSearchRecordingId: onSearchRecordingId
    } = this.props

    return (
      <>
        {!taskEditMode && !fileUploadMode && (
          <>
            <Form className='workOrdersForm'>
              <Tabs defaultActiveKey='informations'>
                <TabPane
                  tab={intl.formatMessage({
                    id: 'informations'
                  })}
                  key='informations'
                >
                  <InformationsTab
                    updateAssignedUser={this.props.updateAssignedUser}
                    updateStateWorkOrder={this.props.updateStateWorkOrder}
                    updateStateSpecialWork={this.props.updateStateSpecialWork}
                    specialWork={this.props.specialWork}
                    fetchSpecialWork={this.props.fetchSpecialWork}
                    fetchUsersHandler={this.props.fetchUsersHandler}
                    action={this.props.toggleTaskEditMode}
                    showFileupload={this.props.toggleFileuploadMode}
                    users={this.props.users}
                    form={this.props.form}
                    formLoading={this.props.formLoading}
                    workOrder={this.props.workOrder}
                  />
                </TabPane>
                <TabPane
                  tab={this.props.intl.formatMessage({ id: 'files' })}
                  key='files'
                >
                  <FileUploadTab
                    action={action}
                    files={workOrder.files}
                    handleDelete={this.onDelete}
                    handleAdd={this.onAdd}
                  />
                </TabPane>
                {this.props.scheduled ? (
                  <TabPane
                    tab={this.props.intl.formatMessage({
                      id: 'recurring'
                    })}
                    key='recurring'
                    className='workorder-tab-recurring'
                  >
                    <RecurringTab
                      updateStateWorkOrder={this.props.updateStateWorkOrder}
                      workOrder={this.props.workOrder}
                    />
                  </TabPane>
                ) : null}

                {this.props.option === 'edit' && (
                  <TabPane
                    tab={this.props.intl.formatMessage({
                      id: 'updates'
                    })}
                    key='updates'
                  >
                    <Updates
                      updateItem={updateStateWorkOrder}
                      data={workOrder}
                      service={workOrdersService}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Form>
          </>
        )}
        {this.props.taskEditMode && (
          <TaskEdit
            action={this.props.toggleTaskEditMode}
            objects={this.props.objects}
            recording={this.props.recording}
            formLoading={this.props.formLoading}
            form={this.props.form}
            auth={this.props.auth}
            objectName={this.props.objectName}
            dataXEnabled={this.props.dataXEnabled}
            extraFieldsForm={this.props.extraFieldsForm}
            objectIdSearchResult={this.props.objectIdSearchResult}
            recordingIdSearchResult={this.props.recordingIdSearchResult}
            handleRecordingSelect={onRecordingSelect}
            removeTagHandler={this.props.removeTagHandler}
            clearTagHandler={this.props.clearTagHandler}
            onSortTasksEnd={this.props.onSortTasksEnd}
            taskIdChange={this.props.taskIdChange}
            handleTaskIdSelectChange={onTaskIdSelectChange}
            handleTaskIdChangeAutocomplete={onTaskIdChangeAutocomplete}
            handleSearchObjectId={onSearchObjectId}
            handleSearchRecordingId={onSearchRecordingId}
            removeTaskholder={this.props.removeTaskholder}
            shareholders={this.props.shareholders}
            addShareholder={this.props.addShareholder}
            markAsDoneTask={this.props.markAsDoneTask}
            hideModalComment={this.props.hideModalComment}
            showModalComment={this.props.showModalComment}
            recordingDrawerVisible={this.props.recordingDrawerVisible}
            recordingDrawerShow={this.props.recordingDrawerShow}
            recordingDrawerHide={this.props.recordingDrawerHide}
            modalCommentActive={this.props.modalCommentActive}
            workorder={this.props.workOrder}
            scheduled={scheduled}
          />
        )}
      </>
    )
  }
}

WorkOrderForm.propTypes = {
  workOrder: PropTypes.object.isRequired,
  updateStateWorkOrder: PropTypes.func.isRequired,
  scheduled: PropTypes.bool,
  taskEditMode: PropTypes.bool,
  fileUploadMode: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  recording: PropTypes.object,
  action: PropTypes.func,
  updateAssignedUser: PropTypes.func.isRequired,
  updateStateSpecialWork: PropTypes.func.isRequired,
  specialWork: PropTypes.array,
  fetchSpecialWork: PropTypes.func.isRequired,
  fetchUsersHandler: PropTypes.func.isRequired,
  toggleTaskEditMode: PropTypes.func.isRequired,
  toggleFileuploadMode: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  formLoading: PropTypes.bool,
  option: PropTypes.string,
  objects: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  auth: PropTypes.object,
  objectName: PropTypes.string,
  dataXEnabled: PropTypes.bool,
  extraFieldsForm: PropTypes.object,
  objectIdSearchResult: PropTypes.array.isRequired,
  recordingIdSearchResult: PropTypes.array.isRequired,
  handleRecordingSelect: PropTypes.func.isRequired,
  removeTagHandler: PropTypes.func,
  clearTagHandler: PropTypes.func,
  onSortTasksEnd: PropTypes.func.isRequired,
  taskIdChange: PropTypes.func.isRequired,
  handleTaskIdSelectChange: PropTypes.func.isRequired,
  handleSearchObjectId: PropTypes.func.isRequired,
  handleSearchRecordingId: PropTypes.func.isRequired,
  removeTaskholder: PropTypes.func.isRequired,
  shareholders: PropTypes.array,
  markAsDoneTask: PropTypes.func.isRequired,
  hideModalComment: PropTypes.func.isRequired,
  showModalComment: PropTypes.func.isRequired,
  recordingDrawerVisible: PropTypes.bool,
  recordingDrawerShow: PropTypes.func,
  recordingDrawerHide: PropTypes.func,
  handleTaskIdChangeAutocomplete: PropTypes.func.isRequired,
  addShareholder: PropTypes.func.isRequired,
  modalCommentActive: PropTypes.string
}

export default injectIntl(connect()(WorkOrderForm))
