import { combineReducers } from 'redux'
import login from './login'
import settings from './settings'
import project from './project'
import stats from './stats'
import recordings from './recordings'
import objects from './object'
import contractSections from './contractSections'
import forms from './forms'
import products from './products'
import category from './category'
import contacts from './contacts'
import acceptance from './acceptance'
import locations from './locations'
import workorder from './workorder'
import tasks from './tasks'
import users from './users'
import specialWork from './specialWork'
import objectType from './objectTypes'
import fields from './fields'

export default combineReducers({
  auth: login,
  settings,
  project,
  stats,
  recordings,
  objects,
  contractSections,
  forms,
  products,
  category,
  contacts,
  acceptance,
  locations,
  workorder,
  tasks,
  users,
  specialWork,
  objectType,
  fields
})
