import React from 'react'
import { Pie } from 'react-chartjs-2'
import { Col, Skeleton, Avatar } from 'antd'
import { injectIntl, FormattedMessage } from 'react-intl'

import './../style.scss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CardLayout from './CardLayout'

class RecordingsBlock extends React.Component {
  render () {
    const { settings, stats, intl, loading } = this.props
    const recording = stats.recording || {}
    const recordingData = recording.count || {}

    return (
      <div className='homepage-row-block'>
        <CardLayout
          label={intl.formatMessage({
            id: 'recordings'
          })}
          style={{ height: '100%' }}
        >
          {settings.buildx && settings.buildx.enabled && (
            <>
              <Col lg={8}>
                <div className='box'>
                  <h3>
                    <FormattedMessage id='verified' />
                  </h3>
                  <Skeleton loading={loading} active paragraph={{ rows: 0 }}>
                    <span className='bigNumber' style={{ color: 'green' }}>
                      {recordingData.verified}
                    </span>
                  </Skeleton>
                </div>
              </Col>

              <Col lg={8}>
                <div className='box'>
                  <h3>
                    <FormattedMessage id='unverified' />
                  </h3>
                  <Skeleton loading={loading} active paragraph={{ rows: 0 }}>
                    <span className='bigNumber' style={{ color: 'orange' }}>
                      {recordingData.unverified}
                    </span>
                  </Skeleton>
                </div>
              </Col>

              <Col lg={8}>
                <div className='box'>
                  <h3>
                    <FormattedMessage id='rejected' />
                  </h3>
                  <Skeleton loading={loading} active paragraph={{ rows: 0 }}>
                    <span className='bigNumber' style={{ color: 'red' }}>
                      {recordingData.rejected}
                    </span>
                  </Skeleton>
                </div>
              </Col>
              <div className='diagram-cover'>
                <Skeleton
                  placeholder={<Avatar size='large' />}
                  loading={loading}
                  avatar
                  paragraph={{ rows: 0 }}
                  title={false}
                  active
                >
                  {recordingData.verifeid +
                    recordingData.unverified +
                    recordingData.rejected >
                    0 && (
                    <Pie
                      data={{
                        labels: [
                          this.props.intl.formatMessage({ id: 'verified' }),
                          this.props.intl.formatMessage({
                            id: 'unverified'
                          }),
                          this.props.intl.formatMessage({ id: 'rejected' })
                        ],
                        datasets: [
                          {
                            label: this.props.intl.formatMessage({
                              id: 'recordings'
                            }),
                            data: [
                              recordingData.verified,
                              recordingData.unverified,
                              recordingData.rejected
                            ],
                            backgroundColor: ['green', 'orange', 'red'],
                            borderColor: ['green', 'orange', 'red'],
                            borderWidth: 1
                          }
                        ]
                      }}
                      options={{
                        legend: {
                          display: false
                        }
                      }}
                    />
                  )}
                </Skeleton>
              </div>
            </>
          )}
        </CardLayout>
      </div>
    )
  }
}

RecordingsBlock.propTypes = {
  settings: PropTypes.object.isRequired,
  stats: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  loading: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    settings: state.settings.data,
    stats: state.stats.data
  }
}

export default injectIntl(connect(mapStateToProps)(RecordingsBlock))
