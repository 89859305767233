import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { connect } from 'react-redux'
import { Form, Input } from 'antd'
import PropTypes from 'prop-types'

import { updatePickedRecording } from '../../../ducks/recordings'

export class GeneralSection extends React.Component {
  handleFieldChange = (key, value) => {
    const { dispatch } = this.props
    dispatch(updatePickedRecording({ [key]: value }))
  }

  changeAttribute = (key, value) => {
    const { recording } = this.props
    const currentAttributes = { ...recording.attributes }
    currentAttributes[key] = value
    this.handleFieldChange('attributes', currentAttributes)
  }

  render () {
    const {
      readOnly,
      formItemLayout,
      recording: { attributes },
      recording,
      intl
    } = this.props

    return (
      <>
        <h3>
          <FormattedMessage id='general' />
        </h3>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'actual status'
          })}
        >
          <Input.TextArea
            disabled={readOnly}
            value={attributes && attributes.actualStatus}
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={event =>
              this.changeAttribute('actualStatus', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'target status'
          })}
        >
          <Input.TextArea
            disabled={readOnly}
            value={attributes && attributes.targetStatus}
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={event =>
              this.changeAttribute('targetStatus', event.target.value)
            }
          />
        </Form.Item>

        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({
            id: 'comment'
          })}
        >
          <Input.TextArea
            disabled={readOnly}
            value={recording.comment}
            autoSize={{ minRows: 2, maxRows: 6 }}
            onChange={event =>
              this.handleFieldChange('comment', event.target.value)
            }
          />
        </Form.Item>
      </>
    )
  }
}

GeneralSection.propTypes = {
  recording: PropTypes.object,
  intl: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  formItemLayout: PropTypes.object,
  readOnly: PropTypes.bool
}

const mapStateToProps = state => ({
  recording: state.recordings.pickedRecording,
  readOnly: state.recordings.pickedReadOnly
})

export default injectIntl(connect(mapStateToProps, null)(GeneralSection))
