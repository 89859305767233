import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { LocationsAPI } from '../services'

export const REDUCER = 'location'
const NS = `@@${REDUCER}/`

export const setSearchedLocations = createAction(`${NS}SET_SEARCHED_LOCATIONS`)
export const setLocationsList = createAction(`${NS}SET_LOCATIONS_LIST`)

const LocationsApi = new LocationsAPI()

export const initialState = {
  locationsList: [],
  searchedLocationsList: [],
  pickedLocations: {}
}

export const fetchLocations = (params, search = false) => (
  dispatch,
  getState
) => {
  return LocationsApi.listV2(params)
    .then(response => {
      if (search) {
        dispatch(setSearchedLocations(response.docs))
      } else {
        dispatch(setLocationsList(response.docs))
      }
      return true
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return false
    })
}

export default createReducer(
  {
    [setSearchedLocations]: (state, searchedLocationsList) => ({
      ...state,
      searchedLocationsList
    }),
    [setLocationsList]: (state, locationsList) => ({ ...state, locationsList })
  },
  initialState
)
