import React, { useEffect, useState } from 'react'
import { Form, Icon, Input, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import { login } from '../../../../ducks/login'

const FormLogin = props => {
  const user = useSelector(state => state.auth.authUser)
  const history = useHistory()
  const intl = useIntl()
  const dispatch = useDispatch()

  const { getFieldDecorator } = props.form

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      history.push('/', { login: true })
    }
  }, [user, history])

  const handleFormSubmit = event => {
    setLoading(true)
    props.form.validateFields((err, values) => {
      event.preventDefault()
      if (!err) {
        const { email, password } = values
        dispatch(login(email, password))
      }
      setTimeout(function () {
        setLoading(false)
      }, 1500)
    })
  }

  return (
    <Form layout='vertical' onSubmit={handleFormSubmit} hideRequiredMark>
      <Form.Item label={intl.formatMessage({ id: 'email address' })}>
        {getFieldDecorator('email', {
          rules: [{ required: true, message: 'Please input your email!' }]
        })(
          <Input
            disabled={loading}
            autoComplete='username'
            prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder={intl.formatMessage({ id: 'email address' })}
          />
        )}
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: 'password' })}>
        {getFieldDecorator('password', {
          rules: [{ required: true, message: 'Please input your password!' }]
        })(
          <Input
            disabled={loading}
            autoComplete='current-password'
            prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
            type='password'
            placeholder={intl.formatMessage({ id: 'password' })}
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          disabled={loading}
          loading={loading}
          block
        >
          <span>
            <FormattedMessage id='login' />
          </span>
        </Button>
      </Form.Item>
    </Form>
  )
}

FormLogin.propTypes = {
  form: PropTypes.object.isRequired
}

export default Form.create({ name: 'form_auth_login' })(FormLogin)
