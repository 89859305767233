import { useState } from 'react'

const useApi = serviceMethod => {
  const [loading, setLoading] = useState(false)

  return [
    loading,
    async (...args) => {
      try {
        setLoading(true)
        const response = await serviceMethod.apply(null, args)
        setLoading(false)

        return response
      } catch (error) {
        setLoading(false)

        throw error
      }
    }
  ]
}

export default useApi
