import axios from '../../helpers/axios'
import HttpClient from './index'
import config from 'config'

class ContactsAPI extends HttpClient {
  async list (params = {}) {
    return axios.get('/contact', params)
  }

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/contact', params)
  }

  async save (contact) {
    const params = {
      company: contact.company,
      firstName: contact.firstName,
      lastName: contact.lastName,
      street: contact.street,
      postcode: contact.postcode,
      city: contact.city,
      email: contact.email,
      phone: contact.phone,
      website: contact.website
    }

    if (contact._id) {
      return axios.put(`/contact/${contact._id}`, params)
    } else {
      return axios.post('/contact', params)
    }
  }

  async remove (contact) {
    return axios.delete(`/contact/${contact._id}`)
  }
}
export default ContactsAPI
