import axios from '../../helpers/axios'
import HttpClient from './index'
// import store from '../../store'
import config from '../config'

class StatsAPI extends HttpClient {
  async getList (params = {}) {
    // const projectID = store.getState().projectReducer.project
    // if (projectID) {
    //   params.project = projectID._id
    // }
    const response = await axios.get('/product', params)
    return response
  }

  async listV2 (params = {}) {
    // const projectID = store.getState().projectReducer.project
    // if (projectID) {
    //   params.project = projectID._id
    // }
    return axios.get(config.server.host + '/1.1/product', params)
  }

  async addProduct (data) {
    const response = await axios.post('/product', data)
    return response
  }

  async deleteProduct (id) {
    const response = await axios.delete(`/product/${id}`)
    return response
  }

  async updateProduct (id, data) {
    const response = await axios.put(`/product/${id}`, data)
    return response
  }
}
export default StatsAPI
