import axios from '../helpers/axios'
import _ from 'lodash'

export default {
  async get () {
    return axios.get('/config')
  },

  async save (config) {
    config = _.omit(config, 'buildx.product_sections')
    return axios.put('/config', config)
  },

  async uploadLogo (file, background) {
    const data = new FormData()
    data.append('file', file)
    if (background) {
      data.append('background', true)
    }
    return axios.post('/config/logo', data)
  },

  async deleteLogo (field) {
    return axios.post('/config/logo/delete', { field })
  }
}
