import moment from 'moment'
import config from 'config'
import axios from 'helpers/axios'
import objectService from './object'

class RecordingService {
  async list (params = {}) {
    return axios.get('/recording', params)
  }

  async submit (recordingID) {
    return axios.post(`/recording/${recordingID}/submit`)
  }

  async archive (recordingID) {
    const params = {
      archived: true
    }
    return axios.put(`/recording/${recordingID}`, params)
  }

  async unarchive (recordingID) {
    const params = {
      archived: false
    }
    return axios.put(`/recording/${recordingID}`, params)
  }

  async createWorkorder (recordingID) {
    return axios.post(`/recording/${recordingID}/create-workorder`)
  }

  async createTask (recordingID) {
    return axios.post(`/recording/${recordingID}/create-task`)
  }

  async createDefect (recordingID, params) {
    return axios.post(`/recording/${recordingID}/create-defect`, params)
  }

  async listV2 (params = {}) {
    return axios.get(config.server.host + '/1.1/recording', params)
  }

  async exportCSV (params = {}) {
    const result = await axios.get(
      config.server.host + '/1.0/recording-csv',
      params
    )

    return result
  }

  async save (recording) {
    let objectId = recording.objectId
    if (!objectId._id) {
      if (objectId.fields) {
        objectId = await objectService.create({
          fields: objectId.fields
        })
      } else {
        objectId = await objectService.create({
          name: objectId.name
        })
      }
    }

    const payloadProducts = []

    recording.products &&
      recording.products.forEach(item => {
        const payloadProduct = {
          product: item.product._id,
          quantity: item.quantity,
          extra_fields: item.extra_fields
        }
        payloadProducts.push(payloadProduct)
      })

    const params = {
      name:
        recording.name ||
        `Aufnahme vom ${moment(recording.createdAt).format(
          'DD.MM.YYYY HH:ss'
        )}`,
      project: recording.project || null,
      comment: recording.comment,
      status: recording.status || '',
      objectId: objectId._id,
      date: recording.date,
      attributes: recording.attributes,
      archived: recording.archived,
      isRejected: recording.isRejected,
      isInvalid: recording.isInvalid,
      products: payloadProducts
    }

    if (recording.contractSectionName) {
      params.contractSection = recording.contractSectionName
    }

    const savedRecording = await axios.put(
      `/recording/${recording._id}`,
      params
    )

    if (recording.images && recording.images.length > 0) {
      for (const image of recording.images) {
        const data = new FormData()

        if (image.comment) {
          data.append('comment', image.comment)
        }

        if (image.status) {
          data.append('status', image.status)
        }

        const savedImage = await axios.put(
          `/recording-image/${image._id}`,
          data
        )

        savedRecording.images = [
          ...savedRecording.images.filter(item => item._id !== savedImage._id),
          savedImage
        ]
      }
    }

    return savedRecording
  }

  async delete (recording) {
    return axios.delete(`/recording/${recording._id}`)
  }
}

const recordingService = new RecordingService()

export default recordingService
