import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Form, Input } from 'antd'
import { connect } from 'react-redux'

import ContactSelect from '../../../../components/commonComponents/ContactSelect'

const { Item } = Form

const ProtocolInfo = props => {
  const intl = useIntl()
  const {
    form: { getFieldDecorator },
    defect,
    defect: { protocolExtResponsible, protocolResponsible },
    updateItem
  } = props

  const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
  }

  return (
    <Form {...formItemLayout}>
      <Item
        label={intl.formatMessage({
          id: 'protocolResponsible'
        })}
      >
        {getFieldDecorator('protocolResponsible')(
          <ContactSelect
            pickContact={contact => updateItem('protocolResponsible', contact)}
            currentValue={protocolResponsible}
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'protocolReference' })}>
        {getFieldDecorator('protocolReference', {
          initialValue: defect.protocolReference
        })(
          <Input
            placeholder={intl.formatMessage({
              id: 'protocolReference'
            })}
            onChange={event =>
              updateItem('protocolReference', event.target.value)
            }
          />
        )}
      </Item>
      <Item
        label={intl.formatMessage({
          id: 'protocolExtResponsible'
        })}
      >
        {getFieldDecorator('protocolExtResponsible')(
          <ContactSelect
            pickContact={contact =>
              updateItem('protocolExtResponsible', contact)
            }
            currentValue={protocolExtResponsible}
          />
        )}
      </Item>
      <Item label={intl.formatMessage({ id: 'protocolRefExt' })}>
        {getFieldDecorator('protocolRefExt', {
          initialValue: defect.protocolRefExt
        })(
          <Input
            placeholder={intl.formatMessage({
              id: 'protocolRefExt'
            })}
            onChange={event => updateItem('protocolRefExt', event.target.value)}
          />
        )}
      </Item>
    </Form>
  )
}

ProtocolInfo.propTypes = {
  updateItem: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  objectName: PropTypes.string,
  defect: PropTypes.object.isRequired,
  contacts: PropTypes.array,
  formLoading: PropTypes.bool,
  fetchData: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    objectName:
      (state.settings.data.buildx && state.settings.data.buildx.objectName) ||
      ''
  }
}

export default connect(mapStateToProps)(ProtocolInfo)
