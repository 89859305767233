import { createAction, createReducer } from 'redux-act'
import { notification } from 'antd'
import { TasksAPI } from '../services'

export const REDUCER = 'stats'
const NS = `@@${REDUCER}/`

export const setTasksData = createAction(`${NS}SET_TASKS_DATA`)
export const setPickedTask = createAction(`${NS}SET_PICKED_TASK`)

const TasksApi = new TasksAPI()

export const initialState = {
  tasksData: {},
  queryParams: {},
  pickedTask: {},
  docNumber: ''
}

export const fetchTasks = params => (dispatch, getState) => {
  const pickedProject = getState().project.pickedProject._id
  if (pickedProject) {
    params.project = pickedProject
  }
  TasksApi.listV2(params)
    .then(response => {
      dispatch(setTasksData(response))
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
    })
}

export const createTask = data => (dispatch, getState) => {
  const pickedProject = getState().project.pickedProject._id
  if (pickedProject) {
    data.project = pickedProject
  }

  return TasksApi.saveTask(data)
    .then(response => {
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message
      })
      return error
    })
}

export const updateTask = (id, data) => (dispatch, getState) => {
  const payload = { ...data }

  if (payload.objectId) {
    payload.objectId = payload.objectId._id
  }
  return TasksApi.updateTask(id, payload)
    .then(response => {
      return response
    })
    .catch(error => {
      notification.open({
        type: 'error',
        message: error.message || error.error
      })
      return error
    })
}

export default createReducer(
  {
    [setTasksData]: (state, tasksData) => ({ ...state, tasksData }),
    [setPickedTask]: (state, pickedTask) => ({ ...state, pickedTask })
  },
  initialState
)
