import { Button, Dropdown, Menu } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { setLanguage } from '../ducks/settings'

const langArray = ['en', 'de']

const LanguagePicker = props => (
  <Dropdown
    className={props.className}
    overlay={
      <Menu>
        {langArray.map(lang => (
          <Menu.Item
            key={lang}
            onClick={() => props.dispatch(setLanguage(lang))}
          >
            <span
              className='nav-text'
              style={{
                fontWeight:
                  props.settings && props.settings.lang === lang
                    ? 'bold'
                    : 'normal'
              }}
            >
              {lang.toUpperCase()}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    }
    placement='bottomCenter'
    trigger={['click']}
  >
    <Button shape='circle' icon='global' />
  </Dropdown>
)

LanguagePicker.propTypes = {
  dispatch: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  className: PropTypes.string
}
const mapStateToProps = state => ({
  settings: state.settings
})

export default connect(mapStateToProps)(LanguagePicker)
